import { Component } from '@angular/core';
import { Events, Config, Modal, NavParams, ViewController, NavController, Page } from 'ionic-angular';

import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { databaseService } from '../../services/databaseService';

import { ElasticHeader } from '../../directives/elastic-header';
import { GuestForm } from '../../components/guest-form/guest-form';
import { ServicesForm } from '../../components/services-form/services-form';
import { RoomForm } from '../../components/room-form/room-form';

@Component({
  templateUrl: 'build/pages/reservierung-detail/detail.html',
  directives: [GuestForm,ElasticHeader,ServicesForm,RoomForm],
  providers: [GuestForm]
})
export class ReservierungDetailPage {

    public isNew: boolean = true;
    public action: string = 'Add';
    private options:any;

    private segment:string = "guest";

    private headerImage: string = 'https://ununsplash.imgix.net/photo-1421091242698-34f6ad7fc088?fit=crop&fm=jpg&h=650&q=75&w=950';

    constructor(
        private guestForm: GuestForm,
        private config: Config,
        private events: Events,
        private nav: NavController,
        private viewCtrl: ViewController,
        private navParams: NavParams,
        private dbService: databaseService,
        private translate: TranslateService
      ) {
        this.translate = translate;
        this.translate.instant('json.string');

        this.options = {
          autoLoadGuest: false,
          autoLoadRoom: false,
          autoLoadServices: false,
          termin: {},
          data: {},
          room: {}
        };

    }

    ionViewLoaded(){
      this.options.termin = this.navParams.get('data');
      //console.log("options termin",this.options.termin);
    }

    ionViewDidEnter(){
      //console.log("details ionViewDidEnter");
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }

      if (!this.options.termin) {
          this.options.termin = {};
      } else {
          this.isNew = false;
          this.action = 'Edit';

          //einzeln starten
          if(this.options.autoLoadGuest){
            //this.guestForm.load(this.options.termin);
          } else {
            //alle daten laden
            this.load();
          }
      }
    }

    changeSegment(){
      //console.log("changeSegment: "+this.segment);
    }

    load(){
      var roomDb = "company/"+this.config.get('GDATNR')+"/RoomData/Room/"+this.options.termin.ZIMMER_NR;

      //FIREBASE
      this.dbService.getRoom(roomDb).then((res) => {
        //console.log(res);
        this.options.room = res;
      });

      this.dbService.getTerminInfos(this.options.termin).then((res) => {
        this.options.data = res;
        if(!this.options.autoLoadGuest){
          console.log("details",res['API']);
          this.guestForm.set(res['API']);
        }
      });
    }

    save() {
        this.close();
    }

    delete() {
        this.close();
    }

    close() {
        this.viewCtrl.dismiss(this.options.termin);
    }
}
