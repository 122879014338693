import { Directive, ElementRef, Input, Renderer } from '@angular/core'; //, HostListener
import { IONIC_DIRECTIVES, Events, Content, NavController, Config } from 'ionic-angular';

let me;

@Directive({
    selector: '[header-directive]',
    host: {
      '(window:resize)': 'onResize($event)'
    }
})
export class HeaderDirective {
  @Input("header-directive") public content: Content;

  private headerHeight;
  private contentHeight;
  private pageHeight;
  private scrollPos: number = 0;
  private scrolltoTop: boolean = false;
  private lastscrollPos: number = 0;
  private el;
  private scroll_content;
  private calendarSlider;
  private resizing: boolean = false;
  private scrolling: boolean = false;
  private header: boolean = true;
  private scrollbar: boolean = false;
  private tabs = null;
  private tabsHeight: number = 0;

  constructor(
    private element: ElementRef,
    private config: Config,
    private renderer: Renderer,
    private nav: NavController
  ){
    me = this;
    me.el = element.nativeElement;
    me.renderer = renderer;
    //renderer.setElementStyle(me.el, 'backgroundColor', 'yellow');
  }
  /*
  @HostListener('click') click() {
    //console.log("click");
  }
  */
  onResize(e){
    //console.log("HeaderDirective: onResize");
    if(!me.resizing){
      me.resizing = true;
      setTimeout( ()=>{
        //console.log("resizing");
        me.scrollbarCheck();
        me.resizing = false;
      },500);
    }
  }

  ngOnInit() {
    //setTimeout(()=>{
      //me.scrollbarCheck();
    //},100);
  }

  ngAfterViewInit() {
    //console.log("headerDirective - ngAfterViewInit");
    //console.log("scroll_content",me.scroll_content);

    //console.log("headerDirective - nav",me.nav);
    var tabs = null;
    if(me.nav.parent){
      if(me.nav.parent.elementRef.nativeElement.nodeName == "ION-TABS"){
        //console.log("HAS TABS: "+me.nav.parent._tabs.length);
        if(me.nav.parent._tabs.length > 0){
          //console.log("HAS TABS: "+me.nav.parent._tabs.length,me.nav);
          //console.log("TABS HEIGHT: "+me.nav.parent.elementRef.nativeElement.children[0].clientHeight);
          tabs = me.nav.parent.elementRef.nativeElement;
          me.tabsHeight = me.nav.parent.elementRef.nativeElement.children[0].clientHeight;
        }
      }
    }
    if(!tabs){
      //console.log("NO TABS");
      me.tabsHeight = 0;
    }
    me.tabs = tabs;
    //console.log("me.tabs",me.tabs);

    //setTimeout(()=>{
      me.scrollbarCheck();
    //},100);
    me.getHeaderHeight();
    me.content.addScrollListener(me.onPageScroll);
  }

  eventAction(action){
    //console.log("headerDirective - action: "+action);
    if(action == "contentChanged"){
      setTimeout(()=>{
        me.scrollbarCheck();
      },100);
    }
  }

  getHeaderHeight(){
    //console.log("me.el.nodeName", me.el.nodeName );
    if(!me.headerHeight && me.el.nodeName == "ION-HEADER"){
      me.headerHeight = me.el.clientHeight;
      //console.log("getHeaderHeight "+me.headerHeight,me.el);
    }
  }

  getScrollEl(){
    var parent = me.el.parentNode;
    me.scroll_content = parent.querySelector('scroll-content');
  }

  getHeights(){
    if(!me.scroll_content){
      me.getScrollEl();
    }
    me.contentHeight = me.scroll_content.clientHeight;
    //console.log(me.content);
    if(me.content._scroll){
      var scrollChildren = me.content._scroll._el.children;
      //console.log(scrollChildren);
      me.getHeaderHeight();
      //console.log("headerHeight: "+me.headerHeight);
      //scroll-content padding-top und tabsHeight im fullscreen modus hinzufügen
      var height = me.headerHeight+me.tabsHeight;
      //console.log("startheight: "+height);
      for(var item in scrollChildren){
        if(typeof(scrollChildren[item]) == 'object'){
          //console.log(scrollChildren[item],scrollChildren[item].clientHeight);
          height += scrollChildren[item].clientHeight;
        }
      }
      if(height > me.contentHeight){
        me.contentHeight = height;
      }
    } else {
      //console.log("no scroll-content");
    }

    me.pageHeight = me.el.parentNode.clientHeight;
    //console.log("contentHeight: "+me.contentHeight+" - pageHeight: "+me.pageHeight);
  }

  onPageScroll(event) {
    me.scrollPos = event.target.scrollTop;
    me.config.set('scrollPos',me.scrollPos);
    if(!me.scrolling){
      me.scrolling = true;
      //setTimeout( ()=>{
        //console.log("resizing");
        me.updateHeader();
        me.scrolling = false;
      //},500);
    }
  }

  scrollbarCheck(){
    var scrollbar: boolean;
    if(this.config.get('mode') != "app" && !this.config.get('mobile')){
      scrollbar = true;
    } else {
      me.getHeights();
      if(me.contentHeight > me.pageHeight){
        scrollbar = true;
      } else {
        scrollbar = false;
      }
    }
    if(scrollbar != me.scrollbar){
      me.scrollbar = scrollbar;
      me.renderer.setElementClass(me.el, 'tfm-scrollbar', me.scrollbar);
      //console.log("tabs",me.tabs);
      if(me.tabs){
        //console.log("set tabs scrollsettings");
        me.renderer.setElementClass(me.tabs, 'tfm-scrollbar', me.scrollbar);
      }
      //console.log("scrollbar: "+me.scrollbar);
    }
  }

  updateHeader(){
    var header;
    var yPos: number;

    //console.log(me.lastscrollPos+":"+me.scrollPos);
    if(me.lastscrollPos > me.scrollPos){
      me.scrolltoTop = true;
    } else {
      me.scrolltoTop = false;
    }
    //console.log(me.scrolltoTop);
    me.lastscrollPos = me.scrollPos;

    if (me.scrollPos > me.headerHeight*1.5 && !me.scrolltoTop) {
      yPos = me.headerHeight;
      header = false;
    } else {
      yPos = 0;
      header = true;
    }

    if(header != me.header){
      me.header = header;
      //console.log("header: "+me.header);
      //me.el.style.webkitTransform = 'translate3d(0,-'+yPos+'px,0) scale(1,1)';
      me.renderer.setElementStyle(me.el, 'transform', 'translate3d(0,-'+yPos+'px,0) scale(1,1)');
      if(me.tabs){
        me.renderer.setElementStyle(me.tabs.children[0], 'transform', 'translate3d(0,-'+yPos+'px,0) scale(1,1)');
      }
    }
  }
}
