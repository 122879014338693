import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, ActionSheet, NavController, Page } from 'ionic-angular';

import { dataService } from '../../services/dataService';
import { pouchDbService } from '../../services/pouchDbService';

@Component({
  templateUrl: 'build/pages/todos-add/add.html'
})
export class TodoAddPage {

    public todoList: Array<string>;
    public todoItem: string;
    private todoObj: any;
    private _db: string;

    constructor(
        private config: Config,
        private events: Events,
        private nav: NavController,
        private dataService: dataService,
        private pouchDbService: pouchDbService,
        private translate: TranslateService
      ) {
        this.translate = translate;
        this.translate.instant('json.string');
        this._db = 'todos';

        this.todoList = JSON.parse(localStorage.getItem(this._db));
        if(!this.todoList) {
            this.todoList = [];
        }
        this.todoObj = {};
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    save() {
        console.log("save");
        console.log(this.todoItem);
        if(this.todoItem != "") {
            this.todoObj.name = this.todoItem;
            this.todoObj.segment = 'all';
            //this.todoList.push(this.todoObj);
            //localStorage.setItem(this._db, this.todoList, JSON.stringify(this.todoList));
            this.pouchDbService.add(this._db, this.todoObj);

            this.nav.pop();
        }
    }

}
