import { Component, ViewChild } from '@angular/core';
import { Events, Config, Content, NavController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { UserData } from '../../providers/user-data';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';
//import { ElasticHeader } from '../../directives/elastic-header';
import { ParallaxHeader } from '../../components/parallax-header/parallax-header';

@Component({
  templateUrl: 'build/pages/home/home.html',
  directives: [ToolbarMenu,HeaderDirective,ParallaxHeader],
  providers: [HeaderDirective]
})
export class HomePage {
  @ViewChild(Content) private content: Content;

  //private content: Content;
  private toolbar_options:any = [];
  private username: string;
  private sessionToken: string;
  private headerImage: string = 'https://ununsplash.imgix.net/photo-1421091242698-34f6ad7fc088?fit=crop&fm=jpg&h=650&q=75&w=950';
  private dummyImage: string = 'https://ununsplash.imgix.net/photo-1421091242698-34f6ad7fc088?fit=crop&fm=jpg&h=50&q=75&w=50';
  private items: any;
  private parallax_header_options: any = [];

  constructor(
    //private content: Content,
    private config: Config,
    private events: Events,
    public nav: NavController,
    private headerDirective: HeaderDirective,
    private userData: UserData,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Home')
    };
    //this.parallax_header_options.content = content;

    this.parallax_header_options.blur = true;
    this.parallax_header_options.parallax = true,
    this.parallax_header_options.headerImg = 'https://ununsplash.imgix.net/photo-1421091242698-34f6ad7fc088?fit=crop&fm=jpg&h=650&q=75&w=950';

    this.nav = nav;

    this.items = [];
    var dummy = {
      img: this.dummyImage
    }
    for(var i=0;i<100;i++){
      this.items.push(dummy);
    }
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
    if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
  }

  ngAfterViewInit() {
    this.getUsername();
  }

  getUsername() {
    /*this.userData.getUsername().then((username) => {
      this.username = username;
    });*/
    this.username = this.userData.getUsername();
  }

  doRefresh(refresher) {
    console.log('Begin async operation', refresher);

    setTimeout(() => {
      console.log('Async operation has ended');
      refresher.complete();
    }, 2000);
  }

}
