import { Injectable, EventEmitter } from '@angular/core';
import { Events, Config } from 'ionic-angular';

let mx: number = 0;
let my: number = 0;
let mouse_x: number = 0;
let mouse_y: number = 0;
let mouseMoveHandler;
let mouseUpHandler;
let me;
let timestampBreak:number = 0;

declare var require: any;

@Injectable()
export class belegungsplanService {

  //BELEGUNGSPLAN
  private bplan_id: string = "";
  private bplan;
  private bplan_info;
  private bplan_right_id: string;
  private bplan_left_id: string;
  private bplan_width;
  private bplan_started = [];
  private bplan_breakpoint = 680;
  private params:any = [];
  private now_resizing: boolean = false;
  private start = 0;
  private bplan_event_timestamp = 0;
  private bplan_event_timestamp_diff = 200;
  private reservations: any;
  private termine = [];
  private tag = 0;
  private zimmer = 0;
  private tage = 0;
  private zimmer_anzahl = 0;
  private tag_width;
  private tag_height;
  private info_boxes = 3;
  private info_width = 60;
  private stage_width;
  private stage_height;
  private text_size = 9;
  private text_x = 6;
  private text_y;
  private obj = [];
  private obj_zahl = [];
  private obj_info = [];
  private obj_info_zahl = [];
  private obj_sauber = [];
  private kopfBox;
  private infoBox;
  private tagBox;
  private sauberBox;
  private wochentagBox;
  private tagDef;
  private sauberDef;
  private wochentagDef;
  private kopfDef;
  private infoDef;
  private paper = [];
  private dragging = false;
  private drag_interval;
  private start_termin_x = 0;
  private start_termin_y = 0;
  private mouse_start_x_diff = 0;
  private mouse_start_y_diff = 0;
  private start_mouse_x = 0;
  private start_mouse_y = 0;
  private create: any;
  private start_stage_x;
  private stage_x:number;
  private stage_y:number;
  private body_width;
  private sauberSize = 10;
  private Snap;
  private this_text;
  private terminStatus: any = [];
  private terminStatusNative: any = [];
  private item: any;
  private locked: boolean = false;
  private drawingCounter: number;
  private reservationStartData: any;

  constructor(
    private config: Config,
    private events: Events
  ) {
    //console.log("belegungsplanService");
    this.create = [];
    this.create['new_termin'] = 0;
    this.create['tag'] = 0;
    this.create['zimmer'] = 0;

    if(!this.config.get('scrollPos')){
      this.config.set('scrollPos',0);
    }

    //FREI
    this.terminStatus[0] = '#efefef';

    /*ROT BELEGT EINGESCHECKT*/
    this.terminStatusNative['11 F'] = 'Belegt';
    this.terminStatusNative['11 H'] = 'Belegt';
    this.terminStatusNative['101F'] = 'Belegt';
    this.terminStatusNative['101H'] = 'Belegt';
    this.terminStatus[1] = "#ff8788";
    //this.terminStatus[1] = "#fb0002";

    /*GRUEN ANGEBOT*/
    this.terminStatusNative['21 F'] = 'Angebot';
    this.terminStatusNative['21 H'] = 'Angebot';
    this.terminStatusNative['200F'] = 'Angebot';
    this.terminStatusNative['200H'] = 'Angebot';

    this.terminStatusNative['21 F'] = 'Angebot';
    this.terminStatusNative['21 H'] = 'Angebot';
    this.terminStatusNative['201F'] = 'Angebot';
    this.terminStatusNative['201H'] = 'Angebot';

    this.terminStatusNative['22 F'] = 'Angebot';
    this.terminStatusNative['22 H'] = 'Angebot';
    this.terminStatusNative['202F'] = 'Angebot';
    this.terminStatusNative['202H'] = 'Angebot';
    this.terminStatus[2] = "#c5ffc4";
    //this.terminStatus[2] = "#40fe42";

    /*DUNKEL BLAU RESERVIERT*/
    this.terminStatusNative['31 F'] = 'Reserviert';
    this.terminStatusNative['31 H'] = 'Reserviert';
    this.terminStatusNative['301F'] = 'Reserviert';
    this.terminStatusNative['301H'] = 'Reserviert';
    this.terminStatus[3] = "#c2c5fa";
    //this.terminStatus[3] = "#403fff";

    /*GELB AUSGECHECKT*/
    this.terminStatusNative['41 F'] = 'Ausgecheckt';
    this.terminStatusNative['41 H'] = 'Ausgecheckt';
    this.terminStatusNative['401F'] = 'Ausgecheckt';
    this.terminStatusNative['401H'] = 'Ausgecheckt';
    this.terminStatus[4] = "#f3ff8e";
    //this.terminStatus[4] = "#ffff01";

    /*INTERNET NOCH NICHT BESTAETIGT*/
    this.terminStatus[6] = "#ffb163";
    /*INTERNET NOCH NICHT BESTAETIGT*/
    this.terminStatus[9] = "#ffb163";
  }

  begin(bplan_id,reservations,params,drawingCounter,from){
    //console.log("BEGIN BELEGUNSPLANSERVICE - drawingCounter("+from+"): "+drawingCounter,reservations);
    //if(drawingCounter > 0){
      this.removeTermine();
    //}

    this.bplan_id = bplan_id;
    this.params = params;
    this.reservations = reservations;
    this.drawingCounter = drawingCounter;

    if(drawingCounter == 0){
      this.init();
    }

    this.build();
  }

  clear(){
    //console.log("clear");
    if(this.bplan_started[this.bplan_right_id] && typeof this.paper[this.bplan_right_id] != 'undefined'){
      //console.log("snap removeChild");
      if(this.bplan){
        while (this.bplan.firstChild) {
            //console.log("snap bplan removeChild");
            this.bplan.removeChild(this.bplan.firstChild);
        }
      }
      if(this.bplan_info){
        while (this.bplan_info.firstChild) {
            //console.log("snap bplan_info removeChild");
            this.bplan_info.removeChild(this.bplan_info.firstChild);
        }
      }
    }
  }

  init(){
    //console.log("init");
    this.bplan_left_id = this.bplan_id+'_info';
    this.bplan_right_id = this.bplan_id;
    //this.Snap = window['Snap'];
    if(!this.Snap){
      this.Snap = require('snapsvg');
      //console.log("snap",this.Snap);
    }

    this.tage = this.params.tage;
    if(!this.bplan){
      this.bplan = document.getElementById(this.bplan_right_id);
      this.bplan_info = document.getElementById(this.bplan_left_id);
      //console.log("bplan",this.bplan);
    }

    //console.log("bplan_started: "+this.bplan_started[this.bplan_right_id]);
    //ENTFERNEN
    this.clear();
    if(!this.bplan_started[this.bplan_right_id]){
      this.bplan_started[this.bplan_right_id] = true;
    }

    //ZIMMER
    this.zimmer_anzahl = this.params.rooms.length;

    //DIMENSIONS
    this.scale();

    if(!this.paper[this.bplan_right_id]){
      this.paper[this.bplan_right_id] = new this.Snap('#'+this.bplan_right_id).attr({ viewBox: "0 0 "+this.stage_width+" "+this.stage_height });
      //console.log("new snap paper",this.paper[this.bplan_right_id]);
    }
    //console.log(this.paper[this.bplan_right_id]);
    if(!this.paper[this.bplan_left_id]){
      this.paper[this.bplan_left_id] = new this.Snap('#'+this.bplan_left_id).attr({ viewBox: "0 0 "+this.info_width+" "+this.stage_height });
    }

    //SAUBERBOX
    this.sauberBox = this.paper[this.bplan_right_id].rect(0,0,(this.sauberSize-1),(this.sauberSize-1)).attr('id','sauberBox');
    this.sauberDef = this.sauberBox.toDefs();

    //WOCHENTAGBOX
    //if(!this.wochentagDef){
      this.wochentagBox = this.paper[this.bplan_right_id].rect(0,0,(this.tag_width-1),(this.tag_height-1)).attr({'fill':'#000','opacity':.1}).attr('id','wochentagBox');
      this.wochentagDef = this.wochentagBox.toDefs();
      //console.log("this.wochentagDef",this.wochentagDef);
    //}

    //KOPFBOX
    this.kopfBox = this.paper[this.bplan_right_id].rect(0,0,(this.tag_width-1),(this.tag_height-1)).attr({'fill':'#fff','opacity':.4}).attr('id','kopfBox');
    this.kopfDef = this.kopfBox.toDefs();

    //INFOBOX
    this.infoBox = this.paper[this.bplan_right_id].rect(0,0,(this.info_width-1),(this.tag_height-1)).attr({'fill':'#fff','opacity':.4}).attr('id','infoBox');
    this.infoDef = this.infoBox.toDefs();

    //TAGEBOX
    this.tagBox = this.paper[this.bplan_right_id].rect(0,0,(this.tag_width-1),(this.tag_height-1)).attr({'fill':'#fff','opacity':.9}).attr('id','tagBox');
    /*
    this.tagBox.mousedown( (e) => {
      //console.log("tagBox mousedown");
      this.removeTermin(0);
      this.BPmouseDown(e);
    });
    */

    this.tagDef = this.tagBox.toDefs();

    var this_text;
    var weekday;
    var divider;
    var abstand;
    var yPos;

    //console.log("this.zimmer_anzahl: "+this.zimmer_anzahl);
    //console.log("this.tage: "+this.tage);
    for(var z=0;z<=(this.zimmer_anzahl+1);z++){
      this.obj[z] = [];
      this.obj_zahl[z] = [];
      this.obj_sauber[z] = [];

      for(var t=0;t<this.tage;t++){
        if(t==0){
          //Zimmer Info Boxen
          this.obj_info[z] = this.infoDef.use();
          this.obj_info[z].attr({ 'x' : (this.tag_width*t), 'y' : (this.tag_height*z) });
          //console.log("append "+z);
          this.paper[this.bplan_left_id].append( this.obj_info[z] );
          //Zimmer Info Boxen Beschriftung
          if(z>1){
            yPos = (this.tag_height*z)+this.tag_height-this.tag_height/2+2;

            //console.log("ZIMMER_SAUBER: "+this.params.rooms[(z-2)].ZIMMER_SAUBER);
            this.obj_sauber[z] = this.sauberDef.use();
            this.paper[this.bplan_left_id].append( this.obj_sauber[z] );
            if(this.params.rooms[(z-2)].ZIMMER_SAUBER == ""){
              //console.log((z-2)+". rot");
              this.obj_sauber[z].attr({'fill': this.params.rot});
            } else {
              this.obj_sauber[z].attr({'fill': this.params.gruen});
            }
            this.obj_sauber[z].attr({ 'x' : 3, 'y' : yPos-this.sauberSize+2 });

            this.obj_info_zahl[z] = this.paper[this.bplan_right_id].text(this.text_x, this.text_y, this.params.rooms[(z-2)].ZIMMER_NAME );
            this.obj_info_zahl[z].attr({'fill' : '#000',  'stroke': 'black', 'stroke-width': 0.1, 'font-size': this.text_size+'px' });
            this.obj_info_zahl[z].attr({ 'x' : 6+this.sauberSize, 'y' : yPos });
            this.paper[this.bplan_left_id].append(this.obj_info_zahl[z]);
          }
        }

        //Boxen
        if(z==0){
          this.obj[z][t] = this.wochentagDef.use();
        } else if(z==1){
          this.obj[z][t] = this.kopfDef.use();
        } else {
          this.obj[z][t] = this.tagDef.use();
        }

        this.paper[this.bplan_right_id].append( this.obj[z][t] );
        this.obj[z][t].attr({ 'x' : (this.tag_width*t), 'y' : (this.tag_height*z), 'tag': (t+1), 'zimmer': z });

        if(this.config.get('mode') != "app"){
          this.obj[z][t].mousedown( (e) => {
            //console.log("tagBox mousedown");
            this.removeTermin(0);
            this.BPmouseDown(e);
          });
        } else {
          this.obj[z][t].click( (e) => {
            //console.log("tagBox mousedown");
            this.removeTermin(0);
            this.BPmouseDown(e);
          });
        }
        //Kopfboxen
        if(z<2){
          if(z==0){
            //Wochentag
            weekday = (t+this.params.weekday*1);
            if(weekday > 6){
              divider = Math.floor(weekday/7);
              weekday = weekday-7*divider;
            }
            this.this_text = this.params.weekdays[weekday].replace(".","");
            abstand = 0;
          } else {
            //Tage
            this.this_text = (1*t)+1;
            abstand = 0;
          }
          this.obj_zahl[z][t] = this.paper[this.bplan_right_id].text(this.text_x, this.text_y, this.this_text );
          this.obj_zahl[z][t].attr({'fill' : '#000',  'stroke': 'black', 'stroke-width': 0.1, 'font-size': this.text_size+'px' });
          this.paper[this.bplan_right_id].append(this.obj_zahl[z][t]);
          this.obj_zahl[z][t].attr({ 'x' : (this.tag_width*t)+this.tag_width/2-this.getZahlWidth(this.this_text)-abstand, 'y' : (this.tag_height*z)+this.tag_height-5 });
        }
      }

      this.getStartStageX();
    }

    if(this.drawingCounter == 0){
      this.addListener();
    }

    //console.log("init startResize");
    this.startResize();
  }

  addListener(){
    //console.log("addListener");
    if(this.config.get('mode') != "app"){
      this.removeListener();
      me = this;
      //console.log("addListener");
      mouseUpHandler = function(e){
        me.BPmouseUp(e);
      }
      document.body.addEventListener("mouseup",mouseUpHandler,false);

      mouseMoveHandler = function(e){
        me.mouseEvent(e);
      }
      document.body.addEventListener('mousemove', mouseMoveHandler, false);
    }
  }

  removeListener(){
    //console.log("removeListener");
    if(this.config.get('mode') != "app"){
      //console.log("removeListener");
      document.body.removeEventListener('mousemove', mouseMoveHandler, false);
      document.body.removeEventListener("mouseup",mouseUpHandler,false);
    }
  }

  onExit(){
    //console.log("onExit");
    this.removeListener();
    this.clear;
    me = null;
  }

  build(){
    //TERMINE
    //console.log("build drawTermine",this.reservations);
    this.drawTermine();
  }

  setMouseY(y){
    return y+this.config.get('scrollPos');
  }

  mouseEvent(e){
    //console.log("mouseEvent");
    mouse_x = e.pageX;
    mouse_y = this.setMouseY(e.pageY);

    if(this.create['new_termin'] == 1){
      this.drawBalken();
    }

    //console.log("mouse: "+mouse_x+"/"+mouse_y);
  }

  getSizes(){
    this.body_width = document.getElementById('body_width').clientWidth;

    this.tag_height = 30;
    if(this.body_width < this.bplan_breakpoint){
      //Mobile
      this.tag_width = 30;
    } else {
      //Desktop
      this.tag_width = (this.body_width-this.info_width)/this.tage;
    }
    this.stage_width = this.tage*this.tag_width;
    this.bplan.style.minWidth = this.stage_width+'px';

    if(typeof this.paper[this.bplan_right_id] != 'undefined'){
      this.paper[this.bplan_right_id].attr({viewBox: "0,0,"+this.stage_width+","+this.stage_height});
    }
  }

  scale(){
    this.bplan_info = document.getElementById(this.bplan_left_id);
    this.getSizes();

    this.stage_height = this.tag_height*(this.zimmer_anzahl+2);
    this.text_y = (this.tag_height-2)-(this.tag_height-this.text_size)/2;
    this.stageFactor();
    this.bplan_info.style.width = this.info_width+'px';
    this.bplan_info.style.height = this.stage_height+'px';
    document.getElementById('col_left').style.width = this.info_width+'px';

    this.bplan.style.height = this.stage_height+'px';
    document.getElementById('col_right').style.left = this.info_width+'px';
    document.getElementById('col_right').style.height = this.stage_height+'px';
    document.getElementById('col_right_scroll').style.height = this.stage_height+'px';
  }

  stageFactor(){
    if(typeof this.stage_x == 'undefined'){
      var stage_pos = this.getOffset( document.getElementById('zimmerplan') );
      this.stage_y = stage_pos.top;
      this.stage_x = stage_pos.left;
    }
    //console.log("stage_x: "+this.stage_x+" - stage_y: "+this.stage_y);
    this.bplan_width = document.getElementById('zimmerplan').clientWidth;
  }

  getOffset(el) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + window.scrollX,
      top: el.top + window.scrollY
    }
  }

  drawTermine(){
    for(var i=0;i<this.reservations.length;i++){
      //console.log("drawTermin: "+i);
      this.drawTermin(this.reservations[i]);
    }
  }

  checkReservation(reservation){
    //console.log("checkReservation - reservation: ",reservation);
    var valid = true;
    for(var i=0;i<this.reservations.length;i++){
      //console.log("drawTermin: "+i);
      if(this.reservations[i].LFNUMMER != reservation.LFNUMMER && this.reservations[i].ZIMMER_NR == reservation.ZIMMER_NR){
        //console.log("res: ",reservation);
        //console.log("reservations: ",this.reservations[i]);
        var start = this.reservations[i].tag*1;
        var ende = (start+this.reservations[i].dauer*1)-1;
        var new_start = reservation.tag*1;
        var new_ende = (new_start+reservation.dauer*1)-1;
        if(new_start <= ende && new_ende >= start){
          valid = false;
          //console.log("valid: "+valid+" "+new_start+"-"+new_ende+" und "+start+"-"+ende);
        }
      }
    }
    return valid;
  }

  drawTermin(reservation){
    //console.log("start add_termin");
    if (reservation instanceof Object){
      this.add_termin(this.paper[this.bplan_right_id],reservation);
      //reservation.el = '1';
      //console.log("el "+reservation.LFNUMMER+" = 1");
    } else {
      //console.log("no instance");
    }
  }

  add_termin(this_paper,termin_){
    //console.log("add",termin_,"paper",this_paper);
      if(typeof termin_.info == 'undefined'){
        termin_.info = 'Neu';
      }
      var termin_x = this.getBoxTagPosition(termin_.tag);
      var termin_y = this.getBoxZimmerPosition(termin_.ZIMMER_NR);

      //MASK
      var mask = this_paper.rect( termin_x, termin_y, (this.tag_width*termin_.dauer-1), (this.tag_height-1)).attr('fill', '#fff');

      //POLYGON
      var box = this_paper.polygon(
        //links oben
        termin_x,termin_y,
        //links unten
        termin_x,termin_y+this.tag_height,
        //unten rechts
        termin_x+(this.tag_width*termin_.dauer-1),termin_y+this.tag_height,
        //mitte rechts
        termin_x+(this.tag_width*termin_.dauer-1)+this.tag_width/3,termin_y+this.tag_height/2,
        //oben rechts
        termin_x+(this.tag_width*termin_.dauer-1),termin_y
      );
      box.attr({'fill':this.terminStatus[termin_.STATUS], 'opacity':.75} );

      //INFO
      var info = this_paper.text( (termin_x+this.text_x), (termin_y+this.text_y), termin_.info);
      info.attr({
        'fill' : '#000',
        'stroke': 'black',
        'stroke-width': 0.1,
        'font-size': this.text_size+'px'
      });
      info.attr({
          mask: mask
      });

      //CLICKAREA
      var clickarea = this_paper.rect( termin_x, termin_y, (this.tag_width*termin_.dauer-1), (this.tag_height-1));
      clickarea.attr({'fill':'#000', 'opacity':0} );

      this.termine[termin_.LFNUMMER] = this_paper.group(box,info,clickarea);
      this.termine[termin_.LFNUMMER].attr({
        'class': 'termin',
        'link':termin_.info,
        'data-x-orig':termin_x,
        'data-y-orig':termin_y,
        'x':termin_x,
        'y':termin_y,
        'LFNUMMER':termin_.LFNUMMER,
        'id':'t_'+termin_.LFNUMMER
      });
      this.termine[termin_.LFNUMMER].el = 1;

      //TITLE
      var title = this.Snap.parse('<title>'+termin_.info+'</title>');
      this.termine[termin_.LFNUMMER].append( title );

      this.termine[termin_.LFNUMMER].mousedown( (event) => {
        //console.log("mousedown");

        this.getTouchPos(event);

        //SET EVENT ITEM
        this.item = event.target.parentNode;
        this.item.title = this.item.getElementsByTagName('title')[0]['innerHTML'];
        this.item.x = this.item.getAttribute('x');
        this.item.y = this.item.getAttribute('y');
        this.item.LFNUMMER = this.item.getAttribute('LFNUMMER');
        this.item.data_x_orig = this.item.getAttribute('data-x-orig');
        this.item.data_y_orig = this.item.getAttribute('data-y-orig');

        this.start_termin_x = this.item.x*1;
        this.start_termin_y = this.item.y*1-this.tag_height*2;
        this.start_mouse_x = this.cleanMouseX();
        this.start_mouse_y = this.cleanMouseY();
        this.mouse_start_x_diff = this.start_mouse_x-this.start_termin_x;
        this.mouse_start_y_diff = this.start_mouse_y-this.start_termin_y;

        var startData = this.getReservation(this.item.LFNUMMER);
        this.reservationStartData = {
          tag:startData.tag,
          zimmer:startData.ZIMMER_NR
        };
        //console.log(this.reservationStartData);

        //console.log(event.target);
        //console.log(this.item);
        //console.log("x: "+this.item.x);
        //console.log("y: "+this.item.y);
        //console.log("2. start_mouse_x: "+this.start_mouse_x);
        //console.log("start_mouse_y: "+this.start_mouse_y);

        this.end_drag();
        this.dragging = true;
        this.start = 1;
        this.bplan_event_timestamp = this.getTimestamp();
        timestampBreak = (this.bplan_event_timestamp+this.bplan_event_timestamp_diff);

        //console.log("bplan_event_timestamp: "+this.bplan_event_timestamp);
      });

      if(this.config.get('mode') != "app"){
        this.termine[termin_.LFNUMMER].click( () => {
          this.terminClick();
        });
      } else {
        this.termine[termin_.LFNUMMER].touchend( () => {
          this.terminClick();
        });
      }

      this.termine[termin_.LFNUMMER].drag(onMouseMove, startFunc, stopFunc );

      this.termine[termin_.LFNUMMER].mouseup( () => {
        //console.log("mouseup");
        setTimeout( () => {
          //console.log("mouseup 2");
          //console.log("-------- termin mouseup - start: "+this.start+" - dragging: "+this.dragging);
          this.stopFunc();
        },50)
      });
  }

  terminClick(){
    //console.log("click");
    //console.log("event");
    //console.log(event);
    var this_stamp = this.getTimestamp();
    if(this_stamp < this.bplan_event_timestamp+this.bplan_event_timestamp_diff){
      //console.log("this_stamp: "+this_stamp);
      //console.log("Termin Info: "+this.item.title);
      //alert("Termin Info: "+this.item.title);

      this.events.publish('reservation:detail',this.getReservation(this.item.LFNUMMER));
    }
  }

  getTouchPos(event){
    if(this.config.get('mode') == "app"){
      if(event.changedTouches){
        mouse_x = event.changedTouches[0].pageX;
        mouse_y = this.setMouseY(event.changedTouches[0].pageY);
      } else if(event.originalEvent){
        if(event.originalEvent.touches){
          mouse_x = event.originalEvent.touches[0].pageX;
          mouse_y = this.setMouseY(event.originalEvent.touches[0].pageY);
        } else {
          //console.log("1 no touch pos");
        }
      } else {
        //console.log("2 no touch pos");
      }
    } else {
      /*
      //console.log("0. mouse_x: "+mouse_x);
      mouse_x = event.pageX;
      mouse_y = this.setMouseY(event.pageY);
      //console.log("1. mouse_x: "+mouse_x);
      */
    }
  }

  getReservation(termin_nr){
    var reservation = {};
    for(var i=0;i<this.reservations.length;i++){
      if(this.reservations[i].LFNUMMER == termin_nr){
        return this.reservations[i];
      }
    }
    return reservation;
  }
  /*
  startFunc(event) {
    //console.log("this.startFunc");
    this.start = 1;

    this.mouse_start_x_diff = 0;
    this.mouse_start_y_diff = 0;
    this.end_drag();
    this.dragging = true;
  }
  */
  stopFunc() {
    //console.log("stopFunc");

    if( typeof this.item != 'undefined' && typeof this.getReservation(this.item.LFNUMMER) != 'undefined'){
      if(this.getTimestamp() > this.bplan_event_timestamp+this.bplan_event_timestamp_diff){
        //console.log("TIMECHECK");
        if(
          (this.cleanMouseX() != this.start_mouse_x)
          || (this.cleanMouseY() != this.start_mouse_y)
          || this.config.get('mode') == "app" && my != 0 && mx != 0
        ){
          if(this.config.get('mode') == "app"){
            //console.log("a) mouse_x:"+mouse_x+" mx:"+mx);
            //console.log("a) mouse_y:"+mouse_y+" my:"+my);
            mouse_x = this.start_mouse_x;
            mouse_x = this.cleanMouseXReverse()+mx;
            mouse_y = this.start_mouse_y;
            mouse_y = this.cleanMouseYReverse()+my;
            //console.log("b) mouse_x: "+mouse_x);
            //console.log("b) mouse_y: "+mouse_y);
            mx = 0;
            my = 0;
          }
            //console.log("DRAGGED "+this.item.LFNUMMER)
            this.getMousePos();
            //console.log("tag: "+this.tag+"/"+this.tag_width+" - zimmer: "+this.zimmer+"/"+this.tag_height);
            var termin_x = this.getBoxTagPosition(this.tag);
            var termin_y = this.getBoxZimmerPosition(this.zimmer);
            //console.log("termin_x: "+termin_x+" - termin_y: "+termin_y);

            //PROBLEM
            //this.attr({'x':termin_x, 'y':termin_y, 'transform': 't'+(termin_x-this.attr('data-x-orig'))+' '+(termin_y-this.attr('data-y-orig'))});

            //console.log("tag: "+this.tag+" - zimmer: "+this.zimmer);
            if(this.tag < 1){
              this.tag = 1;
            }
            if(this.zimmer < 1){
              this.zimmer = 1;
            }
            this.getReservation(this.item.LFNUMMER).tag = this.tag;
            this.getReservation(this.item.LFNUMMER).ZIMMER_NR = this.zimmer;
            this.removeTermin(this.item.LFNUMMER);
            //console.log("drawTermin");
            if(this.checkReservation(this.getReservation(this.item.LFNUMMER))){
              this.drawTermin(this.getReservation(this.item.LFNUMMER));
            } else {
              //console.log("false position",this.reservationStartData);
              if(this.reservationStartData){
                var old = this.getReservation(this.item.LFNUMMER);
                old.tag = this.reservationStartData.tag;
                old.ZIMMER_NR = this.reservationStartData.zimmer;
                this.drawTermin(old);
              }
            }
            this.item = undefined;
            this.drag_interval = setInterval( this.end_drag() , 1000);
            this.reservationStartData = null;
        } else {
          //console.log("no mouse pos difference",this.cleanMouseX(),this.start_mouse_x);
        }
      } else {
        //console.log("ONLY CLICK");
      }
    } else {
      //console.log("item error");
    }

    this.mouse_start_y_diff = 0;
    this.mouse_start_x_diff = 0;
    this.dragging = false;

    this.start = 0;
  }

  end_drag() {
    this.dragging = false;
    clearInterval(this.drag_interval);
  }

  getScrollerPos(){
    return this.getOffset( document.getElementById('zimmerplan').parentNode);
    //console.log(document.getElementById('zimmerplan').parentNode);
  }

  cleanMouseXReverse(){
    return (mouse_x+this.info_width);
  }

  cleanMouseYReverse(){
    return (mouse_y+this.stage_y+this.tag_height*2);
  }

  cleanMouseX(){
    return (mouse_x-this.info_width);
  }

  cleanMouseY(){
    return (mouse_y-this.stage_y-this.tag_height*2);
  }

  getMousePos(){
    this.stageFactor();
    //console.log("------");
    //console.log("mouse_x: "+mouse_x+"/"+this.info_width+" - mouse_y: "+mouse_y+"/"+this.stage_y+"/"+this.tag_height);

    var stage_pos = this.getScrollerPos();
    var mouse_x_diff = stage_pos.left-this.start_stage_x;
    //console.log("mouse_x_diff: "+mouse_x_diff);

    this.getBox(this.cleanMouseX()+mouse_x_diff,this.cleanMouseY());
  }

  getBox(x,y){
    //console.log(x+"/x_diff: "+this.mouse_start_x_diff+" - "+y+"/y_diff: "+this.mouse_start_y_diff);

    var stage_pos = this.getScrollerPos();
    var mouse_x_diff = stage_pos.left-this.start_stage_x;
    //console.log("mouse_x_diff: "+mouse_x_diff);

    this.zimmer = Math.ceil((y-this.mouse_start_y_diff)/this.tag_height);
    this.tag = Math.ceil((x-this.mouse_start_x_diff-mouse_x_diff)/this.tag_width);
    this.create['tag'] = this.tag;
    this.create['zimmer'] = this.zimmer;
  }

  getTimestamp(){
    if (!Date.now) {
      Date.now = function() { return new Date().getTime(); }
    }
    return Date.now();
  }

  getBoxTagPosition(this_tag){
    var x = (this.tag_width*this_tag-this.tag_width);
    return x;
  }

  getBoxZimmerPosition(this_zimmer){
    return (this.tag_height*this_zimmer)+this.tag_height;
  }

  getStartStageX(){
    var stage_pos = this.getScrollerPos();
    if(!this.start_stage_x){
      this.start_stage_x = stage_pos.left;
      //console.log("start_stage_x.left: "+this.start_stage_x);
    }
  }

  startResize(){
    //console.log("startResize");
    window.dispatchEvent(new Event('resize'));
  }

  resize(type){
    //console.log("resize");
    if(!this.now_resizing){

      //leeren um danach neu setzen zu koennen
      this.start_stage_x="";
      this.getStartStageX();

      //console.log("now_resizing");
      this.now_resizing = true;
      if(typeof this.body_width != 'undefined'){

        //RASTER
        if(type == "raster" || type == "all"){
          //console.log("body_width: "+this.body_width);
          this.getSizes();
          //console.log("body_width: "+this.body_width);

          this.wochentagBox.attr('width',(this.tag_width-1));
          this.kopfBox.attr('width',(this.tag_width-1));
          this.tagBox.attr('width',(this.tag_width-1));

          var this_text;
          var abstand;
          for(var z=0;z<=(this.zimmer_anzahl+1);z++){
            for(var t=0;t<this.tage;t++){
              this.obj[z][t].attr('x',this.tag_width*t);
              if(z<2){
                if(z==0){
                  this_text = "Mo";
                  abstand = 0;
                } else {
                  this_text = t;
                  abstand = 0;
                }
                this.obj_zahl[z][t].attr({ 'x' : (this.tag_width*t)+this.tag_width/2-this.getZahlWidth(this_text)-abstand});
              }
            }
          }
        }
        //TERMINE
        if(type == "termine" || type == "all"){
          var removedTermine = this.removeTermine();
          if(removedTermine){
            //console.log("resize drawTermine");
            this.drawTermine();
          }
          window.setTimeout(() => {
            this.now_resizing = false;
          },10);
        }

      }
      //console.log("bplan - contentChanged");
      if(this.params.headerDirective){this.params.headerDirective.eventAction('contentChanged');}
    }
  }

  removeTermine(){
    //console.log("removeTermine: "+this.reservations.length);
    var removedTermine = false;
    if(this.reservations){
      for(var i=0;i<this.reservations.length;i++){
        //console.log("remove lfnummer: "+this.reservations[i].LFNUMMER);
        //console.log("res.el: "+this.reservations[i].el);
        if(this.reservations[i] && this.termine[this.reservations[i].LFNUMMER]){
          if(this.termine[this.reservations[i].LFNUMMER].el == '1'){
            this.removeTermin(this.reservations[i].LFNUMMER);
            removedTermine = true;
            //console.log("removed "+this.reservations[i].LFNUMMER);
          } else {
           //console.log("termin el: "+this.termine[this.reservations[i].LFNUMMER].el);
          }
        }
      }
    }
    return removedTermine;
  }

  removeTermin(LFNUMMER){
    if(document.getElementById('t_'+LFNUMMER)){
      document.getElementById('t_'+LFNUMMER).remove();
      this.termine[LFNUMMER].el = '0';
    }
  }

  getZahlWidth(string){
    return 5;
  }

  BPmouseDown(e) {
    //console.log("mousedown");
    var button;
    switch (e.which) {
      case 1: button = 'left'; break;
      case 2: button = 'middle'; break;
      case 3: button = 'right'; break;
    }
    if(button == "left" || this.config.get('mode') == "app" && this.create['new_termin'] != 1){
      //console.log("BPmouseDown");
      //console.log(e);
      //this.getTouchPos(e);

      //if(this.config.get('mode') == "app"){
        this.tag =  e.target.getAttribute('tag')*1;
        this.zimmer =  (e.target.getAttribute('zimmer')*1-1);
        this.create['tag'] = this.tag;
        this.create['zimmer'] = this.zimmer;
        //console.log("tag: "+this.tag+" - zimmer: "+this.zimmer);
      //} else {
        //this.getBox(this.cleanMouseX(),this.cleanMouseY());
      //}
      this.create['new_termin'] = 1;
      this.create['start_tag'] = this.tag;
      this.create['start_zimmer'] = this.zimmer;

      if(this.config.get('mode') == "app"){
        this.createNewTermin();
      }
    }
  }

  BPmouseUp(e){
    //console.log("mouseup");
    //console.log(this.create);

    this.createNewTermin();

    if(!this.dragging){
      this.mouse_start_y_diff = 0;
      this.mouse_start_x_diff = 0;
    }
  }

  createNewTermin(){
    if(this.create['new_termin'] == 1){
      //console.log("create",this.create);
      this.showTagBox();
      var termin_dauer = 0;
      var termin_tag = 0;
      var termin_STATUS = 0;
      if(this.create['start_tag'] > this.tag){
        termin_dauer = this.create['start_tag']-this.tag+1;
        termin_tag = this.tag;
      } else {
        termin_dauer = this.tag-this.create['start_tag']+1;
        termin_tag = this.create['start_tag'];
      }
      var new_nr = this.getNewNr();
      var new_ = {
        LFNUMMER: new_nr,
        dauer: termin_dauer,
        ZIMMER_NR: this.create['start_zimmer'],
        tag: termin_tag,
        STATUS: termin_STATUS,
        info: ''
      }
      this.reservations[new_nr] = new_;
      //console.log("start add_termin",new_);
      this.add_termin(this.paper['zimmerplan'],new_);
    }
    this.create['new_termin'] = 0;
  }

  getNewNr(){
    var lastIndex = 0;
    for(var i=0;i<this.reservations.length;i++){
      lastIndex = i;
    }
    return lastIndex;
    /*
    var LFNUMMER = "new_"+Math.random();
    if(typeof this.reservations[LFNUMMER] == 'undefined'){
      //console.log("nicht vorhanden: "+LFNUMMER);
      return LFNUMMER;
    } else {
      //console.log("schon vorhanden: "+LFNUMMER);
      return this.getNewNr();
    }
    */
  }

  showTagBox(){
    if(typeof this.obj != 'undefined'){
      if(typeof this.obj[(this.create['start_zimmer']+1)] != 'undefined'){
        for(var i=0;i<this.tage;i++){
          //console.log("start_zimmer: "+(this.create['start_zimmer']+1));
          //console.log("tag: "+(i-1));
          this.setVisible(this.obj[(this.create['start_zimmer']+1)][i],0);
        }
      } else {
        //console.log("UNDEFINED 2 - start_zimmer: "+(this.create['start_zimmer']+1));
      }
    } else {
      //console.log("UNDEFINED 1");
    }
  }

  drawBalken(){
    this.showTagBox();
    this.getBox(this.cleanMouseX(),this.cleanMouseY());
    this.setVisible(this.obj[(this.create['start_zimmer']+1)][(this.tag-1)],1);
    if(this.tag > this.create['start_tag']){
      for(var i=this.create['start_tag'];i<=this.tag;i++){
        this.setVisible(this.obj[(this.create['start_zimmer']+1)][(i-1)],1);
      }
    } else if(this.tag < this.create['start_tag']){
      for(var i:any=(this.tag*1);i<=this.create['start_tag'];i++){
        this.setVisible(this.obj[(this.create['start_zimmer']+1)][(i-1)],1);
      }
    }
  }

  setVisible(obj,val){
    if(typeof obj != 'undefined'){
      obj.attr({'show':val});
    }
  }

}

///JAVASCRIPT////

function onMouseMove(dx,dy) {
  //console.log("onMouseMove "+dx+" : "+dy);
  var timestamp = Date.now()*1;
  //console.log(timestamp+":"+timestampBreak);

  //damit beim klick auf mobilen geraeten nicht der termin verschoben wird
  if(timestamp > timestampBreak){

    mx = dx;
    my = dy;
    this.attr({
        transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]
    });
  }
}
function startFunc() {
  //console.log("startFunc");
  mx = 0;
  my = 0;
  this.data('origTransform', this.transform().local );
}
function stopFunc() {
  //console.log("stopFunc mx: "+mx+" - my: "+my);
  this.attr({'mx':mx, 'my':my});
}
