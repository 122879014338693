import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config, Content, NavController, PopoverController, ViewController } from 'ionic-angular';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  template: `
    <ion-list>
      <button ion-item (click)="close()">Learn Ionic</button>
      <button ion-item (click)="close()">Documentation</button>
      <button ion-item (click)="close()">Showcase</button>
      <button ion-item (click)="close()">GitHub Repo</button>
    </ion-list>
  `
})

class PopoverPage {
  constructor(
    private viewCtrl: ViewController,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
  }
  close() {
    this.viewCtrl.dismiss();
  }
}

@Component({
  templateUrl: 'build/pages/program/program.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]

})
export class ProgramPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  private env:any = [];

  constructor(
    private config: Config,
    private events: Events,
    private nav: NavController,
    private popoverCtrl: PopoverController,
    private headerDirective: HeaderDirective,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Program'),
      buttonsRight: true
    };

    this.env = this.config.get('env');
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
    if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
  }

  setLoadingMessage(){
    this.events.publish('setLoadingMessage','TEST');
  }

  presentPopover(event) {
    let popover = this.popoverCtrl.create(PopoverPage);
    popover.present({
      ev: event
    });
  }
}
