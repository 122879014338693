import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Modal, NavParams, ViewController, ActionSheet, NavController, Page } from 'ionic-angular';

import { dataService } from '../../services/dataService';
import { pouchDbService } from '../../services/pouchDbService';

@Component({
  templateUrl: 'build/pages/todos-detail/detail.html'
})
export class TodoDetailPage {

    public todoList: Array<string>;
    public todoItem: string;
    private todoObj: any;

    public todo;
    public isNew = true;
    public action = 'Add';
    public isoDate = '';
    private _db: string;

    constructor(
        private config: Config,
        private events: Events,
        private nav: NavController,
        private viewCtrl: ViewController,
        private navParams: NavParams,
        private dataService: dataService,
        private pouchDbService: pouchDbService,
        private translate: TranslateService
      ) {
        this.translate = translate;
        this.translate.instant('json.string');
        this._db = 'todos';
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    ionViewLoaded() {
        this.todo = this.navParams.get('todo');

        if (!this.todo) {
            this.todo = {};
        } else {
            this.isNew = false;
            this.action = 'Edit';
            this.isoDate = this.todo.Date.toISOString().slice(0, 10);
        }
    }

    save() {
        this.todo.Date = new Date(this.isoDate);

        if (this.isNew) {
            this.pouchDbService.add(this._db,this.todo)
                .catch(console.error.bind(console));
        } else {
            this.pouchDbService.update(this._db,this.todo)
                .catch(console.error.bind(console));
        }

        this.close();
    }

    delete() {
        this.pouchDbService.delete(this._db,this.todo)
            .catch(console.error.bind(console));

        this.close();
    }

    close() {
        this.viewCtrl.dismiss(this.todo);
    }

}
