import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config, Content, NavController, Loading } from 'ionic-angular';
import { FormBuilder, Validators, ControlGroup } from '@angular/common';

//import { AuthData } from '../../providers/auth-data/auth-data';
import { EmailValidator } from '../../validators/email';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  templateUrl: 'build/pages/signup/signup.html',
  //,providers: [AuthData]
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class SignupPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  public signupForm: ControlGroup;
  emailChanged: boolean = false;
  passwordChanged: boolean = false;
  submitAttempt: boolean = false;


  constructor(
    private config: Config,
    private events: Events,
    public nav: NavController,
    private headerDirective: HeaderDirective,
    //public authData: AuthData,
    public formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Create an Account')
    };
    this.nav = nav;
    //this.authData = authData;

    this.signupForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
      password: ['', Validators.compose([Validators.minLength(6), Validators.required])]
    })
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  /**
   * Receives an input field and sets the corresponding fieldChanged property to 'true' to help with the styles.
   */
  elementChanged(input){
    let field = input.inputControl.name;
    this[field + "Changed"] = true;
  }

  /**
   * If the form is valid it will call the AuthData service to sign the user up password displaying a loading
   *  component while the user waits.
   *
   * If the form is invalid it will just log the form value, feel free to handle that as you like.
   */
  signupUser(){
    this.submitAttempt = true;

    if (!this.signupForm.valid){
      console.log(this.signupForm.value);
    } else {
      //this.authData.signupUser(this.signupForm.value.email, this.signupForm.value.password);
      /*
      let loading = Loading.create({
        dismissOnPageChange: true,
      });
      this.nav.present(loading);
      */
    }
  }
}
