import { Component, Input, OnInit } from '@angular/core';
import { IONIC_DIRECTIVES, Config, Events } from 'ionic-angular';

interface PageObj {
  title: string;
  component: any;
  icon: string;
  index?: number;
  mainMenu: boolean;
}

@Component({
  selector: 'toolbar-menu',
  templateUrl: 'build/components/toolbar-menu/toolbar-menu.html',
  directives: [IONIC_DIRECTIVES], // makes all Ionic directives available to your component
  inputs: ['options'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

//@Input('toolbar-menu') title: string;

export class ToolbarMenu {
  private appPages: any = [];
  private breakpoint: number = 800;
  private winWidth: number = 0;
  private visible: boolean = false;
  private options: any = [];

  constructor(
    private config: Config,
    private events: Events
  ) {
    this.appPages = this.config.get('appPages');
  }

  ngOnInit(){
    //console.log("ngOnInit");
    this.setToolbar();
  }

  onResize(e){
    //console.log("onResize");
    this.setToolbar();
  }

  getWidth(){
    this.winWidth = document.body.clientWidth;
    //console.log("winWidth", this.winWidth);
  }

  setToolbar(){
    //console.log("setToolbar");
    this.getWidth();
    if(this.winWidth > this.breakpoint){
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
  openPage(page: PageObj){
    this.events.publish('openPage',page);
  }
}
