import { Component, ViewChild } from '@angular/core';
import { Events, Config, Content, NavController, PopoverController, ViewController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  template: `
    <ion-list>
      <button ion-item (click)="close()">Learn Ionic</button>
      <button ion-item (click)="close()">Documentation</button>
      <button ion-item (click)="close()">Showcase</button>
      <button ion-item (click)="close()">GitHub Repo</button>
    </ion-list>
  `
})
class PopoverPage {

  constructor(
    private viewCtrl: ViewController,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
  }

  close() {
    this.viewCtrl.dismiss();
  }
}


@Component({
  templateUrl: 'build/pages/about/about.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class AboutPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  conferenceDate = '2047-05-17';

  constructor(
    private config: Config,
    private events: Events,
    private nav: NavController,
    private headerDirective: HeaderDirective,
    private popoverCtrl: PopoverController,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Information'),
      buttonsRight: true
    };
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  presentPopover(event) {
    let popover = this.popoverCtrl.create(PopoverPage);
    popover.present({
      ev: event
    });
    this.events.publish('popover', {ev: event, page: PopoverPage});
  }
}
