import { Page, NavParams } from 'ionic-angular';

import { MessengerChatPage } from '../messenger_chat/chat';
import { MessengerVideoPage } from '../messenger_video/video';

import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

@Page({
  templateUrl: 'build/pages/messenger/tabs.html'
})
export class MessengerPage {
  // this tells the tabs component which Pages
  // should be each tab's root Page
  messengerChatPage: any = MessengerChatPage;
  messengerVideoPage: any = MessengerVideoPage;
  mySelectedIndex: number;

  constructor(
    navParams: NavParams,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.mySelectedIndex = navParams.data.tabIndex || 1;
  }
}
