import { Component, Input, OnInit } from '@angular/core';
import { IONIC_DIRECTIVES, Config, Events } from 'ionic-angular';
import {FORM_DIRECTIVES, REACTIVE_FORM_DIRECTIVES} from '@angular/forms';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { databaseService } from '../../services/databaseService';
import { gdatService } from '../../services/gdatService';

@Component({
  selector: 'guest-form',
  templateUrl: 'build/components/guest-form/guest-form.html',
  directives: [IONIC_DIRECTIVES, FORM_DIRECTIVES, REACTIVE_FORM_DIRECTIVES], // makes all Ionic directives available to your component
  inputs: ['options']
})

export class GuestForm {
  private options: any = [];
  private submitAttempt: boolean = false;
  private slideOneForm: FormGroup;
  private form: any = [];

  constructor(
    private config: Config,
    private events: Events,
    private formBuilder: FormBuilder,
    private dbService: databaseService,
    private gdatService: gdatService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');

    this.form.Guest = [];
    this.form.TERMINE1 = [];

    this.slideOneForm = formBuilder.group({
      VORNAME: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
      FAMNAME: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
      VON: ['', Validators.required],
      BIS: ['', Validators.required],
      time: ['', Validators.required],
      done: ['', Validators.required]
    });

  }

  ngOnInit(){
    //console.log("ngOnInit GuestForm");
    if(this.options.autoLoadGuest){
      console.log("ngOnInit autoLoadGuest");
      this.load(this.options.termin);
    }
  }

  elementChanged(input){
      let field = input.inputControl.name;
      this[field + "Changed"] = true;
  }

  set(res){
    //console.log("GuestForm set",res);
    //console.log("res.GuestData",res.GuestData);
    if(typeof res.GuestData != 'undefined'){
      //console.log("res.GuestData.Guest",res.GuestData.Guest);
      this.form.Guest = res.GuestData.Guest;
      console.log("this.form.Guest",this.form.Guest.VORNAME);
    } else {

    }
    if(typeof res.ReservationData != 'undefined'){
      this.form.TERMINE1 = res.ReservationData.TERMINE1;
    } else {

    }
  }

  load(termin){
    //console.log("GuestForm load",termin);
    this.dbService.getTerminInfos(termin).then((res) => {
      if(res['SUCCESS']){
        console.log("guestForm laod",res['API']);
        this.set(res['API']);
      } else {

      }
    });
  }
}
