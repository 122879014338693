import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config, Content, ActionSheet, NavController, Page } from 'ionic-angular';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

import {
  TimeAgoPipe,
  CalendarPipe,
  DateFormatPipe,
  DurationPipe,
  FromUnixPipe
} from 'angular2-moment';

@Component({
  templateUrl: 'build/pages/stats/stats.html',
  pipes: [
    TimeAgoPipe,
    CalendarPipe,
    DateFormatPipe,
    DurationPipe,
    FromUnixPipe
  ],
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})

export class StatsPage {
    @ViewChild(Content) protected content: Content;

    private toolbar_options:any = [];
    private actionSheet: ActionSheet;
    public todoList: Array<string>;
    private statusList: Array<string>;
    private segment: string;
    private myDate: Date;
    private now: any;
    private counter: any;
    private dauer: any;
    private timer_interval: any;
    private subscription: any;

    constructor(
      private config: Config,
      private events: Events,
      private nav: NavController,
      private headerDirective: HeaderDirective,
      private translate: TranslateService
    ) {
      this.translate = translate;
      this.translate.instant('json.string');
      this.toolbar_options = {
        title: translate.instant('Stats')
      };
      //console.log("language: "+config.get('language'));
      moment.locale('de');
      //console.log(moment.locale());

        this.timer_interval = Observable.interval(1000);
        this.subscription = this.timer_interval.subscribe((x) => {
            this.clock();
            //console.log("interval");
          });
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    onPageDidEnter() {
      this.clock();
      this.segment = 'timer';

      this.todoList = JSON.parse(localStorage.getItem("todos"));
      if(!this.todoList) {
          this.todoList = [];
      }
      this.statusList = [];
    }

    ngAfterViewInit() {
      //wird zu früh und nicht sichtbar ausgeführt
      this.progressChart();
    }

    clock(){
        this.myDate = new Date();
        this.now = moment();
        this.counter = moment([2007, 0, 29]).toNow(true);
        this.dauer = this.getDauer();
    }

    getDauer(){
      var von = '2016-07-25 07:00';
      var now_unix = moment().unix();
      var von_unix = moment(von).unix();
      var diff_unix = now_unix-von_unix;
      //console.log("von_unix: "+von_unix);
      var duration = moment.duration(diff_unix*1000); //value in milliseconds
      //console.log("duration: "+duration);
      var hours = duration.hours();
      var minutes = duration.minutes();
      var seconds = duration.seconds();
      var milliseconds = duration.milliseconds();

      var date = moment().hours(hours).minutes(minutes).seconds(seconds).millisecond(milliseconds);
      return date.format("HH:mm:ss");
    }

    progressChart(){
        console.log("progressChart");

        var d3 = window['d3'];

        var id = "timer",
            width = 600,
            height = 600,
            twoPi = 2 * Math.PI,
            progress = 0,
            total = 1308573, // must be hard-coded if server doesn't report Content-Length
            formatPercent = d3.format(".0%");

        var arc = d3.svg.arc()
            .startAngle(0)
            .innerRadius(180)
            .outerRadius(240);

        var elem = document.getElementById(id+"_div");
            if(elem){
              d3.select("#"+id).remove();
            }

        //var svg = d3.select("#"+id)
        var svg = d3.select("#chart").append("div").attr("id", id)
        //RESPONSIVE CODE
                .append("div")
                //ZUM ENTFERNEN UND NEU STARTEN DANACH
                .attr("id", id+"_div")
                .classed("svg-container", true) //container class to make it responsive
                .append("svg")
                //responsive SVG needs these 2 attributes and no width and height attr
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("viewBox", "0 0 600 400")
                //class to make it responsive
                .classed("svg-content-responsive", true)

        	.append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

              var meter = svg.append("g")
                  .attr("class", "progress-meter");

              meter.append("path")
                  .attr("class", "background")
                  .attr("d", arc.endAngle(twoPi));

              var foreground = meter.append("path")
                  .attr("class", "foreground");

              var text = meter.append("text")
                  .attr("text-anchor", "middle")
                  .attr("dy", ".35em");

          foreground.style("fill", "red") // make the body green
            .transition()
            .duration(1000)
            .style("fill", "green");

            //svg.text.text('40');
            foreground
            .attr("d", arc.endAngle(twoPi * 0));

            var i=0;
            d3.transition().duration(10000).tween("progress", function() {
              return function(t) {
                //console.log(i);
                var circle = (twoPi * (i+1)/100 );
                //console.log( circle );
                foreground.attr("d", arc.endAngle(circle));
                text.text(formatPercent(i/100));
                i++;
              };
            });

          /*
          d3.json("https://api.github.com/repos/mbostock/d3/git/blobs/2e0e3b6305fa10c1a89d1dfd6478b1fe7bc19c1e?" + Math.random())
            .on("progress", function() {
              var i = d3.interpolate(progress, d3.event.loaded / total);
              d3.transition().tween("progress", function() {
                return function(t) {
                  progress = i(t);
                  foreground.attr("d", arc.endAngle(twoPi * progress));
                  text.text(formatPercent(progress));
                };
              });
            })
            .get(function(error, data) {
              //meter.transition().delay(250).attr("transform", "scale(0)");
            });
            */
    }
}
