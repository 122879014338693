import { Component, ViewChild } from '@angular/core';
import { Config, Content, Events, NavController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { UserData } from '../../providers/user-data';
import { dataService } from '../../services/dataService';
import { firebaseService } from '../../services/firebaseService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

import { TranslationAddPage } from "../translation-add/add";

@Component({
  templateUrl: 'build/pages/translation/translation.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class TranslationPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  private languagesRef: any;
  private languages: any;
  private translationRef: any;
  private translations: any;
  private translation: any;
  private translationKey: string;
  private translationVal: string;
  private segment: string;
  private update: any = [];
  private baseLanguage: string = "de";
  private del_key: string = "";
  private queryText: string = "";
  private visibleCounter: number = 0;

  constructor(
    public nav: NavController,
    private events: Events,
    private config: Config,
    private headerDirective: HeaderDirective,
    private FBService: firebaseService,
    private dataService: dataService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Translations'),
      buttonsRight: true
    };

    this.translations = [];
    this.translation = [];
    this.languages = [];

    this.nav = nav;
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }

    //console.log("translations ionViewDidEnter");
    this.update = [];
    this.segment = this.config.get('language');
    //this.getLanguages();
    this.languages = this.config.get('languages');
    this.getTranslation();
  }

  ngAfterViewInit() {

  }

  changeSegment(){
    //console.log(this.segment);
    this.setStrings();
  }

  add() {
    //console.log("add");
    /*
    let modal = Modal.create(TranslationAddPage, { segment: segment, translation: this.translation });
    this.nav.present(modal);
    modal.onDidDismiss(() => {});
    */
    this.events.publish('modal',{data: { segment: this.segment, translation: this.translation }, page: TranslationAddPage});
  }

  delConfirm(key){
    this.del_key = key;
    var alert = {
      title: this.translate.instant("Delete Translation"),
      message: this.translate.instant("Do your really want to delete:")+" "+this.dataService.unSanitizeKey(key),
      buttons : [
        {
          text: this.translate.instant("Cancel")
        },
        {
          text: this.translate.instant("Delete"),
          handler: () => {
            //console.log('Delete clicked');
            this.del();
          }
        }
      ]
    };
    this.events.publish('confirm:basic',alert);
  }

  search(){
    //console.log("search - queryText: "+this.queryText);
    var result = this.dataService.search(this.translation,'key,key_save,text',this.queryText);
    this.translation = result.data;
    this.visibleCounter = result.visibleCounter;
    //setTimeout(()=>{
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
    //},100);
  }

  del(){
    if(this.del_key != ""){
      var en_key = "";
      for (var i=0;i<this.languages.length;i++) {
          var val = this.languages[i].key;
          if(val != "en"){
            //console.log("del - key: "+this.del_key+" - val: "+val);
            this.FBService.save('translation/'+val+'/'+this.del_key,null);
            if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
          }
      }
      this.del_key = "";
    } else {
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant("Entry could not be deleted")
      };
      this.events.publish('alert:basic',alert);
    }
  }

  saveTranslation(){
    //this.FBService.saveMessage();
    var error = false;
    if(this.translation.length == 0 || typeof this.segment == 'undefined'){
      error = true;
    } else {
      var this_language = {};
      var counter = 0;
      for(var i=0;i<this.translation.length;i++){
        var k = this.translation[i].key;
        var v = this.translation[i].text;
        //console.log("update["+k+"]: "+this.update[k]+" - v: "+v);
        if(typeof this.update[k] == 'undefined'){
          //console.log(k+" v: "+v);
          this_language[k] = v;
        } else {
          //console.log(k+" update: "+this.update[k]);
          this_language[k] = this.update[k];
        }
        if(typeof this_language[k] == 'undefined'){
          //console.log("undefined: "+k+" v: "+v);
          this_language[k] = '';
        }
        counter++;
      }
      if(counter > 0 && this.translations[this.segment] != "" && typeof this.translations[this.segment] != 'undefined'){
        //console.log(this_language);
        this.translations[this.segment] = this_language;
        //console.log(this.translations[this.segment]);

        this.FBService.save('translation/'+this.segment,this.translations[this.segment]);
        if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}

        var alert = {title: this.translate.instant("Success"), msg: this.translate.instant('Translation saved')};
        this.events.publish('alert:basic',alert);
      } else {
        error = true;
      }
    }
    if(error){
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant('No Translations for this language found')
      };
      this.events.publish('alert:basic',alert);
    }
  }

  getTranslation(){
    //console.log("getTranslation");
    var query = {
      orderByKey: true,
    }
    this.translationRef = this.FBService.getDB('translation','object',query);
    return this.translationRef.on('value', (data) => {
      var snapshot = data.val();
    //return this.translationRef.subscribe((snapshot) => {
      //console.log("getTranslation");
      //console.log("segment: "+this.segment);
      //console.log(snapshot);
      //console.log(snapshot[this.segment]);
      this.translations = snapshot;
      this.setStrings();
      //console.log(this.translation);
    });
  }

  setStrings(){
    this.translation = [];
    this.visibleCounter = 0;
    for (var key in this.translations[this.baseLanguage]) {
       if (this.translations[this.baseLanguage].hasOwnProperty(key)) {
          var obj = "";
          var placeholder = "";
          if(typeof this.translations[this.segment] == 'undefined'){
            placeholder = this.translations[this.baseLanguage][key];
          } else {
            obj = this.translations[this.segment][key];
            placeholder = obj;
          }
          //console.log(key + " = " + obj);
          var key_save = this.dataService.unSanitizeKey(key);
          this.translation.push({key: key, key_save: key_save, text: obj});
          this.update[key] = obj;
          this.visibleCounter++;
       }
    }
    //console.log("this.translation",this.translation);
    if(this.queryText != "" && typeof this.queryText != 'undefined'){
      this.search();
    } else {
      //setTimeout(()=>{
        if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
      //},100);
    }
  }

}
