import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Config, Content, Slides, Events } from 'ionic-angular';

//import { FormBuilder, ControlGroup, Validators } from '@angular/common';
import {FORM_DIRECTIVES, REACTIVE_FORM_DIRECTIVES} from '@angular/forms';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { dataService } from '../../services/dataService';
import { firebaseService } from '../../services/firebaseService';
import { UserData } from '../../providers/user-data';

import {AgeValidator} from  '../../validators/age';
import {UsernameValidator} from  '../../validators/username';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  templateUrl: 'build/pages/setup/setup.html',
  directives: [ToolbarMenu,HeaderDirective,FORM_DIRECTIVES, REACTIVE_FORM_DIRECTIVES],
  providers: [HeaderDirective]
})
export class SetupPage {
    @ViewChild(Content) protected content: Content;
    @ViewChild('signupSlider') slider: Slides;

    private toolbar_options:any = [];
    private profile: any;

    private slideOneForm: FormGroup;
    private slideTwoForm: FormGroup;

    private firstNameChanged: boolean = false;
    private lastNameChanged: boolean = false;
    private languageChanged: boolean = false;
    //private ageChanged: boolean = false;

    private api_usernameChanged: boolean = false;
    private api_consumerkeyChanged: boolean = false;
    private api_passwordChanged: boolean = false;
    //private privacyChanged: boolean = false;

    private submitAttempt: boolean = false;
    private save_success: boolean = false;
    private steps: number = 2;
    private step: number = 1;
    private languages: any;

    constructor(
      private formBuilder: FormBuilder,
      private events: Events,
      private config: Config,
      private headerDirective: HeaderDirective,
      private dataService: dataService,
      private userData: UserData,
      private FBService: firebaseService,
      private translate: TranslateService
    ) {
      this.translate = translate;
      this.translate.instant('json.string');
      this.toolbar_options = {
        title: translate.instant('Setup')
      };

      this.languages = this.config.get('languages');
      this.profile = this.config.get('profile');
      if(!this.profile.saveGdatNow){
        this.profile.saveGdatNow = false;
      }

      //console.log("profile");
      //console.log(this.profile);

      this.slideOneForm = formBuilder.group({
        firstName: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
        lastName: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])]
        ,language: ['', Validators.required]
        //,age: ['', AgeValidator.isValid]
      });

      this.slideTwoForm = formBuilder.group({
        api_consumerkey: ['', Validators.compose([Validators.minLength(32), Validators.maxLength(32), Validators.pattern('[a-zA-Z0-9 ]*'), Validators.required])],
        api_username: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(255), Validators.pattern('[a-zA-Z0-9 ]*'), Validators.required])],
        api_password: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(255), Validators.pattern('[a-zA-Z0-9 ]*'), Validators.required])],
        saveGdatNow: ['']
        //username: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*')]), UsernameValidator.checkUsername],
        //privacy: ['', Validators.required],
        //bio: ['']
      });

    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }

      if(this.config.get('ApiLoginError')){
        //falsche gdat api login daten
        var confirmObj = {
          title: this.translate.instant("Error"),
          message: this.translate.instant("Invalid GASTROdat API useranme/password"),
          buttons : [
            {
              text: 'OK',
              handler: () => {
                //console.log('OK clicked');
                //this.start();
              }
            }
          ]
        };
        this.events.publish('confirm:basic',confirmObj);
      }
    }

    onSlideChanged(){
      this.step = (this.slider.getActiveIndex()+1);
    }

    next(){
		  //console.log("next: "+this.slider.getActiveIndex()+" - length: "+this.slider.length());
      this.slider.slideNext();
    }

    prev(){
		    //console.log("prev: "+this.slider.getActiveIndex()+" - length: "+this.slider.length());
        this.slider.slidePrev();
    }

    save(formData){
      //console.log("save");
  		if(!this.slideOneForm.valid){
        //console.log("error form 1");
        this.submitAttempt = true;
  			this.slider.slideTo(0);
  		}	else if(!this.slideTwoForm.valid && !this.profile.saveGdatNow){
        //console.log("error form 2");
        this.submitAttempt = true;
  			this.slider.slideTo(1);
  		}	else {
  			console.log("success!")
  			//console.log(this.slideOneForm.value);
  			//console.log(this.slideTwoForm.value);

        var saveObj = this.dataService.merge_options(this.slideOneForm.value,this.slideTwoForm.value);

        //console.log(saveObj);
        this.profile = this.dataService.merge_options(this.profile,saveObj);


        if(this.profile.saveGdatNow){
          this.profile['gdat'] = false;
        } else {
          this.profile['gdat'] = true;
        }
        //delete this.profile.saveGdatNow;
        this.profile.setup = true;

        //console.log(this.profile);

        this.FBService.saveUserSettingsObj(this.profile).then((status) => {
          //console.log("setup save status: "+status);
          if(status){


            //zum zuruecksetzen nach falschen api gdat create session versuch
            if(!this.config.get('userReady')){
              //console.log("start gdatLogin");
              //fuer speichern erfolgreich hinweis ueber dem speichern button
              this.save_success = true;
              this.events.publish('gdatLogin');
            } else {
              var alert = {
                title: this.translate.instant("Success"),
                msg: this.translate.instant("Saved successfully")
              };
              this.events.publish('alert:basic',alert);
            }
          }
        });
      }
    }

    checkboxChanged(input){
      //console.log("checkboxChanged",input,this.profile.saveGdatNow);
      if(!this.profile.saveGdatNow){
         this.profile.api_consumerkey = "";
         this.profile.api_username = "";
         this.profile.api_password = "";
         //this.slideTwoForm.valid =  true;
      }
    }

    elementChanged(input){
      //console.log("elementChanged",input);
      let field = input.inputControl.name;
      this[field + "Changed"] = true;

    }
}
