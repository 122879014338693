import { Injectable } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config } from 'ionic-angular';
import { Http, Headers } from '@angular/http';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

@Injectable()
export class dataService {

  data: any;
  employees: any;
  dataSrc: string;
  shownSessions: number;

  constructor(
    private http: Http,
    private events: Events,
    private config: Config,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    enum dataValues {
      general,
      employees
    };
  }

  equalArrObj(a,b){
    if(a.length != b.length){
      //console.log("equalArrObj - different length");
      return false;
    } else {
      for(var i=0;i<a.length;i++){
        var aObj = this.orderKeys(a[i]);
        var bObj = this.orderKeys(b[i]);
        if( JSON.stringify(aObj) != JSON.stringify(bObj) ){
          //console.log("equalArrObj - different objects");
          //console.log(JSON.stringify(aObj));
          //console.log(JSON.stringify(bObj));
          return false;
        }
      }
      return true;
    }
  }

  orderKeys(obj) {
    //console.log("orderKeys",obj);
    if(obj){
      var keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
          if (k1 < k2) return -1;
          else if (k1 > k2) return +1;
          else return 0;
      });
      var i, after = {};
      for (i = 0; i < keys.length; i++) {
        after[keys[i]] = obj[keys[i]];
        delete obj[keys[i]];
      }
      for (i = 0; i < keys.length; i++) {
        obj[keys[i]] = after[keys[i]];
      }
    }
    return obj;
  }

  checkType(obj){
    var type = "";
    if(obj instanceof Array) {
      type = "Array";
    } else if (obj instanceof String) {
      type = "String";
    } else if (obj instanceof Object) {
      type = "Object";
    } else {
      type = "Unkown";
    }
    //console.log("TYPE:"+type);
    return type;
  }

  emptyCheck(obj,type){
    if(typeof type == 'undefined' || type == ""){
      type = this.checkType(obj);
    }
    if(type == "String" && obj == ""){
      return true;
    } else if(type == "Array" && obj.length == 0){
      //console.log("EMPTY ARRAY");
      //console.log(obj);
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return true;
    } else if(type == "Object"){
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) return false;
      }
      return true;
    } else {
      return false;
    }
  }

  objToArr(obj){
    var type = this.checkType(obj);
    if(type == "Object"){
      var new_arr = [];
      for(var item in obj){
        //console.log("item: "+item);
        //new_arr[item] = obj[item];
        new_arr.push(obj[item]);
      }
      return new_arr;
    }
    return obj;
  }

  arrToObj(arr){
    //console.log("arrToObj");
    //console.log(arr);
    var type = this.checkType(arr);
    if(type == "Array"){
      if(this.emptyCheck(arr,type)){
        //console.log("arrToObj empty");
        return {};
      } else {
        //console.log("arrToObj convert");
        var newObj = {};
        for(var item in arr){
          //console.log("arrToObj item: "+item);
          if(item != '$key'){
            newObj[item] = arr[item];
          }
        }
        //console.log("newObj");
        //console.log(newObj);
        return newObj;
      }
    }
    return arr;
  }

  removeTimestamp(obj){
    var result;
    var type = this.checkType(obj);
    //console.log("removeTimestamp - type: "+type);
    //console.log(obj);
    if(type == "Array"){
      result = [];
    } else {
      result = {};
    }
    var excluded_arr = {
      _id: 1,
      _rev: 1,
      timestamp: 1,
      initial: 1,
      el: 1
    };
    if(type == "Array"){
      for(var i=0;i<obj.length;i++){
        //console.log("removeTimestamp - i: "+i,obj[i]);
        result.push(obj[i]);
      }
      for(var item in excluded_arr){
        delete result[item];
      }
    } else {
      for(var item in obj){
        if(typeof excluded_arr[item] == 'undefined'){
          //console.log("removeTimestamp(Object) - allow: "+item);
          result[item] = obj[item];
        } else {
          //console.log("removeTimestamp(Object) - disallow: "+item);
        }
      }
    }
    //console.log(result);
    //console.log(result['timstamp']);
    //console.log(result.timstamp);
    return result;
  }

  versionCompare(a, b) {
    if(typeof a != 'undefined' && typeof b != 'undefined'){
      var i, diff;
      var regExStrip0 = /(\.0+)+$/;
      var segmentsA = a.replace(regExStrip0, '').split('.');
      var segmentsB = b.replace(regExStrip0, '').split('.');
      var l = Math.min(segmentsA.length, segmentsB.length);

      for (i = 0; i < l; i++) {
          diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
          if (diff) {
              return diff;
          }
      }
      return segmentsA.length - segmentsB.length;
    } else {
      return 1;
    }
  }

  merge_options(obj1,obj2){
      var obj3 = {};
      if(typeof obj1 == 'undefined'){
        obj1 = {};
      }
      if(typeof obj2 == 'undefined'){
        obj2 = {};
      }
      for (var attrname in obj1) {
        if(this.checkType(obj1[attrname]) != 'Object'){
          obj3[attrname] = obj1[attrname];
        } else {
          obj3[attrname] = this.merge_options(obj1[attrname],obj2[attrname]);
        }
      }
      for (var attrname in obj2) {
        if(this.checkType(obj2[attrname]) != 'Object'){
          obj3[attrname] = obj2[attrname];
        } else {
          obj3[attrname] = this.merge_options(obj1[attrname],obj2[attrname]);
        }
      }
      return obj3;
  }

  replaceAll(_string:string, _what:string, _with:string){
  	var r = new RegExp(_what, 'g');
    return _string = _string.replace(r, _with);
  }

  sanitizeKey(key){
    key = this.replaceAll(key, '\\.', '(_p)');
    key = this.replaceAll(key, '#', '(_r)');
    key = this.replaceAll(key, '\\$', '(_d)');
    key = this.replaceAll(key, '\\[', '(_k)');
    key = this.replaceAll(key, ']', '(_k2)');
    key = this.replaceAll(key, '\\!', '(_a)');
    key = this.replaceAll(key, ',', '(_b)');
    key = this.replaceAll(key, '/', '(_oder)');
    key = this.trim(key);
    return key;
  }

  trim(key){
    key = key.trim();
    key = key.replace(/^\s+|\s+$/gm,'');
    key = this.replaceAll(key, '\t','');
    key = this.replaceAll(key, '\r','');
    key = this.replaceAll(key, '\n','');
    return key;
  }

  unSanitizeKey(key){
    key = this.replaceAll(key, '\\(_p\\)', '.');
    key = this.replaceAll(key, '\\(_r\\)', '#');
    key = this.replaceAll(key, '\\(_d\\)', '$');
    key = this.replaceAll(key, '\\(_k\\)', '[');
    key = this.replaceAll(key, '\\(_k2\\)', ']');
    key = this.replaceAll(key, '\\(_a\\)', '!');
    key = this.replaceAll(key, '\\(_b\\)', ',');
    key = this.replaceAll(key, '\\(_oder\\)', '/');
    return key;
  }

  save_json(db_name,new_obj,all_obj) {
      //console.log("save_json");
      //console.log(new_obj);
      all_obj.push(new_obj);
      return localStorage.setItem(db_name, JSON.stringify(all_obj));
  }

  correctLinks(code){
    code = code.replace(/ target="_blank"/g,'');
    code = code.replace(/ target='_blank'/g,"");
    if(this.config.get('mode') == "app"){
      code = code.replace(/ href='/g," class='link' onclick='inAppOpen(this.dataset.href);' data-href='");
      code = code.replace(/ href="/g,' class="link" onclick="inAppOpen(this.dataset.href);" data-href="');
    } else {
      code = code.replace(/ href=/g,' class="link" target="_blank" href=');
    }
    return code;
  }

  strip_shortcodes(code){
    code = code.replace(/\s*\[.*?\]\s*/g, '');
    return code;
  }


  loadData(url) {
    var response = this.http.get(url).map(res => res.json(), error => {console.log(error.json());});
    return response;
  }

  loadDataTest(url) {
    /*if (this.data) {
      // already loaded data
      return Promise.resolve(this.data);
    }*/

    // don't have the data yet
    return new Promise(resolve => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.http.get(url)
        .map(res => res.json())
        .subscribe(
          // we've got back the raw data, now generate the core schedule data
          // and save the data for later reference
          data => {
              this.data = data;
              resolve(this.data);
          }
          /*,
          err => {
              console.log("Oops!",err);

          }
          */
        );
    });
  }

  load(dataSrc:string) {
    //console.log("load dataSrc: "+dataSrc);
    if (this.data) {
      // already loaded data
      return Promise.resolve(this.data);
    }

    // don't have the data yet
    return new Promise(resolve => {
      // We're using Angular Http provider to request the data,
      // then on the response it'll map the JSON data to a parsed JS object.
      // Next we process the data and resolve the promise with the new data.
      this.http.get(dataSrc).subscribe(res => {
        // we've got back the raw data, now generate the core schedule data
        // and save the data for later reference
        //this.data = this.processData(res.json());
        this.data = res.json();
        resolve(this.data);
      });
    });
  }

  getWords(queryText){
    //console.log("getWords - queryText: "+queryText);
    queryText = queryText.toLowerCase().replace(/,|\.|-/g, ' ');
    var queryWords = queryText.split(' ').filter(w => !!w.trim().length);
    //console.log(queryWords);
    return queryWords;
  }

  getSearchKeys(searchKeys){
    var arrSearchKeys = [];

    if(searchKeys.indexOf(',') == -1){
      arrSearchKeys[0] = searchKeys;
    } else {
      arrSearchKeys = searchKeys.split(',');
    }
    if(searchKeys.indexOf(',') == -1){
      arrSearchKeys[0] = searchKeys;
    } else {
      arrSearchKeys = searchKeys.split(',');
    }
    //console.log("arrSearchKeys");
    //console.log(arrSearchKeys);
    return arrSearchKeys;
  }

  search(data,searchKeys,queryText){
    //console.log("dataService search - queryText: "+queryText+" - searchKeys: "+searchKeys);
    var hide = true;
    var text: any = [];
    var queryWords = this.getWords(queryText);
    var arrSearchKeys = this.getSearchKeys(searchKeys);
    var result: any = [];
    result.visibleCounter = 0;

    data.forEach(obj => {
        hide = true;
        text.toSearchText = "";
        arrSearchKeys.forEach(key => {
          //console.log("key: "+key);
          if(text.toSearchText != ""){
            text.toSearchText += ' ';
          }
          text.toSearchText += obj[key];
        });
        //console.log("text: "+text);
        hide = this.filterSession(text, queryWords, [], 'all');
        obj.hide = hide;
        if(!hide){
            result.visibleCounter++;
        }
    });

    result.data = data;
    return result;
  }

  getData(dataSrc:string, name = '', searchKeys = '', queryText = '', excludeTracks = [], segment = 'all') {
    //console.log("getData dataSrc: "+dataSrc);
    return this.load(dataSrc).then(data => {
      //console.log("dataServe: getData");
      //console.log(data.employees);
      if(typeof name == 'undefined'){
        name = '';
      }
      if(typeof queryText == 'undefined'){
        queryText = '';
      }
      if(typeof searchKeys == 'undefined'){
        searchKeys = '';
      }
      //console.log("queryText: "+queryText);
      if(queryText != '' && name != '' && searchKeys != ''){

        this.shownSessions = 0;
        /*
        data[name].forEach(session => {
          session.hide = this.search(obj,searchKeys,queryText);
          if (!session.hide) {
            // if this session is not hidden then this group should show
              this.shownSessions++;
          }
        });
        */
        var result = this.search(data[name],searchKeys,queryText);
        this.shownSessions = result.visibleCounter;

      } else if(name != '' && searchKeys != ''){
        data[name].forEach(session => {
          session.hide = false;
        });
      }
      return data;
    });
  }

  filterSession(session, queryWords, excludeTracks, segment) {
    //console.log("toSearchText: "+session.toSearchText);
    //console.log(queryWords);
    var hide = true;
    let matchesQueryText = false;
    if (queryWords.length) {
      // of any query word is in the session name than it passes the query test
      //console.log("queryWords");
      //console.log(queryWords);
      var matchCounter = 0;
      queryWords.forEach(queryWord => {
        if (session.toSearchText.toLowerCase().indexOf(queryWord) > -1) {
          matchCounter++;
        }
      });
      if(matchCounter == queryWords.length){
        matchesQueryText = true;
      }
    } else {
      // if there are no query words then this session passes the query test
      matchesQueryText = true;
    }

    // if any of the sessions tracks are not in the
    // exclude tracks then this session passes the track test
    /*
    let matchesTracks = false;
    if(typeof session.tracks != 'undefined'){
      if(session.tracks.length > 0){
        session.tracks.forEach(trackName => {
          if (excludeTracks.indexOf(trackName) === -1) {
            matchesTracks = true;
          }
        });
      }
    }
    */
    let matchesTracks = true;

    // if the segement is 'favorites', but session is not a user favorite
    // then this session does not pass the segment test
    /*
    let matchesSegment = false;
    if (segment === 'favorites') {
      if (this.user.hasFavorite(session.toSearchText)) {
        matchesSegment = true;
      }
    } else {
      matchesSegment = true;
    }
    */
    let matchesSegment = true;

    // all tests must be true if it should not be hidden
    hide = !(matchesQueryText && matchesTracks && matchesSegment);

    return hide;
  }

}
