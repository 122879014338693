import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Page, NavController, NavParams } from 'ionic-angular';

import { dataService } from '../../services/dataService';

@Component({
    templateUrl: 'build/pages/employee-details/employee-details.html'
})

export class EmployeeDetailsPage {
    employee = [];

    constructor(
        private config: Config,
        private events: Events,
        private nav: NavController,
        private navParams: NavParams,
        private dataService: dataService,
        private translate: TranslateService
      ) {
        this.translate = translate;
        this.translate.instant('json.string');
        this.nav = nav;

        // If we navigated to this page, we will have an item available as a nav param
        this.employee = navParams.get('employee');
        //console.log("Details");
        //console.log(this.employee);
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    viewManager(managerId) {
        /*
        employeeService.findById(managerId).then(employee =>
            this.nav.push(EmployeeDetailsPage, {
                employee: employee
            })
        );
        */
    }


}
