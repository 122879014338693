import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config, Content, Page, NavController, NavParams } from 'ionic-angular';

import { dataService } from '../../services/dataService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

import { EmployeeDetailsPage } from '../employee-details/employee-details';

@Component({
    templateUrl: 'build/pages/employee-list/employee-list.html',
    directives: [ToolbarMenu,HeaderDirective],
    providers: [HeaderDirective]
})

export class EmployeeListPage {
    @ViewChild(Content) protected content: Content;

    private toolbar_options:any = [];
    private queryText = '';
    private segment = 'all';
    private excludeTracks = [];
    private dataSrc = 'data/employees.json';
    private employees = [];
    private session = [];
    private employeesCounter: number = 0;

    constructor(
      private config: Config,
      private events: Events,
      private nav: NavController,
      private navParams: NavParams,
      private headerDirective: HeaderDirective,
      private dataService: dataService,
      private translate: TranslateService
    ) {
      this.translate = translate;
      this.translate.instant('json.string');
      this.toolbar_options = {
        title: translate.instant('Employees')
      };
      this.nav = nav;
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    ngAfterViewInit() {
      this.employeesCounter = 0;
      this.load();
    }

    load() {
      //this.dataService.getData(this.dataSrc).then(data => {
      this.dataService.getData(this.dataSrc,'employees','firstName,lastName', this.queryText, this.excludeTracks, this.segment).then(data => {
        this.employees = data.employees;
        this.search();
        //console.log("employees");
        //console.log(this.employees);
        if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
      });
    }

    search(){
      //console.log("search - queryText: "+this.queryText);
      var result = this.dataService.search(this.employees,'firstName,lastName,title',this.queryText);
      this.employees = result.data;
      this.employeesCounter = result.visibleCounter;
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
    }

    itemTapped(employee) {
        //console.log("itemTapped");
        //console.log(employee);

        this.nav.push(EmployeeDetailsPage, {
            employee: employee
        });

    }
}
