//import { AngularFire, AuthProviders, AuthMethods, FirebaseAuthState } from 'angularfire2';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NavController, Page, ViewController, NavParams, Events, Config, Content } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Validators } from '@angular/common';
import { REACTIVE_FORM_DIRECTIVES, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { firebaseService } from '../../services/firebaseService';
import { AuthData } from '../../providers/auth-data/auth-data';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
    templateUrl: 'build/pages/login/login.html',
    directives: [ToolbarMenu,HeaderDirective,REACTIVE_FORM_DIRECTIVES],
    providers: [HeaderDirective,AuthData]
})
export class LoginPage {
    @ViewChild(Content) protected content: Content;

    private toolbar_options:any = [];
    private error: any;
    private loginForm: FormGroup;
    private electron: boolean = false;
    private firebaseLoginStatus: string;

    constructor(
      private events: Events,
      private config: Config,
      private headerDirective: HeaderDirective,
      //private af: AngularFire,
      private authData: AuthData,
      private FBService: firebaseService,
      private viewCtrl: ViewController,
      private builder: FormBuilder,
      public _params: NavParams,
      private translate: TranslateService
    ) {
        this.translate = translate;
        this.translate.instant('json.string');
        this.toolbar_options = {
          title: translate.instant('Login')
        };
        //this.authData = authData;

        this.firebaseLoginStatus = this.config.get("firebaseLoginStatus");

        // HAD TO HACK IN THE AngularFire object... :-(
        //this.af = _params.get("af");

        this.loginForm = builder.group({
            'email': [
                '', // default value
                [Validators.required, Validators.minLength(5)]
            ],
            'password': [
                '',
                [Validators.required, Validators.minLength(5)]
            ]
        })

        //this.electron = this.config.get('electron');

    }
    /**
     * this will dismiss the modal page
     */
    dismiss() {
        //this.viewCtrl.dismiss();
    }
    /**
     * this create in the user using the form credentials.
     *
     * we are preventing the default behavor of submitting
     * the form
     *
     * @param _credentials {Object} the email and password from the form
     * @param _event {Object} the event information from the form submit
     */


    registerUser(_credentials, _event) {
        _event.preventDefault();

        //console.log("registerUser");

        //firebase
        this.authData.signupUser(this.loginForm.value.email, this.loginForm.value.password);

        //angularfire
        /*
        this.af.auth.createUser(_credentials)
            .then((user) => {
                //console.log(`Create User Success:`, user);
                _credentials.created = true;

                return this.login(_credentials, _event);
            })
            .catch(e => console.error(`Create User Failure:`, e));
        */
    }

    /*
    registerUserWithGitHub(_credentials, _event) {
        _event.preventDefault();

        this.af.auth.login({
            provider: AuthProviders.Github,
            method: AuthMethods.Popup
        }).then((value) => {
            this.dismiss()
        }).catch((error) => {
            this.error = error
            //console.log(error)
        });
    }


    registerUserWithTwitter(_credentials, _event) {
        _event.preventDefault();

        this.af.auth.login({
            provider: AuthProviders.Twitter,
            method: AuthMethods.Redirect
        }).then((authData) => {
            //console.log(authData)

            // already has user... need better info??
            if (!authData) {
                //this.dismiss()
            }

            const itemObservable = this.af.database.object('/users/' + authData.uid);
            itemObservable.set({
                "provider": authData.auth.providerData[0].providerId,
                "avatar": authData.auth.photoURL || "MISSING",
                "displayName": authData.auth.providerData[0].displayName || authData.auth.email,
            });
            this.events.publish('firebase:login',authData);

        }).then((value) => {
            //this.dismiss()
        }).catch((error) => {
            //console.log("registerUserWithTwitter error");
            this.error = error
            //console.log(error)
        });
    }
    */



    /**
     * this logs in the user using the form credentials.
     *
     * if the user is a new user, then we need to create the user AFTER
     * we have successfully logged in
     *
     * @param _credentials {Object} the email and password from the form
     * @param _event {Object} the event information from the form submit
     */
    login(credentials, _event) {
        _event.preventDefault();

        //console.log("login "+this.config.get('electron'));
        //if(this.config.get('electron')){
          //console.log("this.loginForm.value.email: "+this.loginForm.value.email);
          this.authData.loginUser(this.loginForm.value.email, this.loginForm.value.password);
        /*} else {
          // if this was called from the register user,  the check if we
          // need to create the user object or not
          let addUser = credentials.created
          credentials.created = null;

          // login usig the email/password auth provider
          //console.log(this.af);
          this.af.auth.login(credentials, {
              //provider: AuthProviders.Password,
              //method: AuthMethods.Password
              provider: AuthProviders.Google,
              method: AuthMethods.Redirect //Popup
          }).then((authData) => {
              //console.log(authData)

              if (addUser) {
                  const itemObservable = this.af.database.object('/users/' + authData.uid);
                  itemObservable.set({
                      "provider": authData.auth.providerData[0].providerId,
                      "avatar": authData.auth.photoURL || "MISSING",
                      "displayName": authData.auth.providerData[0].displayName || authData.auth.email,
                  })
              } else {
                  //this.dismiss();

              }
              this.events.publish('firebase:login',authData);
          }).then((value) => {
              //this.dismiss()
          }).catch((error) => {
              //console.log("login error");
              this.error = error
              //console.log(error)
          });
        }*/
    }
}
