import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { NavParams } from 'ionic-angular';

import { AboutPage } from '../about/about';
import { MapPage } from '../map/map';
import { SchedulePage } from '../schedule/schedule';
import { SpeakerListPage } from '../speaker-list/speaker-list';


@Component({
  templateUrl: 'build/pages/tabs/tabs.html'
})
export class TabsPage {
  // set the root pages for each tab
  tab1Root: any = SchedulePage;
  tab2Root: any = SpeakerListPage;
  tab3Root: any = MapPage;
  tab4Root: any = AboutPage;
  mySelectedIndex: number;

  constructor(
    navParams: NavParams,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.mySelectedIndex = navParams.data.tabIndex || 0;
  }
}
