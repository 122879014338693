import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, NavController, NavParams, Page } from 'ionic-angular';

import { SessionDetailPage } from '../session-detail/session-detail';


@Component({
  templateUrl: 'build/pages/speaker-detail/speaker-detail.html'
})
export class SpeakerDetailPage {
  speaker: any;

  constructor(
    private config: Config,
    private events: Events,
    private nav: NavController,
    private navParams: NavParams,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.speaker = this.navParams.data;
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  goToSessionDetail(session) {
    this.nav.push(SessionDetailPage, session);
  }
}
