import { Injectable } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config } from 'ionic-angular';
import { Http, Headers } from '@angular/http';
import { SQLite } from 'ionic-native';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

// POUCH DB
declare var require: any;
let PouchDB = require('pouchdb');
//PouchDB.plugin(require('crypto-pouch'));
//PouchDB.plugin(require('pouchdb-find'));

//window["PouchDB"] = PouchDB;

@Injectable()
export class pouchDbService {

  //POUCH DB
  public _db: any;
  private _data: any;

  constructor(
    private http: Http,
    private events: Events,
    private config: Config,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
  }

  initDB(db) {
    var pdb;
    if(this.config.get('pouchDB')){
      //console.log('initDB '+db);
      if(this.config.get('platform') == 'ios'){
        var pdb = new PouchDB(db, { adapter: 'websql', iosDatabaseLocation: 'default' });
      } if(this.config.get('platform') == 'android'){
        var pdb = new PouchDB(db); //, { adapter: 'idb'}
      } else {
        var pdb = new PouchDB(db, { adapter: 'websql'});
      }
      //console.log(pdb.adapter);
    }
    return pdb;
  }

  deleteDB(db){
    if(this.config.get('pouchDB')){
      //console.log("deleteDB");
      db.destroy().then(function() {
        //console.log('DB deleted');
      });
    }
  }

  saveTable(db,obj){
    if(this.config.get('pouchDB')){
      //console.log("saveTable");
      db.allDocs().then(function (result) {
        // Promise isn't supported by all browsers; you may want to use bluebird
        //console.log("delete rows");
        return Promise.all(result.rows.map(function (row) {
          //console.log("remove rows");
          return db.remove(row.id, row.value.rev);
        }));
      }).then(function () {
        // done!
        //console.log("save obj");
        return db.post(obj);
      }).catch(function (err) {
        // error!
      });
    }
  }

  add(db,obj){
    if(this.config.get('pouchDB')){
      //console.log("add in db");
      //console.log(obj);
      return db.post(obj);
    }
  }

  update(db,obj) {
    if(this.config.get('pouchDB')){
      //console.log("update in db");
      //console.log(obj);
      return db.put(obj);
    }
  }

  delete(db,obj) {
    if(this.config.get('pouchDB')){
      //console.log("delete in db");
      //console.log(obj);
      return db.remove(obj);
    }
  }

  getAll(db) {
    if(this.config.get('pouchDB')){
      //console.log("getAll");
      //console.log(db);
      return db.allDocs({ include_docs: true })
      .then(docs => {
        // Listen for changes on the database.
        //db.changes({ live: true, since: 'now', include_docs: true }).on('change', this.onDatabaseChange);
        if(typeof docs.rows[0] != 'undefined'){
          return docs.rows[0].doc;
        } else {
          return {};
        }
      });
    } else {
      return {};
    }
  }

  getAllTodos(db) {
    if(this.config.get('pouchDB')){
      if (!this._data || this._data.length == 0) {
          //console.log("getAll");
          return db.allDocs({ include_docs: true })
              .then(docs => {

                  // Each row has a .doc object and we just want to send an
                  // array of todo objects back to the calling controller,
                  // so let's map the array to contain just the .doc objects.

                  this._data = docs.rows.map(row => {
                      // Dates are not automatically converted from a string.
                      row.doc.Date = new Date(row.doc.Date);
                      if(!moment(row.doc.Date).isValid()){
                        row.doc.Date = "";
                      } else {
                        row.doc.Date = moment(row.doc.Date).format('LL');
                      }
                      return row.doc;
                  });

                  // Listen for changes on the database.
                  db.changes({ live: true, since: 'now', include_docs: true })
                      .on('change', this.onDatabaseChange);

                  return this._data;
              });
      } else {
          // Return cached data as a promise
          //console.log("Return cached data as a promise");
          //console.log(this._data);
          return Promise.resolve(this._data);
      }
    }
  }

  private onDatabaseChange = (change) => {
    if(this.config.get('pouchDB')){
      //console.log("onDbChange");
      var index = this.findIndex(this._data, change.id);
      var todo = this._data[index];
      if (change.deleted) {
          //console.log("onDbChange deleted");
          if (todo) {
              this._data.splice(index, 1); // delete
          }
      } else {
          change.doc.Date = new Date(change.doc.Date);
          if (todo && todo._id === change.id) {
              //console.log("onDbChange update");
              this._data[index] = change.doc; // update
          } else {
              //console.log("onDbChange insert");
              this._data.splice(index, 0, change.doc) // insert
          }
      }
    }
  }

  // Binary search, the array is by default sorted by _id.
  private findIndex(array, id) {
      var low = 0, high = array.length, mid;
      while (low < high) {
          mid = (low + high) >>> 1;
          array[mid]._id < id ? low = mid + 1 : high = mid
      }
      return low;
  }
}
