import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Content, App, Platform, NavController } from 'ionic-angular';

import { dataService } from '../../services/dataService';
import { pouchDbService } from '../../services/pouchDbService';
import { DragulaService, Dragula } from 'ng2-dragula/ng2-dragula';

import { TodoAddPage } from "../todos-add/add";
import { TodoDetailPage } from "../todos-detail/detail";

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

import {Observable} from 'rxjs/Rx';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

import {
  TimeAgoPipe,
  CalendarPipe,
  DateFormatPipe,
  DurationPipe,
  FromUnixPipe
} from 'angular2-moment';

@Component({
  templateUrl: 'build/pages/todos/todos.html',
  pipes: [
    TimeAgoPipe,
    CalendarPipe,
    DateFormatPipe,
    DurationPipe,
    FromUnixPipe
  ],
  directives: [ToolbarMenu,HeaderDirective,Dragula],
  viewProviders: [DragulaService],
  providers: [HeaderDirective]
})

export class TodosPage {
    @ViewChild(Content) protected content: Content;

    private toolbar_options:any = [];
    public todoList: Array<string>;
    private segment: string;
    private myDate: Date;
    private now: any;
    private counter: any;
    private dauer: any;
    private timer_interval: any;
    private subscription: any;
    private timer_status: boolean;
    private _db: string;
    private todo: any;
    //private appPages: any = [];

    constructor(
      private config: Config,
      private events: Events,
      private nav: NavController,
      private headerDirective: HeaderDirective,
      //private toolbarMenu: ToolbarMenu,
      private dataService: dataService,
      private pouchDbService: pouchDbService,
      private dragulaService: DragulaService,
      private app: App,
      private translate: TranslateService
    ) {
      this.translate = translate;
      this.translate.instant('json.string');
      this.toolbar_options = {
        title: translate.instant('Todos'),
        buttonsRight: true
      };
      this._db = 'todos';
      this.load("db");
      this.segment = 'all'; //'timer'
      this.todo = [];
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    ionViewWillEnter(){

    }

    onPageDidEnter() {
      //TIMER
      this.startTimer();
    }

    changeSegment(){
      this.startTimer();
      this.app.setTitle('Todos '+this.segment);
    }

    startTimer(){
      if(this.segment == 'timer'){
        this.timer_status = true;
        this.timer_interval = Observable.interval(1000);
        this.subscription = this.timer_interval.subscribe((x) => {
          this.clock();
        });
      } else {
        this.stopTimer();
      }
    }

    stopTimer(){
      this.timer_status = false;
      if(typeof this.subscription != 'undefined'){
        this.subscription.unsubscribe();
      }
    }

    load(from){
      //console.log("load "+from);
      if(from == "db"){
          var dbTodo = this.pouchDbService.initDB(this._db);
          this.pouchDbService.getAllTodos(dbTodo)
          .then(data => {
              //this.zone.run(() => {
                  //console.log("pouchDbService.getAllTodos");
                  //console.log(data);
                  this.todoList = data;
              //});
              if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
          })
          .catch(console.error.bind(console));
      } else if(from == "local"){
          this.todoList = JSON.parse(localStorage.getItem(this._db));
          if(!this.todoList) {
              this.todoList = [];
          }
          if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
      }
    }

    showDetail(todo) {
      /*
      let modal = Modal.create(TodoDetailPage, { todo: todo });
      this.nav.present(modal);
      modal.onDidDismiss(() => {});
      */
      this.events.publish('modal',{data: { todo: todo }, page: TodoDetailPage});
    }

    delete(index: number) {
        this.todoList.splice(index, 1);
        this.save();
    }

    add() {
        this.nav.push(TodoAddPage);
    }

    done(index: number) {
        //console.log(this.todoList);
        this.todoList[index]['segment'] = 'done';
        this.save();
    }

    save(){
      localStorage.setItem(this._db, JSON.stringify(this.todoList));
    }

    clock(){
        //console.log("clock");
        this.myDate = new Date();
        this.now = moment();
        this.counter = moment([2007, 0, 29]).toNow(true);
        this.dauer = this.getDauer();
    }

    getDauer(){
      var von = '2016-07-25 07:00';
      var now_unix = moment().unix();
      var von_unix = moment(von).unix();
      var diff_unix = now_unix-von_unix;
      //console.log("von_unix: "+von_unix);
      var duration = moment.duration(diff_unix*1000); //value in milliseconds
      //console.log("duration: "+duration);
      var hours = duration.hours();
      var minutes = duration.minutes();
      var seconds = duration.seconds();
      var milliseconds = duration.milliseconds();

      var date = moment().hours(hours).minutes(minutes).seconds(seconds).millisecond(milliseconds);
      return date.format("HH:mm:ss");
    }
}
