//import { AngularFire, AuthProviders, AuthMethods, FirebaseAuthState } from 'angularfire2';
import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config, MenuController, NavController, Loading } from 'ionic-angular';

//import { AuthData } from '../../providers/auth-data/auth-data';
import { LoginPage } from '../login/login';

@Component({
  templateUrl: 'build/pages/logout/logout.html'
  //,providers: [AuthData]
})
export class LogoutPage {

  constructor(
    private config: Config,
    private events: Events,
    public nav: NavController,
    //private af: AngularFire,
    //public authData: AuthData,
    private menu: MenuController,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.nav = nav;
    //this.authData = authData;
  }

  ionViewDidEnter(){
    this.events.publish('setStandardConfig');
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
    this.menu.enable(false);
  }

  onPageWillEnter() {
    //this.view.showBackButton(false);
  }

  ngAfterViewInit() {
    this.logoutUser();
  }

  logoutUser(){
    //this.authData.logoutUser();
    //this.events.publish('loading:hide');
    this.events.publish('firebase:disconnet');
  }

  goToLogin(){
    //this.nav.push(LoginPage);
    //this.rootPage = LoginPage;
    this.events.publish('setRootPage:LoginPage');
  }
}
