import { Injectable } from '@angular/core';
import * as xml2js from "xml2js";
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Config, Events } from 'ionic-angular';
import { Http, Headers } from '@angular/http';

import { firebaseService } from './firebaseService';
import { dataService } from './dataService';

import { Observable }     from 'rxjs/Observable';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

@Injectable()
export class gdatService {
  private translationRef: any;
  private languages: any;
  private translation: any;
  private profile: any;
  private userSession: any;
  private showError: boolean = true;

  constructor(
    private http: Http,
    private events: Events,
    private config: Config,
    private FBService: firebaseService,
    private dataService: dataService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    //console.log("window.location.hostname: "+window.location.hostname);
  }

  getCall(func:string,params:any){
    this.events.publish('setLoadingMessage','API getCall '+func);
    var request;
    if(typeof params == 'undefined' || params == ''){
      params = {};
    }
    request = '<APIFunction>'+func+'</APIFunction>';
    if(func == "apiGetReservationData"){
      request += "<DatenFelder>+STATUS;+ANZ_E;+ANZ_EE;+ANZ_K1;+ANZ_K2;+ANZ_K3;+ANZ_K4;+WEB_GUID;+TerminHash</DatenFelder>";
      if(typeof params.termin_nr == 'undefined'){
        request += "<TerminNr>"+params.LFNUMMER+"</TerminNr>";
      } else {
        request += "<TerminNr>"+params.termin_nr+"</TerminNr>";
      }
    } else if(func == "apiGetReservationDataAdv"){
      if(typeof params.termin_nr == 'undefined'){
        request += "<TerminNr>"+params.LFNUMMER+"</TerminNr>";
      } else {
        request += "<TerminNr>"+params.termin_nr+"</TerminNr>";
      }
    } else if(func == "apiGetGuestData"){
      if(params.field_check == "true"){
        func = "apiPreCheckInGuestDataFieldCheck";
        request = '<APIFunction>'+func+'</APIFunction>';
      }
      if(params.field_check != "true"){
        if(params.action == "search"){
          request += "<DatenFelder>+VORNAME;+FAMNAME;+ZUSNAME;+FAMNAM_2;+STRASSE;+ORT;+PLZ</DatenFelder>"; //;+ZUSATZINFOS
        } else {
          request += "<DatenFelder>+SPRACHE;+GESCHLECHT;+ANREDE_H;+ANREDE_FAM;+TITEL;+ZUSNAME;+NATION;+TELEFON;+P_HANDY;+VORNAME;+FAMNAME;+BERUF;+PASS_NR;+GEB_H;+GEB_FRAU;+NAME_FRAU;+FAMNAM_2;+NAME_K1;+NAME_K2;+NAME_K3;+NAME_K4;+NAME_K5;+GEB_K1;+GEB_K2;+GEB_K3;+GEB_K4;+GEB_K5;+NEWSLETTER,+FRAU_SEX,+K1_SEX,+K2_SEX,+K3_SEX,+K4_SEX,+K5_SEX</DatenFelder>"; //;+ZUSATZINFOS
        }
      }
      if( (params.suche != "" || (params.pers_nr == "" && params.termin_nr == "")) && params.field_check == "false"){
        request += "<SuchText>"+params.suche+"</SuchText>";
      } else {
        if(params.PERS_NR != ""){
          request += "<PersNr>"+params.PERS_NR+"</PersNr>";
        } else if(params.pers_nr != ""){
          request += "<PersNr>"+params.pers_nr+"</PersNr>";
        } else {
          request += "<PersNr>0</PersNr>";
        }
        if(params.termin_nr != ""){
          request += "<TerminNr>"+params.termin_nr+"</TerminNr>";
        }
        if(params.field_check != "true"){
          request += "<SuchText></SuchText>";
        }
      }
    } else if(func == "apiGetPreferences"){
      request += '<DatenFelder>PersDesc</DatenFelder>';
    } else if(func == "apiGetRoomData"){
      if(typeof params.zimmer == 'undefined'){
        params.zimmer = '';
      }
      request += '<ZimmerNr>'+params.zimmer+'</ZimmerNr>';
    } else if(func == "apiGetReservationsFromTo"){
      request += '<DatenFelder>+STATUS</DatenFelder>';
      request += '<ZimmerNr>'+params.zimmer+'</ZimmerNr>';
      request += '<ZimmerKat></ZimmerKat>';
      request += '<Von>'+params.von+'</Von>';
      request += '<Bis>'+params.bis+'</Bis>';
    } else if(func == "apiCreateSessionToken"){
      this.profile = this.config.get('profile');
      //console.log("profile");
      //console.log(this.profile);
      request += '<ConsumerKey>'+this.profile.api_consumerkey+'</ConsumerKey>';
      request += '<Username>'+this.profile.api_username+'</Username>';
      request += '<Password>'+this.profile.api_password+'</Password>';
      request += '<PasswordHash></PasswordHash>';
      request += '<ForceSync></ForceSync>';
    }
    //console.log("request: "+request);
    return request;
  }

  addStack(arr,func,params){
    //console.log("addStack: "+func);
    arr.push(this.getCall(func,params));
    return arr;
  }

  stack(requestArr){
    //console.log("stack: ");
    var xml_post = "";
    var restult = "";
    this.userSession = this.config.get('userSession');

  	if(requestArr.length > 0){
  		xml_post += "<QueueData>";
  		for(var k=0;k<requestArr.length;k++){
  			xml_post += "<FunctionCall>";
  			xml_post += requestArr[k];
  			xml_post += "</FunctionCall>";
  		}
  		xml_post += "</QueueData>";
  		//console.log(xml_post);
      return this.api('apiSendQueue',xml_post).then(function(status) {
        //console.log("sync - stack");
        //console.log(status);
        return status;
      });
  	} else {
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant("No valid Query")
      };
      this.events.publish('alert:basic',alert);
    }
  }

  api(func:string,params:any) {
    this.profile = this.config.get('profile');
    if(!this.profile.gdat){
      return new Promise(resolve => {
        var data = {
          SUCCESS : false,
          STATUS : this.translate.instant("GASTROdat not activated"),
          API     : ''
        };
        resolve(data);
      });
    } else {
      this.events.publish('setLoadingMessage','API '+func);
      //console.log("api - show error - func: "+func);
      this.config.set('thisApiCall',{func:func,params:params});

      if(typeof this.profile == 'undefined'){
        this.profile = this.config.get('profile');
      }
      //console.log(this.profile);

      //console.log("gdatService - api");
      //var api_url = "http://localhost:8100/api/SendAPICall";
      var api_url = "api/SendAPICall";
      if(window.location.hostname != 'localhost'){
        //api_url = "http://api.gastrodat.com/api/SendAPICall";
        api_url = "https://g-app.de/api_redirect/index.php";
      }

      var request = '<?xml version="1.0" encoding="utf-8"?>'
      request += '<API>'
      request += '<APIFunction>'+func+'</APIFunction>';
      if(func == "apiCreateSessionToken"){
        var app = this.config.get('App');
        request += '<ApplicationKey>'+app.ApplicationKey+'</ApplicationKey>';
        //console.log(request);
      } else {
        this.userSession = this.config.get('userSession');
        request += '<SessionToken>'+this.userSession.apiSessionToken+'</SessionToken>';
      }
      if(func == "apiSendQueue"){
        request += params;
      } else {
        request += this.getCall(func,params);
      }
      request += '</API>';

      //console.log("api - api_url: "+api_url);
      //console.log("api - func: "+func);
      //if(func == "apiSendQueue"){
        //console.log("api - request: "+request);
      //}

      return new Promise(resolve => {
        var data = {
          STATUS  : '',
          SUCCESS : false,
          API     : ''
        };
        let headers = new Headers();
        this.createApiHeader(headers);
        this.http.post(api_url, request, {
          headers: headers
        })
        //.retry(2)
        .timeout(this.profile.settings.apiTimeout*1000, "timeoutError")
        .subscribe(
          res => {
            /*
            //console.log(res.ok);
            //console.log(res.status);
            //console.log(res.statusText);
            */
            //console.log(res);

            var tmpData;
            if(res.status.toString() == "200"){
              //console.log("200");
              //console.log(res['_body']);
              xml2js.parseString(res['_body'], {explicitArray: false}, function (err, result) {
                tmpData = result;
                //console.log(result);
                //console.log("func: "+func);

                if(typeof tmpData['API'] != 'undefined'){
                  if(typeof tmpData['API'].ErrorMessage == 'undefined'
                  && (
                    !this.config.get('forceApiSessionError') && func != 'apiCreateSessionToken'
                    || func == 'apiCreateSessionToken'
                  )
                  ){
                    this.showError = true;
                    data.STATUS = 'API Aufruf erfolgreich';
                    data.SUCCESS = true;
                    this.setOnline(func);
                    data.API = tmpData['API'];
                    //console.log("SUCCESS:tmpData['API']  - func: "+func);
                    //console.log(data.API);
                    if(func == "apiSendQueue"){
                      data.API = this.parseStack(data.API);
                    }
                  } else {
                    //console.log("ERROR:tmpData['API']");
                    //console.log(tmpData['API']);

                    if(this.config.get('forceApiSessionError')){
                      tmpData['API'].ErrorMessage = "Invalid session token";
                    }

                    var this_error_key = this.getErrorKey(tmpData['API']);

                    this.saveErrorMessage(this_error_key);
                    data.STATUS = this.translate.instant(this.dataService.unSanitizeKey(this_error_key));
                  }
                } else {
                  data.STATUS = this.translate.instant("API Call Error");
                }
                if(!this.showError){
                  //console.log("api - clear error");
                  data.STATUS = "-";
                }
                resolve(data);
                //this.resolveAPI(data);
              }.bind(this));

            } else {
              //console.log(res);
              data.STATUS = this.translate.instant("Server Connection Problem (API)");
              if(!this.showError){
                //console.log("api 2 - clear error");
                data.STATUS = "-";
              }
              resolve(data);
              this.setOffline();
            }

          },
          error => {
            //console.log(error); //gives an object at this point
            if(error == "timeoutError"){
              this.setOffline();
              data.SUCCESS = false;
              data.STATUS = this.translate.instant("Server Connection Timeout (API)");
              if(!this.showError){
                //console.log("api 3 - clear error");
                data.STATUS = "-";
              }
              resolve(data);
            }
          }
        );
      });
    }
  }

  getErrorKey(apiResult){
    //console.log("getErrorKey");
    //console.log(apiResult);
    var this_error_key;
    if(typeof apiResult != 'undefined'){
      if(typeof apiResult.ErrorMessage != 'undefined'){
        if(this.dataService.checkType(apiResult.ErrorMessage) == "Object"){
          if(typeof apiResult.ErrorMessage[0] != 'undefined'){
              this_error_key = apiResult.ErrorMessage[0];
          } else {
            this_error_key = JSON.stringify(apiResult.ErrorMessage);
          }
        } else {
          this_error_key = apiResult.ErrorMessage;
        }
      } else {
        this_error_key = "Unkown Error (API)";
      }
    } else {
      this_error_key = "Unkown API Error";
    }
    //console.log(this_error_key);
    this.config.set('lastApiError',this_error_key);

    this_error_key = this.dataService.sanitizeKey(this_error_key);
    if(typeof this_error_key == 'undefined' || this_error_key == ''){
      this_error_key =  this.dataService.sanitizeKey("Unkown API Error");
    }
    this.apiErrorCheck(this_error_key);
    return this_error_key;
  }

  setOffline(){
    var showAlert = false;
    if(this.config.get('apiOnline')){
      showAlert = true;
    }
    this.config.set('apiOnline',false);
    this.events.publish('attention_bar:true',this.translate.instant("GASTROdat connection Problem (API)"));
    if(showAlert){
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant("GASTROdat connection Problem (API)")
        };
      this.events.publish('alert:basic',alert);
    }
  }

  setOnline(func){
    //console.log("setOnline - func: "+func);
    if(func != "apiGetGdatCustomerData" && func != "apiCreateSessionToken"){
      var showAlert = false;
      if(!this.config.get('apiOnline')){
        showAlert = true;
      }
      this.config.set('apiOnline',true);
      this.events.publish('attention_bar:false');
      if(showAlert){
        var alert = {
          title: this.translate.instant("Notice"),
          msg: this.translate.instant("GASTROdat reconnected succesfully (API)")
          };
        this.events.publish('alert:basic',alert);
      }
    }
  }

  apiErrorCheck(key){
    var obj = {};
    obj['bei System(_p)Net(_p)Sockets(_p)TcpClient(_p)GetStream()bei GastrodatAPI(_p)gdAPITcpClient(_p)SendRequest(String host(_b) Int32 port(_b) XmlDocument xml_request(_b) Int32 timeout)(_p) Der Vorgang ist für nicht verbundene Sockets unzulässig(_p)'] = 1;
    obj['(unknown:0) API Server offline(_p)'] = 1;
    if(typeof obj[key] != 'undefined'){
      //console.log("setOffline");
      this.setOffline();
    } else if(key.indexOf('Invalid username(_oder)password(_a)') != -1){
      this.config.set('ApiLoginError',true);
    } else {
      //console.log("not offline: "+key+":"+obj[key]);
    }

    if( key.indexOf('Invalid session token') != -1 || this.config.get('forceApiSessionError')){
      //console.log("SESSION ERROR");
      this.config.set('forceApiSessionError',false);
      if(!this.config.get('ApiSessionError')){
        this.showError = false;
        this.config.set('ApiSessionError',true);
        this.config.set('lastApiCall', this.config.get('thisApiCall') );
        this.events.publish('createApiSession','');
      } else {
        this.showError = true;
        var alert = {
          title: this.translate.instant("Error"),
          msg: this.config.set('lastApiError')
        };
        this.events.publish('alert:basic',alert);
      }
    }

  }

  saveErrorMessage(errorMsg){
    //console.log("saveErrorMessage");
    //console.log(errorMsg);
    if(typeof this.languages == 'undefined'){
      this.languages = this.config.get('languages');
    }
    if(typeof this.translation == 'undefined'){
      this.translation = this.config.get('translation');
    }

    var i = 1; //fuer englisch
    if(typeof this.languages[i] != 'undefined'){
        if(typeof this.translation[errorMsg] == 'undefined'
        && this.languages[i].key != 'undefined' && this.languages[i].key != ''
        && typeof errorMsg != 'undefined' && errorMsg != ''
        ){
          //console.log("saving new error: '"+errorMsg+"'");
          for(var i=0;i<this.languages.length;i++){
            //console.log('gdatService - save error: translation/'+this.languages[i].key+'/'+errorMsg);
            this.FBService.save('translation/'+this.languages[i].key+'/'+errorMsg,'');
          }
        } else {
          //console.log("ERROR WHILE SAVING ERROR IN TRANSLATION: "+errorMsg);
        }
    } else {
      //console.log("LANGUAGES ERROR WHILE SAVING ERROR IN TRANSLATION");
      //console.log(this.languages);
    }
  }

  parseStack(data){
    //console.log(data.QueueData.FunctionCall);
    var result = {};
    var stackData = data.QueueData.FunctionCall;
    for(var t=0;t<stackData.length;t++){
      for (var key in stackData[t]) {
        if (stackData[t].hasOwnProperty(key)) {
          result[key] = stackData[t][key];
          //console.log(result[key]);
        }
      }
    }
    return result;
  }

  resolveAPI(data){
    if(!data.SUCCESS){
      if(!this.showError){
        //console.log("resolveAPI - clear error");
        data.STATUS = "-";
      }
      var alert = {
        title: this.translate.instant("Notice"),
        msg: data.STATUS
      };
      this.events.publish('alert:basic',alert);
    }
  }

  createApiHeader(headers:Headers) {
    //headers.append('Authorization', 'Basic ' +btoa('username:password'));
    headers.append('Accept', 'application/xml');
  }

}
