import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[elastic-header]'
    //,template: '<div id="elastic-header" class="background-image"><div id="elastic-header-blur"></div></div>'
})
export class ElasticHeader {

  private element;
  private scrollerHandle;
  private header;
  private header_blur;
  private headerHeight;
  private translateAmt;
  private scaleAmt:number = 1;
  private scrollTop;
  private lastScrollTop;
  private ticking:boolean;

  constructor(element: ElementRef){
    var me = this;
    this.element = element;
  }

  ngOnInit() {
    this.scrollerHandle = this.element.nativeElement.children[0];
    //this.scrollerHandle = this.element.nativeElement.querySelector('scroll-content');
    this.header = document.getElementById("elastic-header");
    this.header_blur = document.getElementById("elastic-header-blur");
    this.headerHeight = this.scrollerHandle.clientHeight;
    this.translateAmt = null;
    this.scrollTop = null;
    this.lastScrollTop = null;
    this.ticking = false;

    this.header.style.webkitTransformOrigin = 'center bottom';

    window.addEventListener('resize',() => {
        this.headerHeight = this.scrollerHandle.clientHeight;
    }, false);

    this.scrollerHandle.addEventListener('scroll', () => {
        if(!this.ticking){
            window.requestAnimationFrame(() => {
                this.updateElasticHeader();
            });
        }
        this.ticking = true;
    });
  }

  updateElasticHeader(){
    this.scrollTop = this.scrollerHandle.scrollTop;
    if (this.scrollTop >= 0) {
        this.translateAmt = this.scrollTop / 2;
        //this.scaleAmt = 1;
    } else {
        this.translateAmt = 0;
        //this.scaleAmt = -this.scrollTop / this.headerHeight + 1;
    }
    this.header.style.webkitTransform = 'translate3d(0,'+this.translateAmt+'px,0) scale('+this.scaleAmt+','+this.scaleAmt+')';
    this.header_blur.style.WebkitFilter  = 'blur('+this.translateAmt/10+'px)';
    this.ticking = false;
  }

}
