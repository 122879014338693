import { Component, ViewChild } from '@angular/core';
import { Events, Config, Content, NavController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { dataService } from '../../services/dataService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

declare var require: any
var Masonry = require('masonry-layout');
var imagesLoaded = require('imagesloaded');

@Component({
  templateUrl: 'build/pages/tutorial-overview/tutorial-overview.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class TutorialOverviewPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  private username: string;
  private sessionToken: string;
  private headerImage: string = 'https://ununsplash.imgix.net/photo-1421091242698-34f6ad7fc088?fit=crop&fm=jpg&h=650&q=75&w=950';
  private dummyImage: string = 'https://ununsplash.imgix.net/photo-1421091242698-34f6ad7fc088?fit=crop&fm=jpg&h=50&q=75&w=50';
  private items: any = [];
  private res: any = [];
  private max_news: number = 15;
  private category: string = "0";
  private msnry: any;
  private resultPrepared: boolean = false;
  private allCounter: number = 0;
  private gdatCounter: number = 0;
  private gappCounter: number = 0;
  private images: number = 0;
  private AllImagesLoaded: boolean = false;

  constructor(
    private config: Config,
    private events: Events,
    public nav: NavController,
    private headerDirective: HeaderDirective,
    private dataService: dataService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Tutorials')
    };
    this.nav = nav;
  }

  ionViewDidEnter(){
    this.events.publish('loading:show');

    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
    this.load(false);
  }

  load(refresher){

    this.resultPrepared = false;
    this.images = 0;

    var this_url = "https://redaktionssystem.com/gdat/?feed=json&cat=1,65&imgsize=medium_large";
    this.dataService.loadData(this_url).subscribe(
        data => {
            this.res = data;
            //console.log(data);
        },
        err => {
            console.log(err);
            this.events.publish('loading:hide');
            var alert = {
              title: this.translate.instant("Error"),
              msg: this.translate.instant(err)
            };
            this.events.publish('alert:basic',alert);
        },
        () => {
            console.log('LoadData Complete');
            this.events.publish('loading:hide');
            if(refresher){
              refresher.complete();
            }
            this.filter();
        }
    );
  }

  changeCategory(){
    if(this.items.length > 0){
      this.filter();
    }
  }

  filter(){
    var max = this.max_news;
    if(this.res.length < this.max_news){
      max = this.res.length;
    }

    if(!this.resultPrepared){
      var tmp_arr = [];
      for(var i=0;i<max;i++){
        if(this.res[i].img.indexOf('default.png') == -1 && this.res[i].img_width > 250){

          this.res[i].content = this.dataService.strip_shortcodes(this.res[i].content);
          this.res[i].img = this.res[i].img.replace("http://www.gastrodat.com","https://redaktionssystem.com/gdat");
          this.res[i].content = this.dataService.correctLinks(this.res[i].content);
          this.res[i].content = this.res[i].content.replace("http://www.gastrodat.com","https://redaktionssystem.com/gdat");

          if(this.res[i].categories[0].id == "1"){
            this.gdatCounter++;
          } else if(this.res[i].categories[0].id == "65"){
            this.gappCounter++;
          }
          this.allCounter++;
          tmp_arr.push(this.res[i]);
        }
      }
      this.resultPrepared = true;
      this.res = tmp_arr;
    }

    //nur die letzten zb 10 News anzeigen
    this.items = [];
    if(this.res.length < this.max_news){
      max = this.res.length;
    }
    for(var i=0;i<max;i++){
      //filtern
      if(this.category == "0" || this.res[i].categories[0].id == this.category){
        this.items.push(this.res[i]);
      } else {
        //console.log(this.res[i].categories[0].id+":"+this.category);
      }
      this.images++;
    }
    //console.log("anzahl: "+this.items.length);

    this.checkImgOnStage();
  }

  checkImgOnStage(){
    var posts = document.querySelectorAll('.imagesLoaded');
    //console.log("checkImgOnStage - this.images: "+this.images+" - posts: "+posts.length);
    if(this.images == 0 || this.AllImagesLoaded){
      //console.log("0 Images");
      setTimeout(()=>{
        this.startMasonry();
      },100);
    } else if(posts.length >= this.images){
      //console.log("All Images on Stage");
      this.AllImagesLoaded = true;
      this.checkImagesLoaded(posts);
    } else {
      //console.log("Waiting for Images on Stage");
      setTimeout(()=>{
        this.checkImgOnStage();
      },100);
    }
  }

  checkImagesLoaded(posts){
    //console.log("checkImagesLoaded - this.images: "+this.images);
    var imgLoad = imagesLoaded(posts);
    imgLoad.on( 'always', ( instance ) => {
      //console.log('ALWAYS - all images have been loaded', instance);
      //setTimeout(()=>{
        this.startMasonry();
      //},100);
    });
  }

  startMasonry(){
    if(this.msnry){
      //console.log("msnry.layout");
      this.msnry.reloadItems();
      this.msnry.layout();
    } else {
      //console.log("new msnry");
      //let Masonry = window['Masonry'];
      //console.log("Masonry",Masonry);
      this.msnry = new Masonry( '.masonry-grid', {
        // options
        /*
        itemSelector: '.masonry-grid-item',
        //columnWidth: 200,
        columnWidth: '.masonry-grid-sizer',
        //gutter: 10,
        //percentPosition: true
        */
      });
      //console.log("msnry",this.msnry);
    }
    if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
  }

  doRefresh(refresher) {
    //console.log('Begin async operation', refresher);
    this.load(refresher);
    this.startMasonry();
  }

}
