import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Content, App, ItemSliding, List, Modal, NavController } from 'ionic-angular';

import { ConferenceData } from '../../providers/conference-data';
import { ScheduleFilterPage } from '../schedule-filter/schedule-filter';
import { SessionDetailPage } from '../session-detail/session-detail';
import { UserData } from '../../providers/user-data';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  templateUrl: 'build/pages/schedule/schedule.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class SchedulePage {
  // the list is a child of the schedule page
  // @ViewChild('scheduleList') gets a reference to the list
  // with the variable #scheduleList, `read: List` tells it to return
  // the List and not a reference to the element
  @ViewChild('scheduleList', {read: List}) scheduleList: List;
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  dayIndex = 0;
  queryText = '';
  segment = 'all';
  excludeTracks = [];
  shownSessions = [];
  groups = [];

  constructor(
    private config: Config,
    private events: Events,
    private app: App,
    private nav: NavController,
    private headerDirective: HeaderDirective,
    private confData: ConferenceData,
    private user: UserData,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Schedule'),
      buttonsRight: true
    };
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
    //this.app.setTitle('Schedule');
  }

  ngAfterViewInit() {
    this.updateSchedule();
  }

  updateSchedule() {
    // Close any open sliding items when the schedule updates
    this.scheduleList && this.scheduleList.closeSlidingItems();

    this.confData.getTimeline(this.dayIndex, this.queryText, this.excludeTracks, this.segment).then(data => {
      this.shownSessions = data.shownSessions;
      this.groups = data.groups;
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
    });
  }

  presentFilter() {
    //let modal = Modal.create(ScheduleFilterPage, this.excludeTracks);
    //this.nav.present(modal);
    this.events.publish('modal', {
      data: this.excludeTracks,
      page: ScheduleFilterPage,
      onDismissHandler: (data: any[]) => {
          if (data) {
            this.excludeTracks = data;
            this.updateSchedule();
          }
        }
    });
  }


  goToSessionDetail(sessionData) {
    // go to the session detail page
    // and pass in the session data
    this.nav.push(SessionDetailPage, sessionData);
  }

  addFavorite(slidingItem: ItemSliding, sessionData) {

    if (this.user.hasFavorite(sessionData.name)) {
      // woops, they already favorited it! What shall we do!?
      // prompt them to remove it
      this.removeFavorite(slidingItem, sessionData, this.translate.instant("Favorite already added"));
    } else {
      // remember this session as a user favorite
      this.user.addFavorite(sessionData.name);
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}

      // create an alert instance
      let alert = {
        title: this.translate.instant("Favorite Added"),
        buttons: [{
          text: this.translate.instant('OK'),
          handler: () => {
            // close the sliding item
            slidingItem.close();
          }
        }]
      };
      // now present the alert on top of all other content
      //this.nav.present(alert);
      this.events.publish('confirm:basic',alert);
    }

  }

  removeFavorite(slidingItem: ItemSliding, sessionData, title) {
    let alert = {
      title: title,
      message: this.translate.instant("Would you like to remove this session from your favorites?"),
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          handler: () => {
            // they clicked the cancel button, do not remove the session
            // close the sliding item and hide the option buttons
            slidingItem.close();
          }
        },
        {
          text: this.translate.instant("Remove"),
          handler: () => {
            // they want to remove this session from their favorites
            this.user.removeFavorite(sessionData.name);
            this.updateSchedule();

            // close the sliding item and hide the option buttons
            slidingItem.close();
          }
        }
      ]
    };
    // now present the alert on top of all other content
    //this.nav.present(alert);
    this.events.publish('confirm:basic',alert);
  }
}
