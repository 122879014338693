import { Injectable } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Config, Events, LocalStorage, Storage } from 'ionic-angular';

import { firebaseService } from '../services/firebaseService';

@Injectable()
export class UserData {
  private _favorites = [];
  private HAS_LOGGED_IN = 'hasLoggedIn';
  private storage = new Storage(LocalStorage);

  constructor(
    private events: Events,
    private config: Config,
    private FBService: firebaseService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
  }

  hasFavorite(sessionName) {
    return (this._favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName) {
    this._favorites.push(sessionName);
  }

  removeFavorite(sessionName) {
    let index = this._favorites.indexOf(sessionName);
    if (index > -1) {
      this._favorites.splice(index, 1);
    }
  }

  login(profile) {
    //console.log("profile:");
    //console.log(profile);
    //console.log("user-data login: "+profile.email);
    //this.config.set('profile',profile);
    //this.storage.set(this.HAS_LOGGED_IN, true);
    //this.setUsername(profile.email);
    //this.storage.set('profile', JSON.stringify(profile));
    //this.events.publish('user:login');

    var companyUsersRef= this.FBService.getDBObject('company/'+this.config.get('GDATNR')+'/users');
    var saveObj = {
      id : profile.uid,
      name : profile.displayName
    };
    //this.FBService.add(companyUsersRef,saveObj);
    //this.FBService.update(companyUsersRef,saveObj);
  }

  getProfile(){
    /*
    return this.storage.get('profile').then((value) => {
      //console.log("getProfile");
      //console.log(value);
      var data = JSON.parse(value);
      //console.log(data);
      //console.log("uid: "+data.uid);
      return data;
    });
    */
    return this.config.get('profile');
  }

  signup(username) {
    //this.storage.set(this.HAS_LOGGED_IN, true);
    //this.setUsername(username);
    //this.events.publish('user:signup');
  }

  logout() {
    //console.log("user-data: logout");
    //this.storage.remove(this.HAS_LOGGED_IN);
    //this.storage.remove('username');
    //this.events.publish('user:logout');
  }

  setUsername(username) {
    //this.storage.set('username', username);
  }

  getUsername() {
    //console.log("getUsername");
    /*return this.storage.get('username').then((value) => {
      //console.log(value);
      return value;
    });*/
    var profile = this.config.get('profile');
    return profile.displayName;
  }

  // return a promise
  hasLoggedIn() {
    /*
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value;
    });
    */
    if(this.config.get("firebaseLoginStatus") == "true"){
      return true;
    } else {
      return false;
    }
  }
}
