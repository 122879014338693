import { Component } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Modal, NavParams, ViewController, ActionSheet, NavController, Page } from 'ionic-angular';

import { firebaseService } from '../../services/firebaseService';
import { dataService } from '../../services/dataService';

@Component({
  templateUrl: 'build/pages/translation-add/add.html'
})
export class TranslationAddPage {

    public segment: string;
    private obj: any = [];
    private translationRef: any;
    private translation: any;
    private exists: boolean = false;

    constructor(
        private config: Config,
        private events: Events,
        private nav: NavController,
        private viewCtrl: ViewController,
        private navParams: NavParams,
        private FBService: firebaseService,
        private dataService: dataService,
        private translate: TranslateService
      ) {
        this.translate = translate;
        this.translate.instant('json.string');
        this.translation = [];
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    ionViewLoaded() {
        this.exists = false;
        this.segment = this.navParams.get('segment');
        this.translation = this.navParams.get('translation');
        //console.log("translation");
        //console.log(this.translation);
    }

    checkKey(){
      this.exists = false;
      if(this.obj.en != "" && this.obj.en.length > 1){
        for(var i=0;i<this.translation.length;i++){
          if(this.obj.en == this.translation[i].key){
            console.log(this.translation[i].key);
            this.exists = true;
          }
        }
      }
    }

    save() {
      //console.log(this.obj);
      var en_key = "";
      var i = 0;
      for (var key in this.obj) {
        if (this.obj.hasOwnProperty(key)) {
          var val = this.obj[key];
          if(i == 0){
            en_key = val;
          } else {
            if(typeof en_key != 'undefined' && en_key != ''){
              //console.log("en_key: "+en_key+" - key: "+key+" - val: "+val);
              //console.log("en_key before: "+en_key);
              en_key = this.dataService.sanitizeKey(en_key);
              //console.log("en_key after: "+en_key);
              this.FBService.save('translation/'+key+'/'+en_key,val);
            }
          }
          i++;
        }
      }
      this.close();
    }

    delete() {
      this.close();
    }

    close() {
      this.viewCtrl.dismiss();
    }
}
