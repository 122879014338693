import { Injectable } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Config, Events } from 'ionic-angular';
import { Http, Headers } from '@angular/http';

import * as firebase from 'firebase';
import { pouchDbService } from './pouchDbService';
import { dataService } from './dataService';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

@Injectable()
export class firebaseService {
  //FIREBASE DB
  private fdb: any;
  private pouchdb: any;
  private messagesRef: any;
  private translationRef: any;
  private messageInput: any;
  private _db: any;

  public profile: any;

  private authInfo: any;
  private displayName: any;

  private loggedOut: boolean = false;

  constructor(
    private http: Http,
    private events: Events,
    private config: Config,
    private pouchDbService: pouchDbService,
    //private af: AngularFire,
    private dataService: dataService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');

    const fbConf = {
        apiKey: "AIzaSyDpLlfSu4ZmHGPWoTzw9Xt4nfqaYMP3lNQ",
        authDomain: "core-stronghold-91622.firebaseapp.com",
        databaseURL: "https://core-stronghold-91622.firebaseio.com",
        storageBucket: "core-stronghold-91622.appspot.com",
    };

    //if(this.config.get('electron')){
      firebase.initializeApp(fbConf);
      this.firebaseAuth();
    //} else {
      //this.angularfireAuth();
    //}
  }

  angularfireAuth(){
    /*
    this.events.publish('setLoadingMessage','FB Authenticating');
    //console.log("firebaseAuth");
    // subscribe to the auth object to check for the login status
    // of the user, if logged in, save some user information and
    // execute the firebase query...
    // .. otherwise
    // show the login modal page
    this.af.auth.subscribe((data: FirebaseAuthState) => {
      //console.log("firebase auth subscribe", data)
      if (data && !data.anonymous) {
        //console.log("!ANONYMOUS");
        this.af.auth.unsubscribe();

        //this.buttonTitle = "LOGOUT"

        // if no user, then add it
        //this.addOrUpdateUser(data)


        if (data.auth.providerData[0].providerId === "twitter.com") {
            this.authInfo = data.auth.providerData[0]
            this.displayName = data.auth.providerData[0].displayName
        } else if (data.github) {
            this.authInfo = data.github
            //this.authInfo.displayName = data.github.displayName
        } else {
            this.authInfo = data.auth || {}
            this.displayName = data.auth.providerData[0].email
        }
        //this.textItems = this.af.database.list('/textItems')
        this.profile =  data.auth;
        //console.log("uid: "+data.auth.uid);
        this.loggedOut = false;
        this.events.publish('firebase:login',this.profile);
      } else if (data && data.anonymous) {
        //ANONYMOUS
        //this.buttonTitle = "LOGIN"
        //this.authInfo = null
        //this.displayLoginModal()
        //console.log("ANONYMOUS");
        this.profile =  data.auth;
        this.loggedOut = false;
        this.events.publish('firebase:anonymous',this.profile);
      } else {
        //LOGOUT
        //console.log("firebase LOGOUT");
        if(!this.loggedOut){
          this.events.publish('firebase:logout');
        }
      }
    })
    */
  }

  firebaseAuth(){
    /**
     * [auth description]
     * This is a firebase auth observer that's listening to auth changes, it's going to fire on every auth change
     * I have it set to HomePage on login and LoginPage on logout, feel free to edit this.
     */
    //console.log("firebaseAuth");
    firebase.auth().onAuthStateChanged( (user) => {
      //console.log("onAuthStateChanged");
      this.events.publish('setLoadingMessage','FB Auth Change');
      if (user) {
        //this.setFirebaseDBs();
        //console.log("user",user);
        this.profile = user;
        //console.log("firebase onAuthStateChanged user");
        if (user.isAnonymous) {
          //console.log("anonym");
          this.loggedOut = false;
          this.events.publish('firebase:anonymous',user);
        } else {
          //console.log("nicht anonym");
          //firebase.auth().auth.unsubscribe();
          this.loggedOut = false;
          this.events.publish('firebase:login',user);
        }
        //console.log(user);
        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        //console.log("uid: "+this.profile.uid+" - name: "+this.profile.displayName+" - email: "+this.profile.email+" - photoUrl: "+this.profile.photoURL);
      } else {
        //console.log("firebase-service onAuthStateChanged: logoutUser");
        if(!this.loggedOut){
          //console.log("firebase:logout");
          this.events.publish('firebase:logout');
        }
      }
    });
  }

  firebasesignInAnonymously(){
    //console.log('signInAnonymously');

    this.events.publish('setLoadingMessage','FB Anonym Login');
    firebase.auth().signInAnonymously().then( () => {
         //console.log('Logged in as Anonymous!');
         //login status wird auch bei anonymen logins geändert
         //deshalb muss bei der login status änderung auf anonym gecheckt werden
         this.events.publish('setLoadingMessage','FB Anonym Login Success');
       }).catch(function(error) {
         //var errorCode = error.code;
         //var errorMessage = error.message;
         //console.log(errorCode);
         //console.log("firebase error signInAnonymously");
         //console.log(error);
         this.events.publish('setLoadingMessage','FB Anonym Login Error');
         this.events.publish('firebase:logout');
    });

  }

  //setFirebaseDBs(){
    //console.log("setFirebaseDBs");
    //this.fdb = firebase.database().ref('/');
  //}
  getDB(db_name,type,query){
    //console.log("firebase getDB: "+db_name);
    return firebase.database().ref('/'+db_name);
    /*
    var res: any;
    if(type == "list"){
      res = this.af.database.list('/'+db_name,query);
    } else {
      res = this.af.database.object('/'+db_name,query);
    }
    return res;
    */
  }
  getDBObject(db_name){
    //console.log("firebase getDBObject: "+db_name);
    return firebase.database().ref('/'+db_name);
    //return this.af.database.object('/'+db_name);
  }
  getDBList(db_name){
    //console.log("firebase getDBList: "+db_name);
    return firebase.database().ref('/'+db_name);
    //return this.af.database.list('/'+db_name);
  }

  add(ref,obj){
    //console.log("firebase - add", obj);
    var status;
    var list = this.getDBList(ref);
    obj = this.dataService.arrToObj(obj);
    var pouchTable = this.getFirebaseTable(ref);
    this.pouchdb = this.pouchDbService.saveTable(pouchTable,obj);
    obj = this.clearFBObj(obj);
    return list.push(obj).then(() => {
      //console.log("saved");
      status = true;
      return status;
    }).catch((error) => {
      status = false;
      //console.error('Error writing new message to Firebase Database', error);

      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant("Error writing new message to Firebase Database")
      };
      this.events.publish('alert:basic',alert);

      return status;
    });
  }

  save(ref,obj){
    //console.log("firebase - save", obj);
    var status;
    var object = this.getDBObject(ref);
    //console.log("object",object);
    obj = this.dataService.arrToObj(obj);
    var pouchTable = this.getFirebaseTable(ref);
    //console.log("pouchTable: "+pouchTable);
    this.pouchdb = this.pouchDbService.saveTable(pouchTable,obj);
    obj = this.clearFBObj(obj);
    //console.log(object,obj);
    return object.set(obj).then(() => {
      //console.log("saved");
      status = true;
      return status;
    }).catch((error) => {
      status = false;
      //console.error('Error writing new message to Firebase Database', error);
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant("Database save Error")
      };
      this.events.publish('alert:basic',alert);
      return status;
    });
  }

  clearFBObj(obj){
    if(obj){
      delete obj.$key;
      delete obj.$value;
    }
    return obj;
  }

  getFirebaseTable(ref){
    //console.log(ref);
    /*
    var table = "";
    var path = ref._ref.path.o;
    //console.log(path);
    for(var i in path){
      //console.log(path[i]);
      if(table != ""){
        table += "/";
      }
      table += path[i];
    }
    return this.pouchDbService.initDB(table);
    */
    return this.pouchDbService.initDB(ref);
  }

  getFbUsers(){
    //console.log("getFirebaseUsers");
    //console.log("uid: "+this.profile.uid+" - name: "+this.profile.displayName+" - email: "+this.profile.email);
    // Loads the last 12 messages and listen for new ones.
    /*
    var setUsers = function(data) {
      var val = data.val();
      this.displayUser(data.key, val.name, val.text, val.photoUrl, val.imageUrl);
    }.bind(this);

    var usersRef = this.getDBObject('users/'+profile.uid);
    usersRef.orderByChild("name").startAt("B").limitToLast(12).on('child_added', setUsers);
    usersRef.orderByChild("name").startAt("B").limitToLast(12).on('child_changed', setUsers);
    usersRef.orderByChild("name").startAt("B").limitToLast(12).on('child_removed', setUsers);
    */
  }

  displayUser(key, name, text, photoUrl, imageUrl){
    //console.log("User: "+key+" "+name+" "+text);
  }

  filterMessage(){
    var setMessage = function(data) {
      var val = data.val();
      this.displayMessage(data.key, val.name, val.text, val.photoUrl, val.imageUrl);
    }.bind(this);
    this.messagesRef.orderByChild("name").startAt("B").limitToLast(12).on('child_added', setMessage);
    this.messagesRef.orderByChild("name").startAt("B").limitToLast(12).on('child_changed', setMessage);
    this.messagesRef.orderByChild("name").startAt("B").limitToLast(12).on('child_removed', setMessage);
  }

  displayMessage(key, name, text, photoUrl, imageUrl){
    //console.log("Message: "+key+" "+name+" "+text);
  }

  saveUserSettingsObj(profile){
    //console.log("saveUserSettingsObj", profile);
    delete profile.appName;
    delete profile.authDomain;
    delete profile.emailVerified;
    delete profile.isAnonymous;
    delete profile.providerData;
    delete profile.stsTokenManager;

    profile.displayName = profile.firstName+" "+profile.lastName;
    //console.log("saveUserSettingsObj - uid: "+profile.uid);
    //console.log(profile);
    //var usersRef = this.getDBObject('users/'+profile.uid);
    return this.save('users/'+profile.uid,profile).then(function(status) {
      //console.log(status);
      if(status){
        //console.log("firebase - config settings saved");
        this.config.set('profile',profile);
      }
      return status;
    }.bind(this));
  }

  saveUserSettingsSettings(profile){
    //console.log("saveUserSettingsSettings");
    var db_name = 'users/'+profile.uid+'/settings';
    //console.log("db_name: "+db_name);
    //console.log(profile.settings);
    //var settingsRef = this.getDBList(db_name);
    return this.save(db_name,profile.settings).then(function(status) {
      //console.log(status);
      if(status){
        //console.log("firebase - config settings saved");
        //this.config.set('settings',settings);
      }
      return status;
    }.bind(this));
  }

  saveUserSetting(profile,key,val){
    //console.log("saveUserSetting - uid: "+profile.uid+" - key: "+key+" - val: "+val);
    //var usersRef = this.getDBList('users/'+profile.uid+'/'+key);
    return this.save('users/'+profile.uid+'/'+key,val).then(function(status) {
      return status;
    }.bind(this));
  }

  disconnet(){
    //console.log("firebase disconnet");
    this.loggedOut = true;
    //if(this.config.get('electron')){
      this.firebasesignInAnonymously();
    /*} else {
      this.af.auth.login({
        method: AuthMethods.Anonymous,
        provider: AuthProviders.Anonymous
      });
    }*/
  }
}
