import { Injectable } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, NavController, Storage, LocalStorage } from 'ionic-angular';
import { HomePage } from '../../pages/home/home';
import { LoginPage } from '../../pages/login/login';
import * as firebase from 'firebase';

@Injectable()
export class AuthData {
  // Here we declare the variables we'll be using.
  public fireAuth: any;
  public userProfile: any;
  local: Storage;

  constructor(
    private nav: NavController,
    private events: Events,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.nav = nav;

    this.fireAuth = firebase.auth(); // We are creating an auth reference.
    // This declares a database reference for the userProfile/ node.
    this.userProfile = firebase.database().ref('/userProfile');

  }

  /**
   * [loginUser We'll take an email and password and log the user into the firebase app]
   * @param  {string} email    [User's email address]
   * @param  {string} password [User's password]
   */
  loginUser(email: string, password: string): any {
    return this.fireAuth.signInWithEmailAndPassword(email, password).then( authData => {
      console.log("loginUser success");
      //this.nav.setRoot(HomePage);
    }, error => {
      console.log("loginUser error",error);
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant(error.message)
      };
      this.events.publish('alert:basic',alert);
    });
  }

  /**
   * [signupUser description]
   * This function will take the user's email and password and create a new account on the Firebase app, once it does
   * it's going to log the user in and create a node on userProfile/uid with the user's email address, you can use
   * that node to store the profile information.
   * @param  {string} email    [User's email address]
   * @param  {string} password [User's password]
   */
  signupUser(email: string, password: string): any {
    return this.fireAuth.createUserWithEmailAndPassword(email, password).then((newUser) => {
      this.fireAuth.signInWithEmailAndPassword(email, password).then((authenticatedUser) => {
        this.userProfile.child(authenticatedUser.uid).set({
          email: email
        }).then(() => {
          console.log("register success");
          //this.nav.setRoot(HomePage);
        });

      })
    }, (error) => {
        console.log("error",error);
        var alert = {
          title: this.translate.instant("Error"),
          msg: this.translate.instant(error.message)
        };
        this.events.publish('alert:basic',alert);
    });
  }

  /**
   * [resetPassword description]
   * This function will take the user's email address and send a password reset link, then Firebase will handle the
   * email reset part, you won't have to do anything else.
   *
   * @param  {string} email    [User's email address]
   */
  resetPassword(email: string): any {
    return this.fireAuth.sendPasswordResetEmail(email).then((user) => {
      var alert = {
        title: this.translate.instant("Success"),
        message: this.translate.instant("We just sent you a reset link to your email")
      };
      this.events.publish('alert:basic',alert);
    }, (error) => {
      var errorMessage: string;
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "You'll need to write a valid email address";
          break;
        case "auth/user-not-found":
          errorMessage = "That user does not exist";
          break;
        default:
          errorMessage = error.message;
      }
      console.log("error",error);
      var alert = {
        title: this.translate.instant("Error"),
        msg: this.translate.instant(errorMessage)
      };
      this.events.publish('alert:basic',alert);
    });
  }

  /**
   * This function doesn't take any params, it just logs the current user out of the app.
   */
  logoutUser(): any {
    //console.log("auth-data: logoutUser");
    //this.events.publish('user:logout');
    return this.fireAuth.signOut();
  }

}
