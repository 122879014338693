import { Injectable } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { Events, Config } from 'ionic-angular';
import { Http, Headers } from '@angular/http';

import { firebaseService } from './firebaseService';
import { gdatService } from './gdatService';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

@Injectable()
export class databaseService {

  private reservationsRef;
  private roomsRef;

  constructor(
    private http: Http,
    private events: Events,
    private config: Config,
    private gdatService: gdatService,
    private FBService: firebaseService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    enum dataValues {
      general,
      employees
    };
  }

  getTerminInfos(data){
    return new Promise(resolve => {
      var stackArr = [];
      stackArr = this.gdatService.addStack(stackArr,'apiGetGuestData',data);
      stackArr = this.gdatService.addStack(stackArr,'apiGetReservationDataAdv',data);
      this.gdatService.stack(stackArr).then(function(res) {
        //console.log(res);
        resolve(res);
      }.bind(this));
    });
  }

  getRooms(){
    return new Promise(resolve => {
      this.roomsRef = this.FBService.getDBObject('company/'+this.config.get('GDATNR')+'/RoomData/Room');
      //console.log("getRooms");
      this.roomsRef.on('value', (data) => {
        var snapshot = data.val();
        //this.roomsRef.subscribe((snapshot) => {
        //console.log("Firebase Rooms");
        //console.log(snapshot);
        var this_rooms = [];
        for(var item in snapshot){
          if(snapshot[item].ZIMMER_NAME != ''){
            this_rooms.push(snapshot[item]);
          }
        }
        resolve(this_rooms);
      });
    });
  }

  getRoom(nr){
    return new Promise(resolve => {
      var roomRef = this.FBService.getDBObject('company/'+this.config.get('GDATNR')+'/RoomData/Room/'+nr);
      //console.log("getRooms");
      roomRef.on('value', (data) => {
        var snapshot = data.val();
        //roomRef.subscribe((snapshot) => {
        //console.log("Firebase Rooms");
        //console.log(snapshot);
        var this_rooms = [];
        for(var item in snapshot){
          if(snapshot[item].ZIMMER_NAME != ''){
            this_rooms.push(snapshot[item]);
          }
        }
        resolve(this_rooms);
      });
    });
  }

  getReservations(reservationsDb){
    return new Promise(resolve => {
      //console.log("loadFirebaseReservations "+reservationsDb);
      this.reservationsRef = this.FBService.getDBObject(reservationsDb);
      this.reservationsRef.on('value', (data) => {
        resolve(data.val());
      });
    });
  }

}
