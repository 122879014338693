import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Config, Content, Page, NavController, Events } from 'ionic-angular';

import { UserData } from '../../providers/user-data';
import { firebaseService } from '../../services/firebaseService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
    templateUrl: 'build/pages/settings/settings.html',
    directives: [ToolbarMenu,HeaderDirective],
    providers: [HeaderDirective]
})

export class SettingsPage {
    @ViewChild(Content) protected content: Content;

    private toolbar_options:any = [];
    private languages: any;
    /*
    private language: string;
    private startPage: any;
    */
    private profile: any;

    private usersRef: any;
    private appPages: any;

    constructor(
      private config: Config,
      private events: Events,
      private nav: NavController,
      private headerDirective: HeaderDirective,
      private FBService: firebaseService,
      private userData: UserData,
      private translate: TranslateService
    ) {
      this.translate = translate;
      this.translate.instant('json.string');
      this.toolbar_options = {
        title: translate.instant('Settings')
      };
      this.nav = nav;

      this.loadSettings();
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    ngAfterViewInit() {
      //this.loadSettings();
    }

    loadSettings(){
      this.profile = this.config.get('profile');
      //console.log(this.profile.settings.language);
      //console.log(this.profile.settings.startPage);
      //console.log(this.profile.settings.apiTimeout);

      this.languages = this.config.get('languages');
      //this.profile.settings.language = this.config.get('language');
      //console.log("this.languages");

      this.appPages = this.config.get('appPages');
      //console.log("appPages");
      //console.log(this.appPages);
      /*
      setTimeout( () => {
        this.appPages = this.config.get('appPages');
        //console.log("appPages");
        //console.log(this.appPages);
      },1000);
      */

    }

    changeLanguage(key){
      /*
      this.config.set('language', this.profile.settings.language);
      this.events.publish('settings:setLanguage',this.profile.settings.language);
      this.loadSettings();
      */
      /*
      //console.log("changeLanguage - language: "+this.profile.settings.language);
      this.config.set('language', this.profile.settings.language);
      //console.log("config - language: "+this.config.get('language'));
      this.FBService.saveUserSetting(this.profile,'language',this.profile.settings.language);
      this.events.publish('settings:setLanguage',this.profile.settings.language);
      this.loadSettings();
      */
    }

    changeStartPage(key){
      /*
      //console.log("changeStartPage - startPage: "+this.profile.settings.startPage);
      this.config.set('startPage', this.profile.settings.startPage);
      //console.log("config - startPage: "+this.config.get('startPage'));
      this.FBService.saveUserSetting(this.profile,'startPage',this.profile.settings.startPage);
      */
    }

    changeApiTimeout(){
      //console.log("changeApiTimeout - apiTimeout: "+this.profile.settings.apiTimeout);
    }

    save(){
      var status = this.FBService.saveUserSettingsSettings(this.profile).then(function(status) {
        //console.log("setup- status: "+status);
        if(status){
          var alert = {
            title: this.translate.instant("Success"),
            msg: this.translate.instant("Saved successfully")
          };
          this.events.publish('alert:basic',alert);
        } else {
          var alert = {
            title: this.translate.instant("Error"),
            msg: this.translate.instant("Data couldn't be saved - please check your Internet Connection and try again")
          };
          this.events.publish('alert:basic',alert);
        }
      }.bind(this));
    }
}
