import { Component, ViewChild } from '@angular/core';
import { Config, Content, Events, NavController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { gdatService } from '../../services/gdatService';
import { dataService } from '../../services/dataService';
import { firebaseService } from '../../services/firebaseService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  templateUrl: 'build/pages/sync/sync.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class SyncPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  private username: string;
  private sessionToken: string;
  private syncDataCustomer: any;
  private syncData: any;
  private App: any;
  private timestamp: number;
  private syncing: boolean = true;
  private syncSuccess: boolean = false;
  private syncError: boolean = false;

  constructor(
    public nav: NavController,
    private events: Events,
    private config: Config,
    private headerDirective: HeaderDirective,
    private dataService: dataService,
    private FBService: firebaseService,
    private gdatService: gdatService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Sync')
    };
    this.nav = nav;
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  ngAfterViewInit() {
    //console.log("ngAfterViewInit");
    this.App = this.config.get('App');
    this.startSync();
  }

  startSync(){
    //console.log("startSync");
    //this.events.publish('loading:show');
    var synced_step_1 = false;
    var synced_step_2 = false;
    this.syncing = true;
    this.timestamp = Date.now();
    this.gdatService.api('apiGetGdatCustomerData','').then((res) => {
      //console.log("apiGetGdatCustomerData");
      synced_step_1 = true;
      if(res['SUCCESS']){
        //console.log("success apiGetGdatCustomerData");
        //console.log(res['API']);

        //console.log(res['API']['Customer']);
        this.config.set('GDATNR',res['API']['Customer'].GDATNR);
        this.syncDataCustomer = res['API']['Customer'];

        if(synced_step_1 && synced_step_2){
          if(typeof res['API'].ErrorMessage == 'undefined'){
            //console.log("sync success 1");
            this.syncReady();
          } else {
            //console.log("sync error 1");
            this.syncFailure(typeof res['API'].ErrorMessag);
          }
        }
      } else {
        //console.log("sync error 1b "+res['STATUS']);
        if(synced_step_1 && synced_step_2){
          this.syncFailure(res['STATUS']);
        }
      }
    });


    var stackArr = [];
    //kann nicht im stack abgefragt werden
    //stackArr = this.gdatService.addStack(stackArr,'apiGetGdatCustomerData','');
    stackArr = this.gdatService.addStack(stackArr,'apiGetRoomData','');
    stackArr = this.gdatService.addStack(stackArr,'apiGetHotelData','');
    stackArr = this.gdatService.addStack(stackArr,'apiGetRatesGroups','');
    stackArr = this.gdatService.addStack(stackArr,'apiGetSeasonData','');
    stackArr = this.gdatService.addStack(stackArr,'apiGetGastrodatUserList','');
    stackArr = this.gdatService.addStack(stackArr,'apiGetPreferences','');
    //console.log("stackArr");
    //console.log(stackArr);

    this.gdatService.stack(stackArr).then( (res) => {
      //this.events.publish('loading:hide');
      //console.log("sync2 - stack");
      //console.log(res);
      synced_step_2 = true;
      if(res['SUCCESS']){

        //SAVE SYNC DATA IN FIREBASE DB
        //console.log(res['API']);
        this.syncData = res['API'];
        res['API'] = "";
        if(typeof this.syncData == 'undefined'){
          //console.log("sync error 2");
          this.syncFailure(res['STATUS']);
        } else if(synced_step_1 && synced_step_2){
          if(typeof this.syncData.ErrorMessage == 'undefined'){
            //console.log("sync success 2");
            this.syncReady();
          } else {
            //console.log("sync error 2b");
            this.syncFailure(this.syncData.ErrorMessage);
          }
        }
      } else {
        if(synced_step_1 && synced_step_2){
          //console.log("sync error 2c");
          this.syncFailure(res['STATUS']);
        }
      }
    });
  }

  syncFailure(error){
    //console.log("syncFailure", error);
    this.syncing = false;
    this.syncError = true;
    if(typeof error == 'undefined'){
      error = "Sync failure";
    }

    var btnRestart = {
      text: this.translate.instant("Restart"),
      handler: () => {
        this.startSync();
      }
    };

    var btnCancel;
    //muss synchronisieren, wird beim starten ausgführt
    if(this.config.get('syncType') == 'auto'){
      btnCancel = {
        text: this.translate.instant("Cancel"),
        handler: () => {
          //this.events.publish('goToLogin');
          this.events.publish('goToStartPage');
        }
      };
    } else {
      btnCancel = {text: this.translate.instant("Cancel")};
    }

    this.config.set('syncFailure',true);
    var alert = {
      title: this.translate.instant("Error"),
      message: this.translate.instant(error),
      buttons : [
        btnCancel,
        btnRestart
      ]
    };
    this.events.publish('confirm:basic',alert);
  }

  syncReady(){
    //console.log("syncReady");
    var error = false;
    var ready_counter = 0;
    var toSyncLength = 0;

    if(!this.syncData){
        //console.log("no syncData");
        this.syncFailure(this.translate.instant("Sync Error"));
    } else {
        this.syncData.Customer = this.syncDataCustomer;

        for(var item in this.syncData) {
          //console.log("item: "+item);
          this.syncData[item]['timestamp'] = this.timestamp;
          toSyncLength++;
        }
        //console.log("toSyncLength: "+toSyncLength);

        for(var item in this.syncData) {
          //console.log("item: "+item);
          this.FBService.save('company/'+this.config.get('GDATNR')+'/'+item ,this.syncData[item]).then( (status) => {
            ready_counter++;
            //console.log(ready_counter+" synced");

            if(!status){
              error = true;
            }
            if(toSyncLength == ready_counter){
              this.syncData = "";
              if(!error){
                this.syncUserStandards();
              } else {
                this.syncFailure(this.translate.instant("Not all data could be synced"));
              }
            }
          });
        }
    }
  }

  syncUserStandards(){
    //console.log("syncUserStandards");
    //SPEICHERN DER USER STANDARDS EINSTELLUNGEN
    //WIE DIE STARTAPP, DAS STARTHOTEL, DAS LETZTE SYNC DATUM
    //console.log("speichern der user standard settings")
    var profile = this.config.get('profile');
    var standards = this.config.get('standards');
    standards.app.Version = this.App.Version;
    standards.company.timestamp = this.timestamp;
    standards.company.GDATNR = this.config.get('GDATNR');
    //console.log("before set config");
    this.config.set('standards',standards);
    //console.log("after set config");
    //console.log("profile.uid: "+profile.uid);
    //console.log(standards);
    this.FBService.save('users/'+profile.uid+'/standards',standards).then(function(status) {
      //console.log("UserStandards synced");
      this.syncing = false;
      this.syncSuccess = true;
      if(status){
        //console.log("sync - afterSync");
        //muss synchronisieren, wird beim starten ausgführt
        if(this.config.get('syncType') == 'auto'){
          this.events.publish('afterSync');
        } else {
          var alert = {
            title: this.translate.instant("Success"),
            msg: this.translate.instant("Synced successfully")
          };
          this.events.publish('alert:basic',alert);
        }
      } else {
        this.syncFailure();
      }
    }.bind(this));

  }

}
