import { Component, Input, OnInit, ElementRef, } from '@angular/core';
import { IONIC_DIRECTIVES, Config, Events, Content } from 'ionic-angular';

let meParallax;

@Component({
  selector: 'parallax-header',
  templateUrl: 'build/components/parallax-header/parallax-header.html',
  directives: [IONIC_DIRECTIVES], // makes all Ionic directives available to your component
  inputs: ['options'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class ParallaxHeader {
  //private winWidth: number = 0;
  private visible: boolean = false;
  private options: any = [];


  private element;
  private scrollerHandle;
  private header;
  private header_blur;
  private headerHeight;
  private translateAmt;
  private scaleAmt:number = 1;
  private scrollTop;
  private lastScrollTop;
  private scrolling:boolean = false;
  private content: Content;
  private started: boolean = false;

  constructor(
    private config: Config,
    private events: Events,
    element: ElementRef
  ) {
    meParallax = this;
    this.element = element;
  }

  //ngOnInit(){
  ngAfterViewInit() {
    //console.log("ngOnInit");
    this.setHeader();
  }

  onResize(e){
    //console.log("onResize");
    this.setHeader();
  }

  getSize(){
    this.headerHeight = this.scrollerHandle.clientHeight;
  }

  setHeader(){
    //console.log("parallax-header - setHeader",this.options.headerImg);
    //this.getWidth();
    if(this.options.headerImg){
      this.content = this.options.content;
      this.visible = true;
      if(!this.started){
        this.start();
      }
      this.getSize();
      this.updateElasticHeader();
    } else {
      this.visible = false;
    }
  }

  start() {
    //console.log("start");
    this.started = true;
    this.scrollerHandle = this.element.nativeElement.parentNode;
    //console.log("scrollerHandle",this.scrollerHandle);
    this.header = this.element.nativeElement.children[0];
    //console.log("header",this.header);
    this.header_blur = this.element.nativeElement.querySelector('#elastic-header-blur');
    this.translateAmt = null;
    this.scrolling = null;
    this.lastScrollTop = null;

    //this.header.style.webkitTransformOrigin = 'center bottom';

    this.scrollerHandle.addEventListener('scroll', (event) => {
        //console.log("scrolling");
        this.scrollTop = event.target.scrollTop;
        //if(!this.scrolling){
          this.scrolling = true;
          //setTimeout( ()=>{
            //console.log("resizing");
            this.updateElasticHeader();
          //},500);
        //}
    });

  }


  updateElasticHeader(){
    //console.log("updateElasticHeader",this.header);
    if(this.header){
      if (this.scrollTop >= 0) {
          this.translateAmt = this.scrollTop / 2;
          //this.scaleAmt = 1;
      } else {
          this.translateAmt = 0;
          //this.scaleAmt = -this.scrollTop / this.headerHeight + 1;
      }
      if(this.options.parallax){
        this.header.style.webkitTransform = 'translate3d(0,'+this.translateAmt+'px,0) scale('+this.scaleAmt+','+this.scaleAmt+')';
      }
      if(this.options.blur){
        this.header_blur.style.WebkitFilter  = 'blur('+this.translateAmt/10+'px)';
      }
      this.scrolling = false;
    }
  }
}
