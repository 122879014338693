import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Content, Modal, Alert, NavController } from 'ionic-angular';

import { gdatService } from '../../services/gdatService';
import { dataService } from '../../services/dataService';
import { databaseService } from '../../services/databaseService';
import { firebaseService } from '../../services/firebaseService';
import { pouchDbService } from '../../services/pouchDbService';
import { belegungsplanService } from '../../services/belegungsplanService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

import { ReservierungDetailPage } from "../reservierung-detail/detail";

import {Observable} from 'rxjs/Rx';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

@Component({
  templateUrl: 'build/pages/belegungsplan/belegungsplan.html',
  host: {
    '(window:resize)': 'onResize($event)'
  },
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective,belegungsplanService]
})
export class BelegungsplanPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  private timer_interval: any;
  private subscription: any;
  private blp: any;
  private params: any = {};
  private termine: any = [];
  private termineLoaded: boolean = false;
  private drawStarted: boolean = false;
  private desktop: boolean = false;
  private init: boolean = false;
  private rooms: any;
  private standards: any;
  private reservationsDb: string = '';
  private timestamp: number = 0;
  private loadedReady: boolean = false;
  private FiebaseloadedReady: boolean = false;
  private drawingCounter: number;

  constructor(
    private nav: NavController,
    private headerDirective: HeaderDirective,
    private gdatService: gdatService,
    private FBService: firebaseService,
    private dataService: dataService,
    private dbService: databaseService,
    private bplanService: belegungsplanService,
    private pouchDbService: pouchDbService,
    private config: Config,
    private events: Events,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Room Planner')
    };

    this.events = events;

    this.params = {};
    this.timer_interval = Observable.interval(100);
    this.subscription = this.timer_interval.subscribe((x) => {
      this.belgungsplanLodedCheck();
      //console.log("interval");
    });

    this.listenToEvents();
  }


  showDetail(data) {
    //console.log("showDetail");
    //console.log(data);
    this.events.publish('modal', {
      data: { data: data },
      page: ReservierungDetailPage,
      onDismissHandler: (data: any[]) => {
        //console.log("data",data);
        this.bplanService.addListener();
      }
    });
  }

  listenToEvents(){
    if(!this.config.get('belegungsplanEvents')){
      this.config.set('belegungsplanEvents',true);
      //console.log("listenToEvents");
      this.events.subscribe('reservation:detail', (data) => {
        //console.log("reservation:detail");
        this.showDetail(data[0]);
      });

      this.events.subscribe('belegungsplan:reload', (data) => {
        //console.log("belegungsplan:reload");
        this.set('');
      })
    }
  }

  onResize(e){
    if(this.termineLoaded){
      //console.log("onResize");
      this.bplanService.resize("all");
    }
  }

  ionViewWillLeave(){
    //console.log("willLeave");
    this.bplanService.onExit();
  }

  ionViewDidEnter(){
  //ngAfterViewInit(){

    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }

    //console.log("ionViewWillEnter");
    this.initBelegungsplan();
    this.standards = this.config.get('standards');
    if(!this.config.get('mobile')){
      this.desktop = true;
    }
    this.set('');
  }

  initBelegungsplan(){
    //console.log("init flase");
    this.init = false;
  }

  changeDate(){
    //console.log("changeDate");
    this.set(this.params.kalenderauswahl);
  }

  prev(){
    this.params.moment = moment(this.params.datum, "YYYYMMDD").subtract(1, 'month');
    //console.log(this.params.moment);
    this.params.moment = this.params.moment.format('YYYYMM')+'01';
    //console.log(this.params.moment);
    this.set(this.params.moment);
  }
  next(){
    this.params.moment = moment(this.params.datum, "YYYYMMDD").add(1, 'month');
    //console.log(this.params.moment);
    this.params.moment = this.params.moment.format('YYYYMM')+'01';
    //console.log(this.params.moment);
    this.set(this.params.moment);
  }
  set(datum:string){
    //console.log("set - datum: "+datum);
    this.termineLoaded = false;
    this.drawStarted = false;

    //FARBEN
    this.params.rot = '#ee1c25'; //'#f53d3d';
    this.params.gruen = '#8add66'; //'#71d54c';

    //ZIMMER
    this.params.zimmer = '';

    //HEUTE
    this.params.now = moment();
    this.params.heute = this.params.now.format('YYYYMMDD');
    this.params.heute_jahr_monat = this.params.now.format('YYYYMM');
    this.params.heute_jahr = this.params.now.format('YYYY');
    this.params.heute_monat = this.params.now.format('MM');
    this.params.heute_tag = this.params.now.format('DD');
    this.params.heute_tag_zahl = this.params.now.format('D');

    //PLAN DATUM
    if(datum == ""){
      this.params.datum = moment().format('YYYYMM')+'01';
    } else {
      this.params.datum = datum+'01';
    }
    //console.log("datum: "+this.params.datum);
    this.params.moment = moment(this.params.datum, "YYYYMMDD");
    this.params.kalenderauswahl = this.params.moment.format('YYYY-MM-DD');
    this.params.kalender_jahr_monat = this.params.moment.format('YYYYMM');
    this.params.monatsname = this.params.moment.format('MMMM');
    this.params.monatszahl = this.params.moment.format('MM');
    //this.params.monatsindex = this.params.monatszahl*1-1;
    this.params.von = '01'+this.params.moment.format('.MM.YYYY');
    this.params.bis   = this.params.moment.endOf('month').format('DD.MM.YYYY');
    this.params.tage  = this.params.moment.endOf('month').format('D');
    this.params.weekday  = this.params.moment.startOf('month').day(); //0-6
    this.params.weekdays = this.config.get('dayShortNames');

    if(this.params.kalender_jahr_monat == this.params.heute_jahr_monat){
      this.params.aktuelles_monat = true;
    } else {
      this.params.aktuelles_monat = false;
    }

    this.reservationsDb = 'company/'+this.config.get('GDATNR')+'/Reservations/'+this.params.kalender_jahr_monat;

    //console.log("params");
    //console.log(this.params);
    this.drawBelegungsplan("set");
    this.getRooms();
  }

  getRooms(){
    if(typeof this.rooms == 'undefined'){
      this.dbService.getRooms().then((res) => {
        this.rooms = res;
        if(this.rooms.length > 0){
          this.getReservations();
        } else {
          var alert = {
            title: this.translate.instant("Error"),
            msg: this.translate.instant('No Rooms created')
          };
          this.events.publish('alert:basic',alert);
        }
      });
    } else {
      if(this.rooms.length > 0){
        this.getReservations();
      }
    }
  }

  getReservations(){
    //console.log("getReservations");
    //this.events.publish('loading:show');
    this.loadedReady = false;
    this.FiebaseloadedReady = false;
    this.termine;
    this.drawingCounter = 0;

    //API Reservations
    this.loadApiReservations();

    //Pouch Reservations
    this.loadPouchReservations();

    //Firebase Reservations
    this.loadFirebaseReservations();

  }

  loadPouchReservations(){
    if(this.config.get('pouchDB')){
      var pouchDB = this.pouchDbService.initDB(this.reservationsDb);
      this.pouchDbService.getAll(pouchDB)
      .then(data => {
        //console.log("Pouch Reservations");
        if(
        //gdat nicht geladen oder offline
        (!this.loadedReady || !this.config.get('apiOnline'))
        && !this.FiebaseloadedReady
        ){
          if(typeof data != 'undefined'){
            //console.log("SET Pouch Reservations");
            //console.log(data);
            var newTermine = this.dataService.objToArr(this.dataService.removeTimestamp(data));
            if(!this.dataService.equalArrObj(this.termine,newTermine) || !this.drawStarted){
              //console.log("DRAW TERMINE POUCH");
              this.drawStarted = true;
              if(this.termine.length != 0){
                //console.log(this.termine);
                //console.log(newTermine);
              }
              this.termine = newTermine;
              //console.log("Pouch Reservations");
              this.drawBelegungsplan("loadPouchReservations");
            }
          }
        }
      }).catch(
        console.error.bind(console)
      );
    }
  }

  loadFirebaseReservations(){
    //console.log("start loadFirebaseReservations "+this.reservationsDb);

    this.dbService.getReservations(this.reservationsDb).then((snapshot) => {

      if(!this.loadedReady || !this.config.get('apiOnline')){
        if(typeof snapshot != 'undefined'){
          //console.log("SET Firebase Reservations");
          //console.log(snapshot);
          this.FiebaseloadedReady = true;
          var newTermine = this.dataService.objToArr(this.dataService.removeTimestamp(snapshot));
          if(!this.dataService.equalArrObj(this.termine,newTermine) || !this.drawStarted){
            //console.log("DRAW TERMINE FIREBASE");
            this.drawStarted = true;
            if(this.termine.length != 0){
              //console.log(this.termine);
              //console.log(newTermine);
            }
            this.termine = newTermine;
            //console.log(this.termine);
            //console.log(this.termine);
            //console.log("Firebase Reservations");
            this.drawBelegungsplan("loadFirebaseReservations");
          }
        }
      }

      //save for initial emitter
      if(!this.loadedReady && !this.init){
        this.init = true;
        var this_db = this.reservationsDb+'/initial';
        //console.log("this_db: "+this_db);
        var saveObj = [];
        //saveObj['time'] = this.timestamp;
        this.FBService.save(this_db,saveObj).then(function(status) {
          if(!status){
            //console.log("firebase reservations initalized error")
          } else {
            //console.log("firebase reservations initalized");
          }
        }.bind(this));
      }

    });
  }

  loadApiReservations(){
    this.timestamp = Date.now();
    //console.log("timestamp: "+this.timestamp);
    this.gdatService.api('apiGetReservationsFromTo',this.params).then((res) => {
      //console.log(res);
      if(res['SUCCESS']){
        //console.log("SET API Reservations");
        //console.log(res['API'].ReservationData.Reservation);
        this.loadedReady = true;
        this.parseReservations(this.dataService.removeTimestamp(res['API'].ReservationData.Reservation));
      } else {
        //console.log("error "+res['STATUS']);
        this.loadedReady = true;
        if(this.config.get('apiOnline')){
          //console.log("error "+res['STATUS']);
          var alert = {
            title: this.translate.instant("Error"),
            msg: this.translate.instant(res['STATUS'])
          };
          this.events.publish('alert:basic',alert);
        }
      }
    });
  }

  parseReservations(Reservations){
    //console.log(Reservations);
    var error = false;
    if(typeof Reservations != 'undefined'){
      if(Reservations.length > 0){
        for(var t=0;t<Reservations.length;t++){
            Reservations[t].tag = moment(Reservations[t].VON,'DD.MM.YYYY').format('D');
            var v = moment(Reservations[t].VON,'DD.MM.YYYY');
            var b = moment(Reservations[t].BIS,'DD.MM.YYYY');
            var diff = b.unix()-v.unix();
            var duration = moment.duration(diff*1000); //value in milliseconds
            var days = duration.days();
            Reservations[t].dauer = days;
            Reservations[t].info = Reservations[t].PERSON;
        }
      } else {
        error = true;
      }
    } else {
      error = true;
    }
    if(error){
      Reservations = [];
    }
    if(!this.dataService.equalArrObj(this.termine,Reservations)){
      //console.log("DRAW TERMINE API");
      //console.log(Reservations);
      if(this.termine.length != 0){
        //console.log(this.termine);
        //console.log(Reservations);
      }
      this.termine = Reservations;
      this.saveFirebaseReservations(Reservations);
    }
    //console.log("Gdat Reservations");
    this.drawBelegungsplan("parseReservations");
  }

  saveFirebaseReservations(Reservations){
    //SAVE TO FIREBASE
    //console.log("saveFirebaseReservations");
    //this.termine['timestamp'] = this.timestamp;
    this.FBService.save(this.reservationsDb,Reservations).then(function(status) {
      if(!status){
        //console.log("firebase reservations save error")
      }
    }.bind(this));
  }

  belgungsplanLodedCheck(){
      //console.log("belgungsplanLodedCheck");
      if(this.termineLoaded){
          //console.log("unsubscribe");
          this.subscription.unsubscribe();
      }
  }

  drawBelegungsplan(from){
    //console.log("drawBelegungsplan: "+from);
    this.termineLoaded = true;
    //this.events.publish('loading:hide');

    if(
      typeof this.rooms != 'undefined'
      && (from.indexOf('set') == -1 || from.indexOf('set') != -1 && this.drawingCounter == 0)
    ){
      //console.log("bplanService.begin: "+from+" - "+this.termine.length,this.termine);
      this.params.rooms = this.rooms;
      this.params.headerDirective = this.headerDirective;
      //console.log("this.bplanService",this.bplanService);
      this.bplanService.begin('zimmerplan',this.termine,this.params,this.drawingCounter,from);
      this.drawingCounter++;
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
    } else {
      //console.log("timeout");
      setTimeout(() => {
        this.drawBelegungsplan(from+"self");
      }, 100);
    }
  }
}
