import { ElementRef, OnInit, ViewChild } from '@angular/core';
import { Events, Config, Content, Page, Modal, NavController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

//import { UserService } from '../../common/user.service';
import { User, OtherUser, AuthService } from '../../common/auth.service';
import { WebRTCService } from '../../common/webrtc.service';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Page({
    templateUrl: 'build/pages/messenger_video/video.html',
    directives: [ToolbarMenu,HeaderDirective],
    providers: [HeaderDirective]
})
export class MessengerVideoPage implements OnInit {
    @ViewChild(Content) protected content: Content;

    private toolbar_options:any = [];
    myVideo: HTMLMediaElement;
    otherVideo: HTMLMediaElement;

    me: User = {};
    otherUser: OtherUser = new OtherUser();

    private calling: boolean = false;
    private profile: any;

    constructor(
      private config: Config,
      private events: Events,
      private headerDirective: HeaderDirective,

      //private userService: UserService,
      //private authService: AuthService,

      private webRTC: WebRTCService,
      private nav: NavController,
      private elRef: ElementRef,
      private translate: TranslateService
    ) {
      this.translate = translate;
      this.translate.instant('json.string');
      this.toolbar_options = {
        title: translate.instant('Messenger')+" "+translate.instant('Video')
      };
      //this.me = authService.user;
    }

    ionViewDidEnter(){
      if(!this.config.get('homePageLoaded')){
        this.events.publish('homePageLoaded',true);
      }
    }

    ngOnInit(): any {
        this.profile = this.config.get('profile');
        this._afterLogin()
        // Find video elements
        this.myVideo = this.elRef.nativeElement.querySelector('#my-video');
        this.otherVideo = this.elRef.nativeElement.querySelector('#other-video');
        //
        this.webRTC.init(this.myVideo, this.otherVideo, () => {
            console.log('I\'m calling');
            if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
        });
    }

    _afterLogin() {
        // Create Peer
        this.webRTC.createPeer(this.profile.uid);
        // Now change the rootPage to tabs
        //this.rootPage = TabsPage;
    }

    getOtherUserName(): string {
        if (this.otherUser.notEmpty()) {
            return this.otherUser.name;
        } else {
            return 'Choose the User to call...';
        }
    }

    chooseOtherUser() {
        console.log('Choose other user');
        /*
        let modal = Modal.create(UsersPage);
        modal.onDidDismiss((value: any) => {
            console.log('Selected user', value);
            this.otherUser = value;
        });
        this.nav.present(modal);
        */
    }

    startCall() {
        console.log('Call to ', this.otherUser.id);
        if(typeof this.webRTC != 'undefiend'){
          this.webRTC.call(this.otherUser.id);
        } else {
          console.log("ERROR CALL: UNDEFINED webRTC");
        }
    }

    stopCall() {
        console.log('Stop calling to other user', this.otherUser.name);
        if(typeof this.webRTC != 'undefiend'){
          this.webRTC.endCall();
        } else {
          console.log("ERROR DROP: UNDEFINED webRTC");
        }
    }

    call(){
      if(!this.calling){
        this.startCall();
      } else if(this.calling){
        this.stopCall();
      }
    }
}
