import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Content, Config, NavController } from 'ionic-angular';
import { LoginPage } from '../login/login';
import { UserData } from '../../providers/user-data';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

//import {AuthData} from '../../providers/auth-data/auth-data';

@Component({
  templateUrl: 'build/pages/account/account.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class AccountPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  username: string;

  constructor(
    private config: Config,
    private events: Events,
    private nav: NavController,
    private headerDirective: HeaderDirective,
    private userData: UserData,
    //public authData: AuthData,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Profile')
    };
    this.nav = nav;
    //this.authData = authData;
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  /**
   * This calls the logoutUser function from the AuthData provider.
   */
  /*
  logOut(){
    this.authData.logoutUser().then(() => {
      this.nav.rootNav.setRoot(LoginPage);
      this.events.publish('user:logout');
      this.nav.setRoot(LoginPage);
    });
  }
  */

  logOut() {
    //console.log("account: logoutUser");
    this.events.publish('user:logout');
    //this.userData.logout();
    //this.nav.setRoot(LoginPage);
  }


  ngAfterViewInit() {
    this.getUsername();
  }

  updatePicture() {
    //console.log('Clicked to update picture');
  }

  // Present an alert with the current username populated
  // clicking OK will update the username and display it
  // clicking Cancel will close the alert and do nothing
  changeUsername() {
    /*
    let alert = Alert.create({
      title: 'Change Username',
      buttons: [
        'Cancel'
      ]
    });
    alert.addInput({
      name: 'username',
      value: this.username,
      placeholder: 'username'
    });
    alert.addButton({
      text: 'Ok',
      handler: data => {
        this.userData.setUsername(data.username);
        this.getUsername();
      }
    });
    this.nav.present(alert);
    */

    var alert = {
      title: this.translate.instant("Change Username"),
      message: '',
      input1: {
        name: 'username',
        value: this.username,
        placeholder: 'username'
      },
      buttons : [
        {
          text: this.translate.instant("Cancel")
        },
        {
          text: this.translate.instant("OK"),
          handler: data => {
            this.userData.setUsername(data.username);
            this.getUsername();
          }
        }
      ]
    };
    this.events.publish('confirm:basic',alert);
  }

  getUsername() {
    /*this.userData.getUsername().then((username) => {
      this.username = username;
    });*/
    this.username = this.userData.getUsername();
  }

  changePassword() {
    //console.log('Clicked to change password');
  }


}
