import { Component, ViewChild, provide, PLATFORM_PIPES, enableProdMode } from '@angular/core';

import { Loading, Events, ionicBootstrap, MenuController, Nav, Platform, Config, AlertController, ActionSheetController, ModalController, PopoverController, LoadingController  } from 'ionic-angular';
import { Splashscreen, StatusBar, Geolocation } from 'ionic-native';

import { TranslateService, TranslatePipe, TranslateLoader, TranslateStaticLoader } from 'ng2-translate/ng2-translate';
import { Http, HTTP_PROVIDERS } from '@angular/http';

//import { AppDirective } from './directives/app-directive';

//import { AuthData } from './providers/auth-data/auth-data';

import { WebRTCConfig } from './common/webrtc.config';
import { WebRTCService } from './common/webrtc.service';

import { ConferenceData } from './providers/conference-data';
import { UserData } from './providers/user-data';
import { dataService } from './services/dataService';
import { databaseService } from './services/databaseService';
import { firebaseService } from './services/firebaseService';
import { gdatService } from './services/gdatService';
import { pouchDbService } from './services/pouchDbService';

import { BlankPage } from './pages/blank/blank';
import { HomePage } from './pages/home/home';
import { AccountPage } from './pages/account/account';
import { LoginPage } from './pages/login/login';
import { LogoutPage } from './pages/logout/logout';
import { SignupPage } from './pages/signup/signup';

import { TabsPage } from './pages/tabs/tabs';
import { TutorialOverviewPage } from './pages/tutorial-overview/tutorial-overview';
import { TutorialPage } from './pages/tutorial/tutorial';
import { EmployeeListPage } from './pages/employee-list/employee-list';
import { SettingsPage } from './pages/settings/settings';
import { TodosPage } from './pages/todos/todos';
import { NewsPage } from './pages/news/news';
import { BelegungsplanPage } from './pages/belegungsplan/belegungsplan';
import { StatsPage } from './pages/stats/stats';
import { TranslationPage } from './pages/translation/translation';
import { SetupPage } from './pages/setup/setup';
import { SyncPage } from './pages/sync/sync';
import { MessengerPage } from './pages/messenger/tabs';
import { ProgramPage } from './pages/program/program';

import { Observable } from 'rxjs/Rx';

import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

//EXTERNAL FUNCTIONS
//declare var updateFunc:any;

interface PageObj {
  title: string;
  component: any;
  icon: string;
  index?: number;
  mainMenu: boolean;
}

@Component({
  templateUrl: 'build/app.html',
  providers: [],
  pipes: [TranslatePipe]
  //,directives: [AppDirective]
})
class ConferenceApp {
  // the root nav is a child of the root app component
  // @ViewChild(Nav) gets a reference to the app's root nav
  @ViewChild(Nav) nav: Nav;
  appPages: PageObj[] = [];
  loggedInPages: PageObj[] = [];
  loggedOutPages: PageObj[] = [];

  //TutorialPage,EmployeeListPage,TodosPage,BelegungsplanPage,HomePage,LoginPage
  rootPage: any = BlankPage;

  private standardLanguage: string = "de";
  private standardStartPage: string = "BelegungsplanPage";
  private languagesRef: any;
  private languages: any;
  private language: string;
  private timer: any;
  private timer_interval: number;
  private loginTimeout: number;
  private loginTime: number;
  private subscription: any;
  private translationRef: any;
  private translation: any;
  private usersRef: any;
  private appRef: any;
  private loading: any;
  private now_loading: boolean;
  private HAS_LOGGED_IN: any;
  private firebaseLoginStatus: string;
  private translationLoaded: boolean = false;
  private userSession: any;
  private profile: any;
  private settingsLoaded: boolean;
  private userReady: boolean;
  private toSync: boolean;
  private ApplicationKeyLoaded: boolean;
  private App: any;
  private AppName: string;
  private AppHardcodedVersion: string;
  private AppHardcodedTimestamp: number;
  private standards: any;
  private attention_message: string;
  private externalFunctionText: string;
  private lastPage: string;

  /*STYLES*/
  private primaryColor: string = '#cc0098';

  private dialogStack: any;
  private alertDialog: any;
  private showingAlert: boolean;
  private confirmDialog: any;
  private showingConfirm: boolean;
  private popoverDialog: any;
  private showingPopover: boolean;
  private actionSheetDialog: any;
  private showingActionSheet: boolean;

  private homePageLoaded: boolean = false;
  private loadingMessage: string;

  private forceSync: boolean;
  private forceApiSessionError: boolean;

  constructor(
    public config: Config,
    //private nav: Nav,
    //private authData: AuthData,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private loadingCtrl: LoadingController,
    private events: Events,
    private userData: UserData,
    private menu: MenuController,
    private platform: Platform,
    private confData: ConferenceData,
    private dataService: dataService,
    private FBService: firebaseService,
    private gdatService: gdatService,
    private pouchDbService: pouchDbService,
    private webRTC: WebRTCService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    //console.log("constructor");

    //STANDARD CONFIG
    this.setStandardConfig();

    // Call any initial plugins when ready
    platform.ready().then(() => {
      //console.log("platform ready");
      StatusBar.styleDefault();
      Splashscreen.hide();
      this.menu.swipeEnable(false);
      //this.showLoading();

      this.config.set('hostname',window.location.hostname);
      this.config.set('mobile', true);
      if(this.platform.is('ios')){
        this.config.set('platform', 'ios');
        this.config.set('mode', 'app');
      } else if(this.platform.is('android')){
        this.config.set('platform', 'android');
        this.config.set('mode', 'app');
      } else if(this.platform.is('windows')){
        this.config.set('platform', 'windows');
        this.config.set('mode', 'app');
      } else {
        this.config.set('platform', 'desktop');
        if(!this.platform.is('mobile')){
          this.config.set('mobile', false);
        }
      }

      //ENVIROMENT SETTINGS (ELECTRON,CHROME,NODEJS)
      var env:any = [];
      env.electron = [];
      env.chrome = [];
      env.node = [];
      env.electron.platform = false;
      env.electron.version = 0;
      env.chrome.version = 0;
      env.node.version = 0;
      if(window['process']){
        if(window['process'].versions['electron']){
          //alert("electron "+window['process'].versions['electron']);
          env.electron.platform = true;
          env.electron.version = window['process'].versions['electron'];
          env.chrome.version = window['process'].versions['chrome'];
          env.node.version = window['process'].versions['node'];
        }
      }
      this.config.set('electron',env.electron.platform);
      this.config.set('env',env);
      //console.log("electron",env);


      //SET NO iOS, Windows or Android APP
      if(this.config.get('mode') != "app" || this.config.get('electron')){
        this.config.set('mode', 'noApp');
      }


      //POUCHDB PLATFORM SPECIFIC SETTINGS
      //if(this.config.get('platform') != 'ios'){
        //this.config.set('pouchDB',true);
      //}


      //APP SPECIFIC ACTIONS
      if(this.config.get('mode') == "app"){
        //setTimeout(()=>{
          //alert("start native");
          this.setStatusbar();
          this.getGeoLocation();
        //},2000);
      } else {
        //console.log("no app");
      }

      //console.log("platform:"+this.config.get('platform'));
      //console.log("mobile:"+this.config.get('mobile'));
    });

    // load the conference data
    //confData.load();

    // decide which menu items should be hidden by current login status stored in local storage
    //this.userData.hasLoggedIn().then((hasLoggedIn) => {
      //this.enableMenu(hasLoggedIn === 'true');
    //});

    this.listenToLoginEvents();
  }

  setStandardConfig(){
    //STANDARD CONFIG
    this.now_loading = false;
    this.HAS_LOGGED_IN = false;
    this.firebaseLoginStatus = "tutorial";
    this.userSession = {};
    this.profile = {};
    this.settingsLoaded = false;
    this.userReady = false;
    this.toSync = true;
    this.ApplicationKeyLoaded = false;
    this.App = '';
    this.AppName = "AllInOne";
    this.AppHardcodedVersion = '0.0.0';
    this.AppHardcodedTimestamp = 0;
    this.standards = [];
    this.attention_message = '';
    this.dialogStack = [];
    this.showingAlert = false;
    this.showingConfirm = false;
    this.showingPopover = false;
    this.showingActionSheet = false;
    this.loadingMessage = "loading...";
    this.forceSync = false;
    this.forceApiSessionError = false;
    this.translation = [];
    this.loginTime = 0;
    this.loginTimeout = 10;
    this.timer_interval = 250;
    this.standards.app = [];
    this.standards.company = [];
    this.externalFunctionText = "";

    this.config.set('AppName', this.AppName);
    this.config.set('AppVersion', this.AppHardcodedVersion);
    this.config.set('apiOnline',true);
    this.config.set('forceApiSessionError',this.forceApiSessionError);
    this.config.set('forceSetup',false); //bei falschen gdat API Login Daten
    this.config.set('ApiLoginError',false);
    this.config.set('syncFailure',false);
    this.config.set("firebaseLoginStatus",this.firebaseLoginStatus);
    this.config.set('pouchDB',true);
  }

  loginAction(){
    this.setLoadingMessage("login try...");
    //console.log("loginAction - firebaseLoginStatus: "+this.firebaseLoginStatus);

    if(this.firebaseLoginStatus == "true"){
      this.start();
    } else if(this.firebaseLoginStatus != "true"){
      //console.log("firebase basic login data");
      //console.log("uid: "+this.profile.uid);
      //console.log(this.profile);
      //wenn noch nicht eingeloggt
      //console.log("loginAction");

      this.firebaseLoginStatus = "true";
      this.config.set("firebaseLoginStatus",this.firebaseLoginStatus);

      this.usersRef = this.FBService.getDBObject('users/'+this.profile.uid);
      //console.log(this.usersRef);

      this.usersRef.on('value', (data) => {
        var snapshot = data.val();
      //this.usersRef.subscribe((snapshot) => {
        this.settingsLoaded = true;
        //console.log("settingsLoaded");
        //console.log(snapshot);
        //console.log(snapshot);
        //console.log(snapshot.settings.language);
        //this.profile = snapshot;
        var settings_from_firebase_profile = {
          email: this.profile.email,
          emailVerified: this.profile.emailVerified,
          isAnonymous: this.profile.isAnonymous,
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          photoURL: this.profile.photoURL,
          //providerId: this.profile.providerId,
          //displayName: this.profile.displayName,
          uid: this.profile.uid,
        }
        //this.profile = this.dataService.merge_options(settings_from_firebase_profile,snapshot);
        this.profile = this.dataService.merge_options(settings_from_firebase_profile,snapshot);
        //console.log(this.profile);
        //console.log(this.profile.settings.language);
        this.setUserProfileAfterFirebaseLogin();
      });

      this.appRef = this.FBService.getDBObject('app/'+this.config.get('AppName'));
      this.appRef.on('value', (data) => {
        var snapshot = data.val();
      //this.appRef.subscribe((snapshot) => {
        //console.log("ApplicationKeyLoaded");
        //console.log(snapshot);
        this.ApplicationKeyLoaded = true;
        this.App = snapshot;
        this.config.set('App',this.App);
      });

      this.getLanguages();

      this.showLoading();
      this.timer = Observable.interval(this.timer_interval);
      this.subscription = this.timer.subscribe((x) => {
        this.checkLogin();
        //console.log("interval");
      });
    }
  }

  translateConfig() {
    this.setLoadingMessage("setting language...");
    //console.log("translateConfig");
    var userLang;

    userLang = navigator.language.split('-')[0]; // use navigator lang if available
    userLang = /(de|en|es)/gi.test(userLang) ? userLang : this.standardLanguage;
    //console.log("userLang: "+userLang);

    return userLang;
  }

  getLanguages(){
    //console.log("getLanguages");
    this.setLoadingMessage("loading languages...");
    this.languagesRef = this.FBService.getDBObject('languages');
    this.languagesRef.on('value', (data) => {
      var snapshot = data.val();
    //return this.languagesRef.subscribe((snapshot) => {
      var data = snapshot;
      //console.log("languages");
      //console.log(data);
      this.languages = data;
      this.config.set('languages',this.languages);
    });
  }

  checkLogin(){
    //console.log("checkLogin");
    //console.log("firebaseToken:"+this.userSession.firebaseToken);
    //console.log("email:"+this.profile.email+" - settingsLoaded:"+this.settingsLoaded+" - apiSessionToken:"+this.userSession.apiSessionToken+" - language:"+this.language);
    //console.log("ApplicationKeyLoaded:"+this.ApplicationKeyLoaded+" - settingsLoaded:"+this.settingsLoaded+" - apiSessionToken:"+this.userSession.apiSessionToken+" - language:"+this.language);
    if(
    //this.loginTime > 100000 &&
    typeof this.profile.email != 'undefined'
    //&& typeof this.userSession.firebaseToken != 'undefined'
    && this.settingsLoaded
    && typeof this.languages != 'undefined'
    && this.ApplicationKeyLoaded
    && this.profile.settings.language != 'undefined'
    //&& typeof this.userSession.apiSessionToken != 'undefined'
    ){
        //console.log("userSession.SessionToken: "+this.userSession.apiSessionToken);
        //console.log("userSession.profile.email: "+this.profile.email);
        //console.log("userSession.token: "+this.userSession.firebaseToken);

        this.subscription.unsubscribe();

        this.setAppSettings();
        this.setLanguage(this.profile.settings.language);

    } else {
        //console.log("this.loginTime: "+this.loginTime);
        this.loginTime += this.timer_interval/1000;
        if(this.loginTime > this.loginTimeout){
          this.subscription.unsubscribe();
          this.rootPage = LoginPage;
          this.hideLoading();
          setTimeout(() => {
            this.doAlert(this.translate.instant("Login Error"),this.translate.instant('Login Timeout - Please check your your Internet Connection and try it again.'));
          }, 500);
        }
    }
  }

  changeLanguage(newLang:string){
    //console.log("changeLanguage: "+this.profile.settings.language);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.profile.settings.language);
    moment.locale(newLang);
    this.config.set('monthNames',moment.months());
    this.config.set('monthShortNames',moment.monthsShort());
    this.config.set('dayNames',moment.weekdays());
    this.config.set('dayShortNames',moment.weekdaysShort());

    //asynchrones laden der Translation aus der firebase db
    this.loadTranslation();
  }

  loadTranslation(){
    this.setLoadingMessage("loading translaton...");
    //console.log("loadTranslation: "+this.profile.settings.language);
    this.translationRef = this.FBService.getDBObject('translation/'+this.profile.settings.language);
    //console.log("this.translationRef");
    //console.log(this.translationRef);
    this.translationRef.on('value', (data) => {
      var snapshot = data.val();
    //this.translationRef.subscribe((snapshot) => {
      //console.log("userLang: " + userLang);
      //console.log("loadTranslation snapshot");
      //console.log(snapshot);
      //set the language json object
      this.config.set('translation',snapshot);
      var data = this.unSanitizeTranlationKeys(snapshot);
      //console.log(data);
      this.translate.setTranslation(this.profile.settings.language, data);
      this.translationLoaded = true;
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use(this.profile.settings.language).subscribe(langObj => {
          //console.log("setMenu");
          this.setMenu();
      });
    });
  }

  setMenu(){
    this.setLoadingMessage("setting menu...");
    // List of pages that can be navigated to from the left menu
    // the left menu only works after login
    // the login page disables the left menu
    this.appPages = [];
    this.config.set('appPages',this.appPages);
    this.loggedInPages = [
      { title: this.translate.instant('Profile'), component: AccountPage, icon: 'person', mainMenu: false },
      { title: this.translate.instant('Settings'), component: SettingsPage, icon: 'settings', mainMenu: false},
      { title: this.translate.instant('Setup'), component: SetupPage, icon: 'construct', mainMenu: false},
      { title: this.translate.instant('Logout'), component: LogoutPage, icon: 'log-out', mainMenu: false }
    ];
    this.loggedOutPages = [
      { title: this.translate.instant('Login'), component: LoginPage, icon: 'log-in', mainMenu: false },
      { title: this.translate.instant('Signup'), component: SignupPage, icon: 'person-add', mainMenu: false }
    ];
    this.start();
  }

  gdatLogin(){
    this.setLoadingMessage("GASTROdat login...");
    //console.log("gdatLogin");
    var validProfile = true;
    if(typeof this.profile == 'undefined'){
      validProfile = false;
    } else if(
    typeof this.profile.api_consumerkey != 'undefined' && this.profile.api_consumerkey != ""
    && typeof this.profile.api_username != 'undefined' && this.profile.api_username != ""
    && typeof this.profile.api_password != 'undefined' && this.profile.api_password != ""
    ){
      //console.log("save config profile");
      //console.log(this.profile);
      this.config.set('profile',this.profile);
      this.createApiSession();
    } else if(!this.profile.gdat){
      //console.log("Login without Gastrodat");
      this.start();
    } else {
      validProfile = false;
    }
    if(!validProfile){
      //console.log("!validProfile");
      this.nav.push(SetupPage);
    }
  }

  createApiSession(){
    //console.log("createApiSession");
    this.setLoadingMessage("creating API Session...");
    if(this.config.get('ApiSessionError')){
      //console.log("apiCreateSessionToken");
    }
    this.gdatService.api('apiCreateSessionToken','').then((res) => {
      this.setLoadingMessage("API Session created");
      //console.log(res);
      if(res['SUCCESS']){
        this.config.set('forceSetup',false);
        this.setLoadingMessage("API Session Success");
        //console.log("success "+res['STATUS']);
        //console.log("SessionToken: "+res['API'].SessionToken);
        //console.log(res['API'].Expires);
        this.userSession.apiSessionToken = res['API'].SessionToken;
        this.userSession.apiExpires = res['API'].Expires;
        this.config.set('userSession',this.userSession);
        //console.log("ApiSessionError: "+this.config.get('ApiSessionError'));
        if(!this.config.get('ApiSessionError')){
          //console.log("start");
          this.start();
        } else {
          //NACH SESSION ERROR UND ERNEUERN DER SESSION LADEN DES LETZTEN BEFEHLS
          this.setLoadingMessage("API Session Error");
          //console.log("Session successfully generated (API)");
          //this.doAlert(this.translate.instant("Notice"),this.translate.instant("Session successfully generated (API)"));
          var lastApiCall = this.config.get('lastApiCall');
          if(typeof lastApiCall != 'undefined'){
            //console.log("API Call wird neu aufgerufen");
            //console.log(lastApiCall);
            this.config.set('ApiSessionError',false);
            var undefined;
            this.config.set('lastApiCall', undefined);
            this.gdatService.api(lastApiCall.func,lastApiCall.params);
          }
        }
      } else {
        //console.log("gdat api create session error");
        if(this.config.get('ApiLoginError')){
          //falsche gdat api login daten
          if(this.config.get('forceSetup')){
            //bei wiederholten gdat api login fehler aufgrund falscher daten
            //nur mehr ausgeben der Fehlermeldung
            this.doAlert(this.translate.instant("Error"),this.translate.instant(res['STATUS']));
          } else {
              //beim 1 gdat api login fehler aufgrund falscher daten
              this.config.set('forceSetup',true);
              /*
              var confirmObj = {
                title: this.translate.instant("Error"),
                message: this.translate.instant(res['STATUS']),
                buttons : [
                  {
                    text: 'OK',
                    handler: () => {
                      //console.log('OK clicked');
                      //this.start();
                    }
                  }
                ]
              };
              this.doConfirm(confirmObj);
              */
              this.start();
          }
        } else {
          if(this.config.get('apiOnline')){
            //console.log("error "+res['STATUS']);
            this.doAlert(this.translate.instant("Error"),this.translate.instant(res['STATUS']));
          }
          if(!this.config.get('ApiSessionError')){
            //console.log("API SESSION ERROR - GO TO LOGIN PAGE");
            //this.rootPage = LoginPage;
            this.goToStartPage();
          }
        }
      }
    });
  }

  start(){
    this.setLoadingMessage("starting...");
    //console.log("start");
    this.hideLoading();
    var startPageObj;

    if(typeof this.userSession.apiSessionToken != 'undefined' || (!this.profile.gdat && this.firebaseLoginStatus == "true")){
      console.log("start userReady: true");
      this.userLoginReady();
      this.userReady = true;
      this.userData.login(this.profile);
      this.config.set('userReady',this.userReady);
      this.config.set('startPage',this.profile.settings.startPage);
      //console.log("this.profile.settings.startPage: "+this.profile.settings.startPage);

      //webrtc
      //this._afterLogin();

      //toSync Check
      this.compareVersion();
      //nur wenn toSync, wenn nichht schon ein Sync Fehler aufgetreten ist und wenn gdat überhaupt aktiviert ist / gdat api login daten eingegeben sind
      if(this.toSync && !this.config.get('syncFailure') && this.profile.gdat){
        console.log("start sync");
        this.setLoadingMessage("starting Sync...");
        this.config.set('syncType','auto');
        this.rootPage = SyncPage;
      } else {
        console.log("start goToStartPage");
        this.setLoadingMessage("loading Homepage...");
        this.config.set('syncFailure',false);
        this.goToStartPage();
      }

    } else if(this.firebaseLoginStatus == "true"){
      console.log("start firebaseLoginStatus");
      if(this.profile.setup && !this.config.get('forceSetup')){
        console.log("start gdatLogin");
        this.gdatLogin();
      } else {
        //console.log("start SetupPage");
        this.lastPage = "SetupPage";
        this.rootPage = SetupPage;
      }
    } else if(this.firebaseLoginStatus == "tutorial"){
      //console.log("firebaseLoginStatus: tutorial");
      this.lastPage = "TutorialPage";
      this.rootPage = TutorialPage;
    } else {
      console.log("start: unhandeld exception");
    }
  }

  userLoginReady(){
    this.setLoadingMessage("logged in...");
    this.enableMenu(true);
    this.appPages = [
      //{ title: this.translate.instant('Home'), component: HomePage, icon: 'home', mainMenu: false},
      { title: this.translate.instant('News'), component: NewsPage, icon: 'paper', mainMenu: true},
      { title: this.translate.instant('Room Planner'), component: BelegungsplanPage, icon: 'list', mainMenu: true},
      { title: this.translate.instant('Schedule'), component: TabsPage, index: 0, icon: 'calendar' , mainMenu: true},
      { title: this.translate.instant('Speakers'), component: TabsPage, index: 1, icon: 'contacts' , mainMenu: false},
      { title: this.translate.instant('Map'), component: TabsPage, index: 2, icon: 'map' , mainMenu: false},
      { title: this.translate.instant('Information'), component: TabsPage, index: 3, icon: 'information-circle' , mainMenu: false},
      { title: this.translate.instant('Employees'), component: EmployeeListPage, icon: 'person' , mainMenu: true},
      { title: this.translate.instant('Todos'), component: TodosPage, icon: 'list-box', mainMenu: true},
      { title: this.translate.instant('Stats'), component: StatsPage, icon: 'stats', mainMenu: true},
      //{ title: this.translate.instant('Chat'), component: ChatPage, icon: 'chatbubbles', mainMenu: true},
      { title: this.translate.instant('Messenger'), component: MessengerPage, icon: 'chatbubbles', mainMenu: true},
      { title: this.translate.instant('Tutorials'), component: TutorialOverviewPage, icon: 'eye', mainMenu: false},
      //{ title: this.translate.instant('Tutorial'), component: TutorialPage, icon: 'eye', mainMenu: false},
      { title: this.translate.instant('Translation'), component: TranslationPage, icon: 'flag', mainMenu: false},
      { title: this.translate.instant('Sync'), component: SyncPage, icon: 'sync', mainMenu: false}
    ];
    //if(this.config.get('electron')){
      this.appPages.push({ title: this.translate.instant('Program'), component: ProgramPage, icon: 'laptop', mainMenu: false});
    //}
    this.config.set('appPages',this.appPages);
  }

  logoutAction(){
    //console.log("logoutAction - this.translationLoaded: "+this.translationLoaded);
    this.setUserProfileAfterFirebaseLogin();
    if(this.firebaseLoginStatus == "tutorial" && !this.translationLoaded){
      //beim erstmaligen start
      //die uebersetzungen laden und dafuer die firebase db verbinden
      //console.log("logoutAction - beim erstmaligen start");
      //this.FBService.firebasesignInAnonymously();
      this.profile.settings.language = this.translateConfig();
      this.setLanguage(this.profile.settings.language);
    } else if(this.firebaseLoginStatus == "tutorial"){
      //translation laden und danach in der start funktion
      //anstatt der startseite die tutorial seite lesen
      //console.log("logoutAction - start");
      this.start();
    } else if(this.firebaseLoginStatus == "true"){
      //wird ausgeloggt
      //this.enableMenu(false);
      //console.log("logoutAction - firebaseLoginStatus");
      this.setStandardConfig();
      this.rootPage = LogoutPage;
      // Give the menu time to close before changing to logged out
      setTimeout(() => {
        this.userData.logout();
      }, 1000);
    }
  }

  setLoadingMessage(text){
    //console.log("setLoadingMessage: "+text);
    if(this.config.get('electron')){
      if(document.getElementById('setLoadingMessage')){
        this.loadingMessage = text;
        document.getElementById('loadingMessageText').innerHTML = this.loadingMessage;
        //console.log("setLoadingMessage: "+document.getElementById('loadingMessageText').innerHTML);
        document.getElementById('setLoadingMessage').click();
      } else {
        //console.log("no setLoadingMessage element");
      }
    } else {
      //console.log("No Electron");
    }
  }

  setHomePageLoaded(){
    this.setLoadingMessage("Homepage loaded...");
    //console.log("didEnter");
    //console.log("elctron: "+this.config.get('electron'));
    //console.log("platform: "+this.config.get('platform'));
    //console.log("mobile: "+this.config.get('mobile'));
    if(this.config.get('electron')){
      //console.log("homePageLoaded: electron");
      document.getElementById('hideLoading').click();
      document.getElementById('startElectron').click();

      setTimeout(()=>{
        this.callExternalFunction("updateFunc()");
      },2000);
    } else {
      //console.log("homePageLoaded: no electron");
    }
    document.body.className += " homePageLoaded";
    this.hideLoading();
  }

  listenToLoginEvents() {

    this.events.subscribe('homePageLoaded', (data) => {
      this.config.set('homePageLoaded',true);
      this.setHomePageLoaded();
    });

    this.events.subscribe('setStandardConfig', () => {
      this.setStandardConfig();
    });

    this.events.subscribe('setLoadingMessage', (data) => {
      this.setLoadingMessage(data[0]);
    });

    this.events.subscribe('createApiSession', (data) => {
      this.createApiSession();
    });

    this.events.subscribe('attention_bar:true', (data) => {
      this.attention_message = data[0];
    });

    this.events.subscribe('attention_bar:false', () => {
      this.attention_message = "";
    });

    this.events.subscribe('goToLogin', () => {
      this.rootPage = LoginPage;
      //this.nav.push(LoginPage);
    });

    this.events.subscribe('gdatLogin', () => {
      this.gdatLogin();
    });

    this.events.subscribe('afterSync', () => {
      this.toSync = false;
      this.goToStartPage();
    });

    this.events.subscribe('goToStartPage', () => {
      this.goToStartPage();
    });

    this.events.subscribe('openPage', (data) => {
      this.openPage(data[0]);
    });

    this.events.subscribe('setRootPage:LoginPage', (data) => {
      //console.log("setRootPage ROOTPAGE LoginPage");
      this.rootPage = LoginPage;
    });

    this.events.subscribe('tutorial:skip', (data) => {
      //console.log("tutorial:skip - firebaseLoginStatus: "+this.firebaseLoginStatus);
      if(this.firebaseLoginStatus == "true"){
        this.rootPage = HomePage;
      } else {
        this.rootPage = LoginPage;
      }
    });

    this.events.subscribe('settings:setLanguage', (data) => {
      //console.log("subscribe settings:setLanguage");
      //console.log(data[0]);
      this.setLanguage(data[0]);
    });

    this.events.subscribe('user:login', (user) => {
      //console.log("subscribe event: login");
      this.profile = user[0];
      this.setUserProfileAfterFirebaseLogin();
      //console.log("user:login - this.profile.email: "+this.profile.email);
      this.loginAction();
    });

    this.events.subscribe('firebase:login', (user) => {
      //console.log("firebase event: login");
      this.profile = user[0];
      this.setUserProfileAfterFirebaseLogin();
      //console.log("firebase:login - this.profile.email: "+this.profile.email);
      //this.events.publish('setRootPage:LoginPage');
      this.loginAction();
    });

    this.events.subscribe('firebase:anonymous', (user) => {
      //console.log("firebase event: anonymous - firebaseLoginStatus: "+this.firebaseLoginStatus);
      //this.logoutAction();
      //this.profile = user[0];
      this.events.publish('goToLogin');
    });

    this.events.subscribe('firebase:disconnet', () => {
      //console.log("firebase:disconnet");
      //this.usersRef.remove();
      //this.usersRef.unauth();
      //this.usersRef.off();
      //this.usersRef.cancel();
      this.FBService.disconnet();
    });

    this.events.subscribe('user:signup', () => {
      //console.log("subscribe event: signup");
      this.loginAction();
    });

    this.events.subscribe('user:logout', () => {
      //console.log("subscribe event: logout");
      this.logoutAction();
    });

    this.events.subscribe('firebase:logout', () => {
      //console.log("firebase event: logout - firebaseLoginStatus: "+this.firebaseLoginStatus);
      this.logoutAction();
    });

    this.events.subscribe('alert:basic', (data) => {
      //console.log("subscribe event: alert:basic");
      //console.log(data);
      this.doAlert(data[0].title,data[0].msg);
    });

    this.events.subscribe('actionSheet', (data) => {
      //console.log("subscribe event: actionSheet ");
      //console.log(data);
      this.doActionSheet (data[0]);
    });

    this.events.subscribe('modal', (data) => {
      //console.log("subscribe event: modal ");
      //console.log(data);
      this.doModal(data[0]);
    });

    this.events.subscribe('confirm:basic', (data) => {
      //console.log("subscribe event: confirm:basic");
      //console.log(data);
      this.doConfirm(data[0]);
    });

    this.events.subscribe('doPopover', (data) => {
      //console.log("subscribe event: doPopover");
      //console.log(data);
      this.doPopover(data[0]);
    });

    this.events.subscribe('loading:show', (data) => {
      this.showLoading();
    });

    this.events.subscribe('loading:hide', (data) => {
      this.hideLoading();
    });

  }

  goToStartPage(){
    //console.log("goToStartPage 1: "+this.config.get('startPage'));
    var startPageObj = this.getPageObj(this.config.get('startPage'));
    //console.log("goToStartPage 2");
    //console.log(startPageObj);
    //Funktioniert nicht mit Tab Pages
    this.lastPage = this.config.get('startPage');
    this.rootPage = startPageObj;
    //Deshalb
    //this.nav.push(startPageObj);
  }

  doDialog(){
    if(typeof this.dialogStack != 'undefined'){
      if(this.dialogStack.length > 0){
        if(this.dialogStack[0].type == "alert"){
          this.doAlert(this.dialogStack[0].titel,this.dialogStack[0].msg);
        } else if(this.dialogStack[0].type == "confirm"){
          this.doConfirm(this.dialogStack[0].obj);
        } else if(this.dialogStack[0].type == "popover"){
          this.doPopover(this.dialogStack[0].obj);
        } else if(this.dialogStack[0].type == "actionsheet"){
          this.doActionSheet(this.dialogStack[0].obj);
        }
      }
    }
  }

  doAlert(title,msg) {
    //console.log("doAlert");
    if(typeof msg != 'undefined' && msg != '-'){
      if(
        !this.showingAlert && !this.showingConfirm &&
        !this.showingPopover && !this.showingActionSheet
      ){
        //console.log("doAlert start");
        this.alertDialog = this.alertCtrl.create({
          title: title,
          subTitle: msg,
          buttons: [{
            text: 'OK',
            handler: () => {
              //console.log('OK clicked');
              this.showingAlert = false;
            }
          }]
        });
        this.showingAlert = true;
        this.alertDialog.present()
        this.alertDialog.onDismiss(() => {
            this.showingAlert = false;
            if(this.dialogStack.length > 0){
              this.doDialog();
              this.dialogStack.splice(0, 1);
            }
        });
      } else {
        this.dialogStack.push({type:'alert',title:title,msg:msg});
      }
    }
  }

  doConfirm(obj) {
    //console.log("doConfirm");
    if(
      !this.showingAlert && !this.showingConfirm &&
      !this.showingPopover && !this.showingActionSheet
    ){
      //console.log("doConfirm start");
      this.confirmDialog = this.alertCtrl.create({
        title: obj.title,
        message: obj.message,
        buttons: obj.buttons
      });
      this.showingConfirm = true;
      this.confirmDialog.present()
      this.confirmDialog.onDismiss(() => {
          this.showingConfirm = false;
          if(this.dialogStack.length > 0){
            this.doDialog();
            this.dialogStack.splice(0, 1);
          }
      });
    } else {
      this.dialogStack.push({type:'confirm',obj:obj});
    }
  }

  doPopover(obj){
    if(
      !this.showingAlert && !this.showingConfirm &&
      !this.showingPopover && !this.showingActionSheet
    ){
      this.popoverDialog = this.popoverCtrl.create(obj.page);
      this.showingPopover = true;
      this.popoverDialog.present({
        ev: obj.ev
      });
      this.popoverDialog.onDismiss(() => {
          this.showingPopover = false;
          if(this.dialogStack.length > 0){
            this.doDialog();
            this.dialogStack.splice(0, 1);
          }
      });
    } else {
      this.dialogStack.push({type:'popover',obj:obj});
    }
  }

  doActionSheet (obj){
    if(
      !this.showingAlert && !this.showingConfirm &&
      !this.showingPopover && !this.showingActionSheet
    ){
      this.actionSheetDialog = this.actionSheetCtrl.create(obj);
      this.showingActionSheet = true;
      this.actionSheetDialog.present();
      this.actionSheetDialog.onDismiss(() => {
          this.showingActionSheet = false;
          if(this.dialogStack.length > 0){
            this.doDialog();
            this.dialogStack.splice(0, 1);
          }
      });
    } else {
      this.dialogStack.push({type:'actionsheet',obj:obj});
    }
  }

  doModal(obj){
    //console.log("doModal",obj);
    let profileModal = this.modalCtrl.create(obj.page, obj.data);
    profileModal.present();
    if(obj.onDismissHandler){
      profileModal.onDidDismiss(data => {
        obj.onDismissHandler(data);
      });
    }
  }

  enableMenu(loggedIn) {
    this.menu.enable(loggedIn, 'loggedInMenu');
    this.menu.enable(!loggedIn, 'loggedOutMenu');
  }

  getPageObj(startPage:string){
    var obj;
    if(this.profile.setup){
      obj = HomePage;
    } else {
      obj = SetupPage;
    }
    if(typeof startPage != 'undefined' && startPage != ''){
      for(var i=0;i<this.appPages.length;i++){
        if(startPage == this.appPages[i].component.name){
          //console.log(startPage+" - "+this.appPages[i].component.name);
          obj = this.appPages[i].component;
        }
      }
    }
    return obj;
  }

  showLoading(){
    if(!this.now_loading){
      //console.log("showLoading");
      this.now_loading = true;
      if(typeof this.nav != 'undefined'){
        this.loading = this.loadingCtrl.create({
          dismissOnPageChange: false, //true macht beim starten einen fehler
        });
        this.loading.present(this.loading);
      }
    }
  }

  hideLoading(){
    //console.log("hideLoading");
    if(typeof this.loading != 'undefined'){
      this.loading.dismiss();
    }
    this.now_loading = false;
  }

  unSanitizeTranlationKeys(data){
    var dataNew: any = {};
    for (var key in data) {
       if (data.hasOwnProperty(key)) {
          dataNew[this.dataService.unSanitizeKey(key)] = data[key];
       }
    }
    return dataNew;
  }

  setLanguage(new_lang){
    //console.log("setLanguage - "+new_lang);
    if(typeof new_lang == 'undefined'){
      new_lang = this.profile.settings.language;
    }
    var found = false;
    if(typeof this.languages != 'undefined'){
      if(this.languages.length > 0){
        for(var i=0;i<this.languages.length;i++){
          if(this.languages[i].key == new_lang){
            found = true;
          }
        }
      }
    }
    if (!found) {
      //console.log("sprache '"+new_lang+"' nicht vorhanden");
      this.profile.settings.language = this.standardLanguage;
    } else {
      this.profile.settings.language = new_lang;
    }
    this.config.set('profile',this.profile);
    //console.log("setLanguage - "+this.profile.settings.language);
    this.changeLanguage(this.profile.settings.language);
  }

  setAppSettings(){
    //console.log("setAppSettings");
    //if(typeof this.profile.settings.language == 'undefined'){
      //this.profile.settings.language = this.language;
    //}
    if(typeof this.standards.app.Version == 'undefined'){
      this.standards.app.Version = this.AppHardcodedVersion;
    }
    if(typeof this.standards.app.AppName == 'undefined'){
      this.standards.app.AppName = this.config.get('AppName');
    }
    if(typeof this.standards.company.timestamp == 'undefined'){
      this.standards.company.timestamp = this.AppHardcodedTimestamp;
    }
    this.config.set('GDATNR',this.standards.company.GDATNR);
    this.config.set('standards',this.standards);
  }

  //nach Login oder Logout
  setUserProfileAfterFirebaseLogin(){
    //console.log("setUserProfileAfterFirebaseLogin start");
    //console.log(this.profile);

    if(typeof this.profile == 'undefined'){
      this.profile = [];
    }

    if(typeof this.profile.firstName == 'undefined'){
      this.profile.firstName = "";
    }
    if(typeof this.profile.lastName == 'undefined'){
      this.profile.lastName = "";
    }
    if(typeof this.profile.photoURL == 'undefined'){
      this.profile.photoURL = "";
    }
    if(typeof this.profile.setup == 'undefined'){
      this.profile.setup = false;
    }

    if(typeof this.profile.settings == 'undefined'){
      this.profile.settings = [];
    }

    if(typeof this.profile.settings.language == 'undefined'){
      this.profile.settings.language = this.translateConfig();
    }

    if(!this.profile.settings.startPage || typeof this.profile.settings.startPage == 'undefined' || this.profile.settings.startPage == ""){
      this.profile.settings.startPage = this.standardStartPage;
    };
    if(typeof this.profile.settings.apiTimeout == 'undefined'){
      this.profile.settings.apiTimeout = 10;
    }

    if(typeof this.profile.standards != 'undefined'){
      if(typeof this.profile.standards.company != 'undefined'){
        if(typeof this.profile.standards.company.GDATNR != 'undefined'){
          this.standards.company.GDATNR = this.profile.standards.company.GDATNR;
        }
        if(typeof this.profile.standards.company.timestamp != 'undefined'){
          this.standards.company.timestamp = this.profile.standards.company.timestamp*1;
        }
      }
      if(typeof this.profile.standards.app != 'undefined'){
        if(typeof this.profile.standards.app.AppName != 'undefined'){
          this.standards.app.AppName = this.profile.standards.app.AppName;
        }
        if(typeof this.profile.standards.app.Version != 'undefined'){
          this.standards.app.Version = this.profile.standards.app.Version;
        }
      }
    }

    this.config.set('profile',this.profile);

    //console.log("setUserProfileAfterFirebaseLogin ende");
    //console.log("language: "+this.profile.settings.language);
    //console.log(this.profile);
  }

  compareVersion(){
    if(this.forceSync){
      this.toSync = true;
    } else {
      var compare = this.dataService.versionCompare(this.standards.app.Version,this.App.Version);
      if(compare == -1){
        //console.log("toSync(Version): true");
        this.toSync = true;
      } else {
        if(this.standards.company.timestamp <= this.App.timestamp){
          //console.log("toSync(Timestamp): true");
          this.toSync = true;
        } else {
          //console.log("toSync: false");
          this.toSync = false;
        }
      }
    }
  }

  openPage(page: PageObj) {
    //Neu Laden des gleichen Seite beim Belegungsplan verhinder, da dieser dann nicht funktioniert
    //Laden des Belegungsplan ohne neu Laden der Belegungsplan Seite weiter unten
    if(page.component.name != this.lastPage || page.component.name != "BelegungsplanPage"){
      //console.log("openPage - lastPage: "+this.lastPage+" - page: "+page.component.name);
      this.lastPage = page.component.name;
      // the nav component was found using @ViewChild(Nav)
      // reset the nav to remove previous pages and only have this page
      // we wouldn't want the back button to show in this scenario
      if (page.index) {
        this.nav.setRoot(page.component, {tabIndex: page.index});
      } else {
        this.nav.setRoot(page.component);
      }
      /*
      if (page.title === 'Logout') {
        //console.log("page.title Logout");
        // Give the menu time to close before changing to logged out
        setTimeout(() => {
          this.userData.logout();
        }, 1000);
      }
      */
    } else {
      //Laden des Belegungsplan ohne neu Laden der Belegungsplan Seite
      this.events.publish('belegungsplan:reload');
    }
  }

  callExternalFunction(func){
    //console.log("callExternalFunction: "+func);
    this.externalFunctionText = func;
    document.getElementById('callExternalFunctionText').innerHTML = this.externalFunctionText;
    document.getElementById('callExternalFunction').click();
  }

  /*NATIVE FUNCTIONS*/
  setStatusbar(){
    StatusBar.styleDefault();
    StatusBar.overlaysWebView(false); // let status var overlay webview or not
    StatusBar.backgroundColorByHexString(this.primaryColor); // set status bar to white
  }
  getGeoLocation(){

    Geolocation.getCurrentPosition().then((pos) => {
      this.config.set('geolocation',{success:true,errorCode:'',errorMessage:'',lat:pos.coords.latitude,lon:pos.coords.longitude});
      //alert('Koordinaten lat: ' + pos.coords.latitude + ', lon: ' + pos.coords.longitude);
    }, (err) => {
        // error
        this.config.set('geolocation',{success:false,errorCode:err.code,errorMessage:err.message,lat:0,lon:0});

        //console.log("geolocation error");
        //console.log(err.code);
        //console.log(err.message);
    });

    /*
    let watch = Geolocation.watchPosition().subscribe((pos) => {
      this.config.set('geolocation',{success:true,errorCode:'',errorMessage:'',lat:pos.coords.latitude,lon:pos.coords.longitude});
      alert('lat: ' + pos.coords.latitude + ', lon: ' + pos.coords.longitude);
    }, (err) => {
        // error
        this.config.set('geolocation',{success:false,errorCode:err.code,errorMessage:err.message,lat:0,lon:0});

        //console.log("geolocation watch error");
        //console.log(err.code);
        //console.log(err.message);
    });

    // to stop watching
    watch.unsubscribe();
    */
  }
}

// Pass the main App component as the first argument
// Pass any providers for your app in the second argument
// Set any config for your app as the third argument, see the docs for
// more ways to configure your app:
// http://ionicframework.com/docs/v2/api/config/Config/
// Place the tabs on the bottom for all platforms
// See the theming docs for the default values:
// http://ionicframework.com/docs/v2/theming/platform-specific-styles/

enableProdMode();
ionicBootstrap(ConferenceApp, [
      ConferenceData,
      UserData,
      dataService,
      databaseService,
      firebaseService,
      /*
      FIREBASE_PROVIDERS,
      defaultFirebase({
          apiKey: "AIzaSyDpLlfSu4ZmHGPWoTzw9Xt4nfqaYMP3lNQ",
          authDomain: "core-stronghold-91622.firebaseapp.com",
          databaseURL: "https://core-stronghold-91622.firebaseio.com",
          storageBucket: "core-stronghold-91622.appspot.com",
      }),
      firebaseAuthConfig({
          //provider: AuthProviders.Google,
          //method: AuthMethods.Popup,
          provider: AuthProviders.Password,
          method: AuthMethods.Password,
          //method: AuthMethods.Anonymous,
          //provider: AuthProviders.Anonymous,
          remember: 'default',
          scope: ['email']
      }),
      AngularFire,
      */
      WebRTCConfig, WebRTCService,

      gdatService,
      pouchDbService,

      [
        provide(TranslateLoader,{}),
        TranslateService
      ],
      {provide: PLATFORM_PIPES, useValue: TranslatePipe, multi: true}
  ], {
      tabbarPlacement: 'top',
      language: 'en',
      iconMode: 'md', //ios
      mode: 'md'
});
