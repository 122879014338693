import { Component, ViewChild } from '@angular/core';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { Events, Config, Page, Content } from 'ionic-angular';
import { ConferenceData } from '../../providers/conference-data';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  templateUrl: 'build/pages/map/map.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class MapPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];

  constructor(
    private config: Config,
    private events: Events,
    private headerDirective: HeaderDirective,
    private confData: ConferenceData,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Map')
    };
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  ionViewLoaded() {
    this.confData.getMap().then(mapData => {
      let mapEle = document.getElementById('map');

      let map = new google.maps.Map(mapEle, {
        center: mapData.find(d => d.center),
        zoom: 16
      });

      mapData.forEach(markerData => {
        let infoWindow = new google.maps.InfoWindow({
          content: `<h5>${markerData.name}</h5>`
        });

        let marker = new google.maps.Marker({
          position: markerData,
          map: map,
          title: markerData.name
        });

        marker.addListener('click', () => {
          infoWindow.open(map, marker);
        });
      });

      google.maps.event.addListenerOnce(map, 'idle', () => {
        mapEle.classList.add('show-map');

        //BLANK SCREEN FIX AFTER REOPEN MAP
        //console.log("map idled, resizing");
        google.maps.event.trigger(map, 'resize');
        map.setCenter(mapData.find(d => d.center));
        map.setZoom(map.getZoom());

      });

    });
  }
}
