import { Component, Input, OnInit } from '@angular/core';
import { IONIC_DIRECTIVES, Config, Events } from 'ionic-angular';
import {FORM_DIRECTIVES, REACTIVE_FORM_DIRECTIVES} from '@angular/forms';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";
import { databaseService } from '../../services/databaseService';
import { gdatService } from '../../services/gdatService';

@Component({
  selector: 'room-form',
  templateUrl: 'build/components/room-form/room-form.html',
  directives: [IONIC_DIRECTIVES, FORM_DIRECTIVES, REACTIVE_FORM_DIRECTIVES], // makes all Ionic directives available to your component
  inputs: ['options']
})

export class RoomForm {
  private options: any = [];
  private submitAttempt: boolean = false;
  private slideOneForm: FormGroup;
  private form: any = [];

  constructor(
    private config: Config,
    private events: Events,
    private formBuilder: FormBuilder,
    private dbService: databaseService,
    private gdatService: gdatService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');

    this.form.Guest = [];
    this.form.TERMINE1 = [];

    this.slideOneForm = formBuilder.group({
      VORNAME: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
      FAMNAME: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
      VON: ['', Validators.required],
      BIS: ['', Validators.required],
      time: ['', Validators.required],
      done: ['', Validators.required]
    });

  }

  ngOnInit(){
    //console.log("ngOnInit GuestForm");
    //this.load();
  }

  load(){
    this.dbService.getTerminInfos(this.options.termin).then((res) => {
      //console.log(res);
      if(res['SUCCESS']){
        //console.log(res['API']);
        if(typeof res['API'].GuestData != 'undefined'){
          this.form.Guest = res['API'].GuestData.Guest;
        } else {

        }
        if(typeof res['API'].ReservationData != 'undefined'){
          this.form.TERMINE1 = res['API'].ReservationData.TERMINE1;
        } else {

        }
      } else {

      }
    });
  }
}
