import { Component, ViewChild } from '@angular/core';
import { Config, Content, Events, NavController } from 'ionic-angular';
import { TranslateService, TranslatePipe } from "ng2-translate/ng2-translate";

import { UserData } from '../../providers/user-data';
import { dataService } from '../../services/dataService';
import { firebaseService } from '../../services/firebaseService';

import { ToolbarMenu } from '../../components/toolbar-menu/toolbar-menu';
import { HeaderDirective } from '../../directives/header-directive';

@Component({
  templateUrl: 'build/pages/messenger_chat/chat.html',
  directives: [ToolbarMenu,HeaderDirective],
  providers: [HeaderDirective]
})
export class MessengerChatPage {
  @ViewChild(Content) protected content: Content;

  private toolbar_options:any = [];
  private username: string;
  private messages: any;
  private messagesRef: any;
  private profile: any;
  private messageInput: string;

  constructor(
    public nav: NavController,
    private events: Events,
    private config: Config,
    private headerDirective: HeaderDirective,
    private userData: UserData,
    private dataService: dataService,
    private FBService: firebaseService,
    private translate: TranslateService
  ) {
    this.translate = translate;
    this.translate.instant('json.string');
    this.toolbar_options = {
      title: translate.instant('Messenger')+" "+translate.instant('Chat')
    };

    this.nav = nav;
    this.messages = [];
    this.profile = this.config.get('profile');
  }

  ionViewDidEnter(){
    if(!this.config.get('homePageLoaded')){
      this.events.publish('homePageLoaded',true);
    }
  }

  ngAfterViewInit() {
    this.getUsername();
    //this.FBService.getFbUsers();
    this.getMessages();
  }
  saveMessage(){
    if(typeof this.messageInput == 'undefined'){
      var alert = {title: this.translate.instant("Error"), msg: this.translate.instant('Please enter a message')};
      this.events.publish('alert:basic',alert);
    } else {
      var obj = {
        uid: this.profile.uid,
        name: this.profile.displayName || this.profile.email,
        text: this.messageInput,
        photoUrl: this.profile.photoURL || '/images/profile_placeholder.png'
      }
      this.messagesRef = this.FBService.getDBObject('messages/support');
      //console.log(this.profile);
      //console.log(obj);
      this.FBService.add(this.messagesRef,obj);
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
    }
  }

  filterMessage(){
    this.FBService.filterMessage();
  }
  getMessages(){
    var query = {
      limitToLast: 10,
    }
    this.messagesRef = this.FBService.getDB('messages/support','object',query);
    this.messagesRef.limitToFirst(query.limitToLast).on('value', (data) => {
      var snapshot = data.val();
    //this.messagesRef.subscribe((snapshot) => {
      //console.log(snapshot);
      this.messages.push(snapshot);
      if(this.headerDirective){this.headerDirective.eventAction('contentChanged');}
    });
  }
  getUsername() {
    /*this.userData.getUsername().then((username) => {
      this.username = username;
    });*/
    this.username = this.userData.getUsername();
  }
}
